import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  reactFormatter,
  TableEventsV2,
  TableV2,
} from "../../../../../../../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../../../../../../../components/table/v2/TableV2";
import { deepClone } from "../../../../../../../../../../deepClone";
import { useFormatter } from "../../../../../../../../../../hooks/useFormatter";
import Scatter from "./Scatter";
import { CellComponent, ColumnDefinition } from "tabulator-tables";
import { useEnvironment } from "../../../../../../../../../../hooks/useEnvironment";

type Props = {
  data: any;
  isUsedInCompare?: boolean;
};
const defaultFormatterType = "PERCENTAGE";

export const Analytics = ({ data, isUsedInCompare }: Props) => {
  type ChoosenTabType = "YEARLY" | "MONTHLY" | "QUARTERLY" | "SCATTER";
  const hasBenchmark = useMemo(() => {
    if (data.strategy.params.strategy.benchmark != null) {
      return true;
    }
    return false;
  }, [data.strategy.params.strategy.benchmark]);
  const [choosenTab, setChoosenTab] = useState<ChoosenTabType>("YEARLY");
  const tableRef = useRef<{ getInstance: () => TableV2 }>();
  const [status, setStatus] = useState({ columns: false, built: false });

  const columnsReady = useMemo(() => status.columns, [status.columns]);
  const tableBuilt = useMemo(() => status.built, [status.built]);

  const scatterAndTableData = useMemo(() => {
    if (data) {
      return scatterDataPrepare(data.analytics);
    }
  }, [data]);

  const environment = useEnvironment();

  const sorter = useCallback((a, b) => {
    return a.value > b.value ? 1 : -1;
  }, []);

  const tableData = useMemo(() => {
    if (choosenTab === "YEARLY") {
      return scatterAndTableData.yearly;
    } else if (choosenTab === "MONTHLY") {
      return scatterAndTableData.monthly;
    } else if (choosenTab === "QUARTERLY") {
      return scatterAndTableData.quarterly;
    }
    return scatterAndTableData.chartScatter;
  }, [
    choosenTab,
    scatterAndTableData.chartScatter,
    scatterAndTableData.monthly,
    scatterAndTableData.quarterly,
    scatterAndTableData.yearly,
  ]);
  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: ChoosenTabType) => {
      setChoosenTab(newValue);
    },
    []
  );
  const formatter = useFormatter();
  const renderCell = useCallback(
    (type) => {
      return (cell, formatterParams, onRendered) => {
        const object = cell.getData();
        const row = cell.getRow().getData();
        const field = cell.getField();
        const v = row?.[field];
        const value = field === "timeFrame" ? v : v?.value;

        if (value == null) {
          return;
        }

        if (value === "Annualized" || value === "average") {
          let row = cell.getRow();
          let element = row.getElement();
          element.style.fontWeight = 600;
        }
        if (value === "Annualized") {
          return value;
        }
        if (value === "average") {
          return "Average";
        }
        let innerHTML = "";
        switch (type) {
          case "DATE": {
            const tokens = value.split("_");
            const date = new Date(
              Date.UTC(parseInt(tokens[0]), parseInt(tokens[1]) - 1, 1)
            );
            innerHTML = formatter.custom("date", {
              options: {
                format: ["M", "Y"],
                isMillisecond: true,
                notAvailable: {
                  input: "",
                  output: "",
                },
                separator: " ",
              },
              output: "HTML",
              value: date.getTime(),
              valueHelper: null,
            });

            break;
          }
          case "DATE_QUARTER": {
            const tokens = value.split("_");
            const date = new Date(
              Date.UTC(parseInt(tokens[0]), parseInt(tokens[1]) - 1, 1)
            );
            var quarter = "";
            switch (tokens[1]) {
              case "01":
              case "02":
              case "03": {
                quarter = "Q1";
                break;
              }
              case "04":
              case "05":
              case "06": {
                quarter = "Q2";
                break;
              }
              case "07":
              case "08":
              case "09": {
                quarter = "Q3";
                break;
              }
              case "10":
              case "11":
              case "12": {
                quarter = "Q4";
                break;
              }
            }
            innerHTML =
              quarter +
              " " +
              formatter.custom("date", {
                options: {
                  format: ["Y"],
                  isMillisecond: true,
                  notAvailable: {
                    input: "",
                    output: "",
                  },
                  separator: " ",
                },
                output: "HTML",
                value: date.getTime(),
                valueHelper: null,
              });

            break;
          }
          case "PERCENTAGE": {
            innerHTML = formatter.custom("number", {
              options: {
                isPercentage: true,
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "HTML",
              value: value,
              valueHelper: {
                normalizationThreshold: 1,
              },
            });

            break;
          }
          case "PERCENTAGE_AND_BAR": {
            innerHTML = formatter.custom("bar", {
              options: {
                isPercentage: true,
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "HTML",
              value: value,
              valueHelper: {
                normalizationThreshold: object["_s_normalizationThreshold"],
              },
            });

            break;
          }
        }

        return innerHTML;
      };
    },
    [formatter]
  );

  const [currentFormatterType, setCurrentFormatterType] =
    useState(defaultFormatterType);

  let baseClass = "StrategyAnalytics";
  var cssClassSwitch = baseClass + "-formatterSwitch";
  var cssClassSwitchOff = cssClassSwitch + "--off";
  var cssClassSwitchOn = cssClassSwitch + "--on";

  const componente = useCallback(
    (title) => (
      <>
        <span>{title}</span>
        <button
          className={[
            cssClassSwitch,
            currentFormatterType === "PERCENTAGE"
              ? cssClassSwitchOff
              : cssClassSwitchOn,
          ].join(" ")}
          onClick={() =>
            currentFormatterType === "PERCENTAGE"
              ? setCurrentFormatterType("PERCENTAGE_AND_BAR")
              : setCurrentFormatterType("PERCENTAGE")
          }
          title="Show/hide Difference histogram"
        >
          <span className="i-beta"></span>
        </button>
      </>
    ),
    [cssClassSwitch, cssClassSwitchOff, cssClassSwitchOn, currentFormatterType]
  );

  const uuid = useMemo(() => {
    return environment.get("account").user.id;
  }, [environment]);

  const cellTagTooltip = useCallback(
    (e, cell: CellComponent) => {
      const f = cell.getField();
      const tag = cell.getRow().getData()?.[f]?.tag;

      if (
        tag != null &&
        (uuid === 2 || uuid === 4 || uuid === 2700 || uuid === 2816)
      ) {
        return tag;
      }
    },
    [uuid]
  );

  const prepareColumns = useCallback(
    (frameSize, formatterType, hasBenchmark) => {
      formatterType =
        formatterType == null ? defaultFormatterType : formatterType;

      var columns: any = [];
      var labelStrategy = "Strategy";
      var benchmark = isUsedInCompare === true ? "Strategy 2" : "Benchmark";

      switch (frameSize) {
        case "MONTHLY": {
          if (hasBenchmark) {
            columns = [
              {
                title: "",
                columns: [
                  {
                    field: "timeFrame",
                    title: "Month",
                    formatter: renderCell("DATE"),
                  },
                ],
              },
              {
                title: "Monthly performance",
                columns: [
                  {
                    sorter,
                    field: "strategyMonthlyPerformance",
                    title: labelStrategy,
                    formatter: renderCell("PERCENTAGE"),
                    tooltip: cellTagTooltip,
                  },
                  {
                    sorter,
                    field: "benchmarkMonthlyPerformance",
                    title: benchmark,
                    formatter: renderCell("PERCENTAGE"),
                    tooltip: cellTagTooltip,
                  },
                  {
                    sorter,
                    field: "deltaMonthlyPerformance",
                    title: "Difference",
                    formatter: renderCell(formatterType),
                    tooltip: cellTagTooltip,
                  },
                ],
                titleFormatter: reactFormatter(
                  componente("Monthly performance")
                ),
              },
              {
                title: "Max drawdown",
                columns: [
                  {
                    sorter,
                    field: "strategyMaxDrawdown",
                    title: labelStrategy,
                    formatter: renderCell("PERCENTAGE"),
                    tooltip: cellTagTooltip,
                  },
                ],
              },
              {
                title: "Monthly return stdDev",
                columns: [
                  {
                    sorter,
                    field: "strategyVolatility",
                    title: labelStrategy,
                    formatter: renderCell("PERCENTAGE"),
                    tooltip: cellTagTooltip,
                  },
                ],
              },
            ];
          } else {
            columns = [
              {
                field: "timeFrame",
                title: "Month",
                formatter: renderCell("DATE"),
                sorter: "string",
              },
              {
                field: "strategyMonthlyPerformance",
                title: "Monthly performance",
                formatter: renderCell("PERCENTAGE"),
                sorter,
                tooltip: cellTagTooltip,
              },
              {
                field: "strategyMaxDrawdown",
                title: "Max drawdown",
                formatter: renderCell("PERCENTAGE"),
                sorter,
                tooltip: cellTagTooltip,
              },
              {
                field: "strategyVolatility",
                title: "Monthly return stdDev",
                formatter: renderCell("PERCENTAGE"),
                sorter,
                tooltip: cellTagTooltip,
              },
            ];
          }
          break;
        }
        case "QUARTERLY": {
          if (hasBenchmark) {
            columns = [
              {
                title: "",
                sorter: "number",
                columns: [
                  {
                    field: "timeFrame",
                    title: "Quarter",
                    formatter: renderCell("DATE_QUARTER"),
                    sorter,
                  },
                ],
              },
              {
                title: "Quarterly performance",
                sorter: "number",
                columns: [
                  {
                    field: "strategyQuarterlyPerformance",
                    title: labelStrategy,
                    formatter: renderCell("PERCENTAGE"),
                    tooltip: cellTagTooltip,
                    sorter,
                  },
                  {
                    sorter,
                    field: "benchmarkQuarterlyPerformance",
                    title: benchmark,
                    formatter: renderCell("PERCENTAGE"),
                    tooltip: cellTagTooltip,
                  },
                  {
                    sorter,
                    field: "deltaQuarterlyPerformance",
                    title: "Difference",
                    widthGrow: 1,
                    formatter: renderCell(formatterType),
                    tooltip: cellTagTooltip,
                  },
                ],
                titleFormatter: reactFormatter(
                  componente("Quarterly performance")
                ),
              },
              {
                title: "Max drawdown",
                sorter,
                columns: [
                  {
                    sorter,
                    field: "strategyMaxDrawdown",
                    title: labelStrategy,
                    formatter: renderCell("PERCENTAGE"),
                    tooltip: cellTagTooltip,
                  },
                ],
              },
              {
                title: "Quarterly return stdDev",
                sorter: "number",
                columns: [
                  {
                    sorter,
                    field: "strategyVolatility",
                    title: labelStrategy,
                    formatter: renderCell("PERCENTAGE"),
                    tooltip: cellTagTooltip,
                  },
                ],
              },
            ];
          } else {
            columns = [
              {
                field: "timeFrame",
                title: "Quarter",
                formatter: renderCell("DATE_QUARTER"),
                sorter: "string",
              },
              {
                field: "strategyQuarterlyPerformance",
                title: "Quarterly performance",
                formatter: renderCell("PERCENTAGE"),
                tooltip: cellTagTooltip,
                sorter,
              },
              {
                field: "strategyMaxDrawdown",
                title: "Max drawdown",
                formatter: renderCell("PERCENTAGE"),
                tooltip: cellTagTooltip,
                sorter,
              },
              {
                field: "strategyVolatility",
                title: "Quarterly return stdDev",
                formatter: renderCell("PERCENTAGE"),
                tooltip: cellTagTooltip,
                sorter,
              },
            ];
          }

          break;
        }
        case "YEARLY": {
          if (hasBenchmark) {
            columns = [
              {
                title: "",
                columns: [
                  {
                    field: "timeFrame",
                    title: "Year",
                    formatter: (cell) => {
                      const value = cell.getValue();
                      if (value === "Annualized" || value === "average") {
                        let row = cell.getRow();
                        let element = row.getElement();
                        element.style.fontWeight = 600;
                      }
                      return value !== "average" ? value : "Average";
                    },
                  },
                ],
              },
              {
                title: "Yearly performance",
                columns: [
                  {
                    sorter,
                    field: "strategyYearlyPerformance",
                    title: labelStrategy,
                    formatter: renderCell("PERCENTAGE"),
                    tooltip: cellTagTooltip,
                  },
                  {
                    sorter,
                    field: "benchmarkYearlyPerformance",
                    title: benchmark,
                    formatter: renderCell("PERCENTAGE"),
                    tooltip: cellTagTooltip,
                  },
                  {
                    sorter,
                    field: "deltaYearlyPerformance",
                    title: "Difference",
                    formatter: renderCell(formatterType),
                    tooltip: cellTagTooltip,
                  },
                ],
                titleFormatter: reactFormatter(
                  componente("Yearly performance")
                ),
              },
              {
                title: "Max drawdown",
                columns: [
                  {
                    sorter,
                    field: "strategyMaxDrawdown",
                    title: labelStrategy,
                    formatter: renderCell("PERCENTAGE"),
                    tooltip: cellTagTooltip,
                  },
                ],
              },
              {
                title: "Volatility",
                titleFormatter: (cell) => {
                  return '<span title="Monthly return stdDev">Volatility</span>';
                },
                columns: [
                  {
                    sorter,
                    field: "strategyVolatility",
                    title: labelStrategy,
                    formatter: renderCell("PERCENTAGE"),
                    tooltip: cellTagTooltip,
                  },
                ],
              },
              {
                title: "One way turnover",
                columns: [
                  {
                    sorter,
                    field: "turnover",
                    title: labelStrategy,
                    formatter: renderCell("PERCENTAGE"),
                    tooltip: cellTagTooltip,
                  },
                ],
              },
            ];
          } else {
            columns = [
              {
                field: "timeFrame",
                title: "Year",
                sorter: "string",
              },
              {
                field: "strategyYearlyPerformance",
                title: "Yearly performance",
                formatter: renderCell("PERCENTAGE"),
                tooltip: cellTagTooltip,
                sorter,
              },
              {
                field: "strategyMaxDrawdown",
                title: "Max drawdown",
                formatter: renderCell("PERCENTAGE"),
                sorter,
                tooltip: cellTagTooltip,
              },
              {
                field: "strategyVolatility",
                title: "Volatility",
                formatter: renderCell("PERCENTAGE"),
                sorter,
                tooltip: cellTagTooltip,
              },
              {
                title: "One way turnover",
                columns: [
                  {
                    sorter,
                    field: "turnover",
                    title: labelStrategy,
                    formatter: renderCell("PERCENTAGE"),
                    tooltip: cellTagTooltip,
                  },
                ],
              },
            ];
          }

          break;
        }
      }
      // Common fields
      if (hasBenchmark) {
        columns[2].columns.push({
          field: "benchmarkMaxDrawdown",
          title: benchmark,
          formatter: renderCell("PERCENTAGE"),
          tooltip: cellTagTooltip,
          sorter,
        });
        columns[2].columns.push({
          field: "deltaMaxDrawdown",
          title: "Difference",
          formatter: renderCell("PERCENTAGE"),
          tooltip: cellTagTooltip,
          sorter,
        });
        columns[3].columns.push({
          field: "benchmarkVolatility",
          title: benchmark,
          formatter: renderCell("PERCENTAGE"),
          tooltip: cellTagTooltip,
          sorter,
        });
        columns[3].columns.push({
          field: "deltaVolatility",
          title: "Difference",
          formatter: renderCell("PERCENTAGE"),
          tooltip: cellTagTooltip,
          sorter,
        });
      }
      return columns;
    },
    [cellTagTooltip, componente, isUsedInCompare, renderCell, sorter]
  );

  const tableOptions = useMemo(() => {
    return {
      tableOption: {
        selectableRows: false,
        ajaxSorting: false,
      },
    };
  }, []);

  const tableEvents: TableEventsV2 = useMemo(() => {
    return {
      onTableBuilt: () => setStatus((current) => ({ ...current, built: true })),
      onTableDestroyed: () =>
        setStatus((current) => ({ ...current, built: false })),
      columnsLoaded: (columns) => {
        if (columns.length) {
          setStatus((current) => ({ ...current, columns: true }));
        }
      },
    };
  }, []);

  useEffect(() => {
    if (tableBuilt) {
      const table = tableRef.current?.getInstance();

      table?.insertColumns(
        prepareColumns(choosenTab, currentFormatterType, hasBenchmark)
      );
    }
  }, [
    choosenTab,
    currentFormatterType,
    hasBenchmark,
    prepareColumns,
    tableBuilt,
  ]);

  useEffect(() => {
    if (tableBuilt && columnsReady) {
      const table = tableRef.current?.getInstance();

      table?.insertData(tableData);
    }
  }, [columnsReady, tableBuilt, tableData]);

  return (
    <Box
      p={1}
      width={"100%"}
      height={"100%"}
      minHeight={0}
      minWidth={0}
      display={"flex"}
      gap={1}
      flexDirection={"column"}
    >
      <Box flex={3} minHeight={0}>
        <Tabs
          sx={{ borderBottom: 1, borderColor: "divider" }}
          value={choosenTab}
          onChange={handleChange}
        >
          <Tab label="Yearly" value="YEARLY" />
          <Tab label="Monthly" value="MONTHLY" />
          <Tab label="Quarterly" value="QUARTERLY" />
          <Tab label="Scatter" value="SCATTER" />
        </Tabs>
        {choosenTab !== "SCATTER" && (
          <Box py={1} width={"100%"} height={"95%"} display={"flex"}>
            <TrendratingTableV2
              ref={tableRef}
              tableEvents={tableEvents}
              tableOptions={tableOptions}
            />
          </Box>
        )}
        {choosenTab === "SCATTER" && <Scatter data={scatterAndTableData} />}
      </Box>
      <PerformanceTable
        performances={data.analytics.analytics.performances}
        hasBenchmark={hasBenchmark}
      />
    </Box>
  );
};

const getAggregatedData = (data) => {
  const total = {
    equity: { StdDev: null, AnnualizedRateOfReturn: null },
    benchmark: { StdDev: null, AnnualizedRateOfReturn: null },
    diff: { StdDev: null, AnnualizedRateOfReturn: null },
  };

  const annualizedPerformances = data?.yearly?.find(
    (point) => point.timeFrame === "Annualized"
  );

  if (annualizedPerformances) {
    if (annualizedPerformances["strategyYearlyPerformance"]) {
      total["equity"]["AnnualizedRateOfReturn"] =
        annualizedPerformances["strategyYearlyPerformance"];
    }

    if (annualizedPerformances["benchmarkYearlyPerformance"]) {
      total["benchmark"]["AnnualizedRateOfReturn"] =
        annualizedPerformances["benchmarkYearlyPerformance"];
    }

    if (annualizedPerformances["deltaYearlyPerformance"]) {
      total["diff"]["AnnualizedRateOfReturn"] =
        annualizedPerformances["deltaYearlyPerformance"];
    }
  }

  const averagePerformances = data.yearly.find(
    (point) => point.timeFrame === "average"
  );

  if (averagePerformances) {
    if (averagePerformances["strategyVolatility"]) {
      total["equity"]["StdDev"] = averagePerformances["strategyVolatility"];
    }

    if (averagePerformances["benchmarkVolatility"]) {
      total["benchmark"]["StdDev"] = averagePerformances["benchmarkVolatility"];
    }

    if (averagePerformances["deltaVolatility"]) {
      total["diff"]["StdDev"] = averagePerformances["deltaVolatility"];
    }
  }

  return total;
};

const scatterDataPrepare = (value) => {
  let analytics: any = {
    chartScatter: null,
    monthly: null,
    quarterly: null,
    yearly: null,
  };

  analytics.chartScatter = {
    strategy: value.strategy,
    strategyInstrumentBenchmark: value.strategyInstrumentBenchmark,
    total: getAggregatedData(value.analytics),
    years: deepClone(value.analytics.yearly).filter(function (item) {
      if (item.timeFrame !== "Annualized" && item.timeFrame !== "average") {
        return true;
      }

      return false;
    }),
  };

  // compute normalizationThreshold to be used in bar formatter
  analytics.monthly = normalizeAndCutOutliers(
    deepClone(value.analytics.monthly),
    "deltaMonthlyPerformance"
  );
  analytics.quarterly = normalizeAndCutOutliers(
    deepClone(value.analytics.quarterly),
    "deltaQuarterlyPerformance"
  );
  analytics.yearly = normalizeAndCutOutliers(
    deepClone(value.analytics.yearly),
    "deltaYearlyPerformance"
  );

  return analytics;
};
const normalizeAndCutOutliers = (arrayOfObject, property) => {
  if (arrayOfObject.length) {
    var rawData = deepClone(arrayOfObject);
    var LENGTH = rawData.length;
    rawData = rawData.sort(function (a, b) {
      var aAbs = a ? Math.log(Math.abs(a[property]?.value)) : a;
      var bAbs = b ? Math.log(Math.abs(b[property]?.value)) : b;

      if (aAbs > bAbs) {
        return -1;
      }
      if (aAbs < bAbs) {
        return 1;
      }

      return 0;
    });

    var maxIndex = Math.min(
      LENGTH - 1,
      parseInt(((3 / 4) * (LENGTH + 1)).toString())
    );
    var minIndex = parseInt(((1 / 4) * (LENGTH + 1)).toString());
    var max = rawData[maxIndex][property]?.value;
    var min = rawData[minIndex][property]?.value;
    var IQR = max - min;
    var threshold = Math.abs(IQR * 1.5);

    for (var i = 0, length = arrayOfObject.length; i < length; i++) {
      arrayOfObject[i]["_s_normalizationThreshold"] = threshold;
    }
  }

  return arrayOfObject;
};

const PerformanceTable = ({ performances, hasBenchmark }) => {
  const formatter = useFormatter();
  const environment = useEnvironment();

  const uuid = useMemo(() => {
    return environment.get("account").user.id;
  }, [environment]);

  const data = useMemo(() => {
    let rows: {
      oneMonth: number;
      threeMonths: number;
      sixMonths: number;
      oneYear: number;
      twoYears: number;
      threeYears: number;
      fiveYears: number;
      tenYears: number;
      curve: string;
    }[] = [];

    if (!hasBenchmark) {
      delete performances["B"];
      delete performances["D"];
    }

    if (performances != null) {
      let name = "";

      for (const key in performances) {
        switch (key) {
          case "H":
            name = "Strategy";
            break;
          case "B":
            name = "Benchmark";
            break;
          case "D":
            name = "Excess";
        }

        rows.push({
          oneMonth: performances[key]["oneMonth"],
          threeMonths: performances[key]["threeMonths"],
          sixMonths: performances[key]["sixMonths"],
          oneYear: performances[key]["oneYear"],
          twoYears: performances[key]["twoYears"],
          threeYears: performances[key]["threeYears"],
          fiveYears: performances[key]["fiveYears"],
          tenYears: performances[key]["tenYears"],
          curve: name,
        });
      }
    }

    return rows;
  }, [hasBenchmark, performances]);

  const formatPerformance = useCallback(
    (cell) => {
      const value = cell.getValue().value;

      return formatter.custom("number", {
        options: {
          hasPositiveSign: true,
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: value,
        valueHelper: null,
      });
    },
    [formatter]
  );

  const cellTagTooltip = useCallback(
    (e, cell: CellComponent) => {
      const f = cell.getField();
      const tag = cell.getRow().getData()[f].tag;

      if (uuid === 2 || uuid === 4 || uuid === 2700 || uuid === 2816) {
        return tag;
      }
    },
    [uuid]
  );

  const sorter = useCallback((a, b) => {
    return a.value > b.value ? 1 : -1;
  }, []);

  const columns: ColumnDefinition[] = useMemo(
    () => [
      { title: "", field: "curve" },
      {
        title: "1 Month",
        field: "oneMonth",
        formatter: formatPerformance,
        sorter,
        tooltip: cellTagTooltip,
      },
      {
        title: "3 Months",
        field: "threeMonths",
        formatter: formatPerformance,
        sorter,
        tooltip: cellTagTooltip,
      },
      {
        title: "6 Months",
        field: "sixMonths",
        formatter: formatPerformance,
        sorter,
        tooltip: cellTagTooltip,
      },
      {
        title: "1 Year",
        field: "oneYear",
        formatter: formatPerformance,
        sorter,
        tooltip: cellTagTooltip,
      },
      {
        title: "2 Years*",
        field: "twoYears",
        formatter: formatPerformance,
        sorter,
        tooltip: cellTagTooltip,
      },
      {
        title: "3 Years*",
        field: "threeYears",
        formatter: formatPerformance,
        sorter,
        tooltip: cellTagTooltip,
      },
      {
        title: "5 Years*",
        field: "fiveYears",
        formatter: formatPerformance,
        sorter,
        tooltip: cellTagTooltip,
      },
      {
        title: "10 Years*",
        field: "tenYears",
        formatter: formatPerformance,
        sorter,
        tooltip: cellTagTooltip,
      },
    ],
    [cellTagTooltip, formatPerformance, sorter]
  );

  const tableConfig = useMemo(
    () => ({
      tableOption: {
        data,
        columns,
      },
    }),
    [columns, data]
  );

  return (
    <Box display={"flex"} flex={1} flexDirection={"column"} minHeight={0}>
      <Typography
        sx={{ fontSize: "0.8vw", color: "#2a7090", textTransform: "uppercase" }}
      >
        Trailing Returns %
      </Typography>
      <TrendratingTableV2 tableOptions={tableConfig} />
      <Typography>* Annualized performance</Typography>
    </Box>
  );
};

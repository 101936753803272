import {
  Card,
  CardContent,
  FormControlLabel,
  Skeleton,
  Switch,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Instruments } from "../../../../../../../../../api/compute/Instruments";
import {
  Chart,
  millisecondsToDays,
} from "../../../../../../../../../components/Chart";
import { useEnvironment } from "../../../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../../../hooks/useFormatter";
import { TDate } from "../../../../../../../../../trendrating/date/TDate";
import { InstrumentSelection } from "../../../../../widgets/InstrumentSelection";
import styles from "./RatingHistoryView.module.scss";

type RatingHistoryChartProps = {
  historySerie: any[];
  yAxisConfiguration: any[];
  history: any[];
  analyticType: string;
  colors: {
    border: string;
    grid: string;
    instrumentDefault: string;
    instrumentRateA: string;
    instrumentRateB: string;
    instrumentRateC: string;
    instrumentRateD: string;
    instrumentRateU: string;
    line: string;
    trendrating: string;
  };
  years: number;
  setYears: Function;
  areSingleMetrics: boolean;
  setAreSingleMetrics: Function;
  setIsPerc: Function;
  title: string;
};

const getSerieIndicator = (day, property, colors) => {
  var configuration = {
    A_n: {
      color: colors.instrumentRateA,
      name: "A",
    },
    B_n: {
      color: colors.instrumentRateB,
      name: "B",
    },
    C_n: {
      color: colors.instrumentRateC,
      name: "C",
    },
    D_n: {
      color: colors.instrumentRateD,
      name: "D",
    },
    A_p: {
      color: colors.instrumentRateA,
      name: "A",
    },
    B_p: {
      color: colors.instrumentRateB,
      name: "B",
    },
    C_p: {
      color: colors.instrumentRateC,
      name: "C",
    },
    D_p: {
      color: colors.instrumentRateD,
      name: "D",
    },
    AB_n: {
      color: colors.instrumentRateA,
      name: "AB",
    },
    CD_n: {
      color: colors.instrumentRateD,
      name: "CD",
    },
    AB_p: {
      color: colors.instrumentRateA,
      name: "AB",
    },
    CD_p: {
      color: colors.instrumentRateD,
      name: "CD",
    },
  };

  return {
    color: configuration[property].color,
    name: configuration[property].name,
    y: day[property] ? day[property] : 0,
  };
};

const getDay = (history, timestamp) => {
  var historyLength = history.length;

  // by default will be returned today
  var day = history[historyLength - 1];

  for (let i = historyLength - 1; i >= 0; i--) {
    if (history[i]["t"] === timestamp) {
      day = history[i];

      break;
    }
  }

  return day;
};

const chartMouseHoverHandler = (
  pointX,
  analyticType,
  history,
  colors,
  getPointInfo,
  chartRef,
  indicatorNode
) => {
  const day = getDay(history, pointX);

  // Show price in benchamrk node
  if (chartRef.current) {
    const chart = chartRef.current;
    const benchmarkSerie = chart.get("benchmark");

    if (benchmarkSerie) {
      const benchmarkNode = indicatorNode.current;

      if (benchmarkNode) {
        const points = benchmarkSerie?.points;
        const pointOnSerie = points.find((point) => {
          return point.x === pointX;
        });

        if (pointOnSerie) {
          benchmarkNode.lastChild.innerText = `${pointOnSerie.y}`;
        }
      }
    }
  }

  const seriesIndicator: any = [];
  switch (analyticType) {
    case "AB_CD_NUMBER": {
      seriesIndicator.push(getSerieIndicator(day, "AB_n", colors));
      seriesIndicator.push(getSerieIndicator(day, "CD_n", colors));

      break;
    }
    case "A_B_C_D_NUMBER": {
      seriesIndicator.push(getSerieIndicator(day, "A_n", colors));
      seriesIndicator.push(getSerieIndicator(day, "B_n", colors));
      seriesIndicator.push(getSerieIndicator(day, "C_n", colors));
      seriesIndicator.push(getSerieIndicator(day, "D_n", colors));

      break;
    }
    case "A_B_C_D_PERCENTAGE": {
      seriesIndicator.push(getSerieIndicator(day, "A_p", colors));
      seriesIndicator.push(getSerieIndicator(day, "B_p", colors));
      seriesIndicator.push(getSerieIndicator(day, "C_p", colors));
      seriesIndicator.push(getSerieIndicator(day, "D_p", colors));

      break;
    }
    case "AB_CD_PERCENTAGE":
    default: {
      seriesIndicator.push(getSerieIndicator(day, "AB_p", colors));
      seriesIndicator.push(getSerieIndicator(day, "CD_p", colors));

      break;
    }
  }

  getPointInfo({
    day: day,
    series: seriesIndicator,
  });
};

const prepareConfiguration = (
  series,
  yAxis,
  colors,
  analyticType,
  history,
  getPointInfo,
  chartRef,
  indicatorNode
) => {
  return {
    chart: {
      alignTicks: false,
      animation: false,
      borderWidth: 0,
      plotBorderColor: colors["border"],
      plotBorderWidth: 1,
      zoomType: "x",
    },
    credits: { enabled: false },
    exporting: { enabled: false },
    legend: { enabled: false },
    navigator: { enabled: false },
    plotOptions: {
      area: {
        fillOpacity: 1,
        lineWidth: 1,
        marker: {
          fillColor: colors["line"],
          lineColor: colors["line"],
          lineWidth: 1,
        },
        stacking: "normal",
        states: {
          inactive: {
            enabled: false,
          },
        },
      },
      line: {
        animation: false,
        shadow: false,
      },
      series: {
        animation: false,
        point: {
          events: {
            mouseOver: function (e) {
              chartMouseHoverHandler(
                e.target.x,
                analyticType,
                history,
                colors,
                getPointInfo,
                chartRef,
                indicatorNode
              );
            },
          },
        },
      },
    },
    rangeSelector: { enabled: false },
    scrollbar: { enabled: false },
    series: series,
    tooltip: { enabled: false },
    xAxis: {
      crosshair: {
        color: colors["line"],
        zIndex: 3,
      },
      gridLineColor: colors["grid"],
      lineColor: colors["line"],
    },
    yAxis: yAxis,
  };
};

const fromSeriesToMap = (series) => {
  const seriesMap = {};

  let serie: any = null;
  for (let i = 0, length = series.length; i < length; i++) {
    serie = series[i];
    seriesMap[serie["name"]] = serie["y"];
  }

  return seriesMap;
};

export default function RatingHistoryChart({
  historySerie,
  yAxisConfiguration,
  colors,
  history,
  analyticType,
  setAreSingleMetrics,
  setYears,
  years,
  areSingleMetrics,
  setIsPerc,
  title,
}: RatingHistoryChartProps) {
  const [chartConfiguration, setChartConfiguraiton] = useState<any>();
  const formatter = useFormatter();
  const environment = useEnvironment();
  const apiInstrument = useMemo(
    () => new Instruments(environment.get("setup")),
    [environment]
  );
  const [benchmarkHistory, setBenchmarkHistory] = useState<any>();
  const dateRef = useRef<HTMLParagraphElement>(null);
  const refLabelAB = useRef<HTMLParagraphElement>(null);
  const refLabelCD = useRef<HTMLParagraphElement>(null);
  const refLabelA = useRef<HTMLParagraphElement>(null);
  const refLabelB = useRef<HTMLParagraphElement>(null);
  const refLabelC = useRef<HTMLParagraphElement>(null);
  const refLabelD = useRef<HTMLParagraphElement>(null);
  const historyChartRef = useRef<any>(null);
  const benchmarkIndicatorRef = useRef<any>(null);

  const alingHistories = useCallback(
    (benchmarkHistory) => {
      const cuttedHistory: any = [];
      const targetHistoryStart = history?.[0]?.d;
      benchmarkHistory.forEach((item) => {
        if (item.d >= targetHistoryStart) {
          cuttedHistory.push([TDate.daysToMilliseconds(item.d), item.v]);
        }
      });
      return cuttedHistory;
    },
    [history]
  );

  const isPerc = useMemo(
    () => (analyticType.includes("PERCENTAGE") ? "PERCENTAGE" : "NUMBER"),
    [analyticType]
  );

  const getLabelfirstValue = useCallback(
    (label: "A" | "B" | "C" | "D" | "AB" | "CD", dateValue: boolean) => {
      const serieByName = historySerie.find((serie) => serie.name === label);
      if (serieByName) {
        const firstPoint = serieByName["data"][serieByName["data"].length - 1];
        const firstPointValue = firstPoint[dateValue ? 0 : 1];
        return firstPointValue;
      }
    },
    [historySerie]
  );

  const formatNumberByRepresentationMethod = useCallback(
    (number, isPerc) => {
      switch (isPerc) {
        case "NUMBER": {
          return formatter.custom("number", {
            options: {
              decimals: 0,
              notAvailable: {
                input: null,
                output: "-",
              },
            },
            output: "HTML",
            value: number,
            valueHelper: null,
          });
        }
        case "PERCENTAGE": {
          return formatter.custom("number", {
            options: {
              isPercentage: true,
              notAvailable: {
                input: null,
                output: "-",
              },
            },
            output: "HTML",
            value: number,
            valueHelper: null,
          });
        }
        // no default
      }
    },
    [formatter]
  );

  const date = useCallback(
    (day) =>
      formatter.custom("date", {
        options: {
          notAvailable: {
            input: null,
            output: "-",
          },
        },
        output: "HTML",
        value: day,
        valueHelper: null,
      }),
    [formatter]
  );

  /**
   * Initialize labels with the first point of the serie
   */
  const InitializeLabels = useCallback(
    (
      rating:
        | "A"
        | "B"
        | "C"
        | "D"
        | "AB"
        | "CD"
        | "first_date_AB"
        | "first_date_A"
    ) => {
      if (historySerie) {
        switch (rating) {
          case "first_date_AB": {
            const first_date = getLabelfirstValue("AB", true);

            return date(millisecondsToDays(first_date));
          }

          case "first_date_A": {
            const first_date = getLabelfirstValue("A", true);

            return date(millisecondsToDays(first_date));
          }

          case "AB": {
            const firstPointValue = getLabelfirstValue("AB", false);
            return formatNumberByRepresentationMethod(firstPointValue, isPerc);
          }

          case "CD": {
            const firstPointValue = getLabelfirstValue("CD", false);
            return formatNumberByRepresentationMethod(firstPointValue, isPerc);
          }
          case "A": {
            const firstPointValue = getLabelfirstValue("A", false);
            return formatNumberByRepresentationMethod(firstPointValue, isPerc);
          }

          case "B": {
            const firstPointValue = getLabelfirstValue("B", false);
            return formatNumberByRepresentationMethod(firstPointValue, isPerc);
          }

          case "C": {
            const firstPointValue = getLabelfirstValue("C", false);
            return formatNumberByRepresentationMethod(firstPointValue, isPerc);
          }

          case "D": {
            const firstPointValue = getLabelfirstValue("D", false);
            return formatNumberByRepresentationMethod(firstPointValue, isPerc);
          }
        }
      }
    },
    [
      date,
      formatNumberByRepresentationMethod,
      getLabelfirstValue,
      historySerie,
      isPerc,
    ]
  );

  const getPointInfo = useCallback(
    (pointInfo) => {
      dateRef.current!.innerHTML = date(pointInfo.day["d"]);

      if (historySerie) {
        const seriesMap = fromSeriesToMap(pointInfo.series);

        if (historySerie.length === 2) {
          if (refLabelAB.current && refLabelCD.current) {
            refLabelAB.current.innerHTML = formatNumberByRepresentationMethod(
              seriesMap["AB"],
              isPerc
            );
            refLabelCD.current.innerHTML = formatNumberByRepresentationMethod(
              seriesMap["CD"],
              isPerc
            );
          }
        } else if (historySerie.length === 4) {
          if (
            refLabelA.current &&
            refLabelB.current &&
            refLabelC.current &&
            refLabelD.current
          ) {
            refLabelA.current.innerHTML = formatNumberByRepresentationMethod(
              seriesMap["A"],
              isPerc
            );
            refLabelB.current.innerHTML = formatNumberByRepresentationMethod(
              seriesMap["B"],
              isPerc
            );
            refLabelC.current.innerHTML = formatNumberByRepresentationMethod(
              seriesMap["C"],
              isPerc
            );
            refLabelD.current.innerHTML = formatNumberByRepresentationMethod(
              seriesMap["D"],
              isPerc
            );
          }
        }
      }
    },
    [date, formatNumberByRepresentationMethod, historySerie, isPerc]
  );

  useEffect(() => {
    if (historySerie.length && yAxisConfiguration) {
      const series = [...historySerie];

      if (benchmarkHistory && benchmarkHistory?.length) {
        const benchmarkHistoryCopy = [...benchmarkHistory].sort((a, b) =>
          a?.[0] > b?.[0] ? 1 : -1
        );

        const alignedBenchmarkHistory = alingHistories(benchmarkHistoryCopy);

        const benchmarkSerie = {
          color: "#000000",
          name: "Benchmark",
          data: alignedBenchmarkHistory,
          dataGrouping: {
            units: [["day", [1]]],
          },
          currency: "EUR",
          id: "benchmark",
          lineWidth: 1,
          zIndex: 10,
          shadow: true,
          type: "line",
          visible: true,
          yAxis: 4,
        };

        series.push(benchmarkSerie);
      }

      const config = prepareConfiguration(
        series,
        yAxisConfiguration,
        colors,
        analyticType,
        history,
        getPointInfo,
        historyChartRef,
        benchmarkIndicatorRef
      );

      setChartConfiguraiton(config);
    }
  }, [
    alingHistories,
    analyticType,
    benchmarkHistory,
    colors,
    getPointInfo,
    history,
    historySerie,
    yAxisConfiguration,
  ]);

  const onSelectBenchmark = useCallback(
    async (e) => {
      const benchmarkInfo = e ?? null;
      if (benchmarkInfo) {
        try {
          const params = {
            filters: [
              {
                dimension: "symbol",
                segments: [benchmarkInfo.symbol],
              },
            ],
          };
          const temp = await apiInstrument.newFilterAndFetch(
            params,
            "security",
            [{ property: "currency", date: null }],
            false
          );
          let benchmarkData = {
            history: await apiInstrument.historyOf({
              symbol: benchmarkInfo.symbol,
            }),
            currency: temp["data"][0]["currency"],
          };

          setBenchmarkHistory(benchmarkData.history);

          if (benchmarkIndicatorRef.current) {
            const span = document.createElement("span");
            span.innerText = benchmarkData.currency + " ";
            const priceNode = document.createElement("span");

            const point0 = history?.[0]?.d;
            const benchmarStartPoint = benchmarkData.history.find(
              (point) => point["d"] === point0
            );

            if (benchmarStartPoint) {
              priceNode.innerText = benchmarStartPoint["v"];
            }

            benchmarkIndicatorRef.current.appendChild(span);
            benchmarkIndicatorRef.current.appendChild(priceNode);
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        setBenchmarkHistory(undefined);
        const chart = historyChartRef.current;
        if (chart) {
          const benchmarkOldSerie = chart.get("benchmark");
          if (benchmarkOldSerie) {
            benchmarkOldSerie.remove();
          }
        }
      }
    },
    [apiInstrument, history]
  );

  return chartConfiguration ? (
    <Card sx={{ flex: 1 }}>
      <CardContent>
        <Typography>{title}</Typography>
        <Card
          sx={{
            flex: 1,
            border: "1px solid #2A7091",
            display: "flex",
            marginBottom: 2,
          }}
        >
          <CardContent
            sx={{
              flex: 1,
              display: "flex",
              padding: "10px!important",
            }}
          >
            <FormControlLabel
              sx={{ fontSize: "12px!important" }}
              classes={{ label: styles.switch__label }}
              control={
                <Switch
                  size="small"
                  checked={isPerc === "PERCENTAGE"}
                  onChange={(e) => setIsPerc(e.target.checked)}
                />
              }
              label="Percentage"
            />
            <FormControlLabel
              sx={{ fontSize: "12px!important" }}
              classes={{ label: styles.switch__label }}
              control={
                <Switch
                  size="small"
                  checked={areSingleMetrics}
                  onChange={(e) => setAreSingleMetrics(e.target.checked)}
                />
              }
              label="A, B, C, D"
            />
            <FormControlLabel
              sx={{ fontSize: "12px!important" }}
              classes={{ label: styles.switch__label }}
              control={
                <Switch
                  size="small"
                  checked={years === 5}
                  onChange={(e) => setYears(e.target.checked ? 5 : 1)}
                />
              }
              label="5 years"
            />
            <InstrumentSelection
              setInstrument={onSelectBenchmark}
              ref={benchmarkIndicatorRef}
              hasIndicator
            />
          </CardContent>
        </Card>
        <Chart
          constructorType="stockChart"
          ref={historyChartRef}
          options={chartConfiguration}
        />
        {historySerie ? (
          historySerie.length === 2 ? (
            <Card
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
              }}
            >
              <CardContent>
                <strong ref={dateRef}>
                  {InitializeLabels("first_date_AB")}
                </strong>
                <p className="tPeerRatingHistoryIndicator-item">
                  <strong className="format-rate format-rate--A">A</strong>
                  <strong className="format-rate format-rate--B">B</strong>:
                  <span
                    ref={refLabelAB}
                    className="tPeerRatingHistoryIndicator-legendValue"
                  >
                    {InitializeLabels("AB")}
                  </span>
                </p>

                <p className="tPeerRatingHistoryIndicator-item">
                  <strong className="format-rate format-rate--C">C</strong>
                  <strong className="format-rate format-rate--D">D</strong>:
                  <span
                    ref={refLabelCD}
                    className="tPeerRatingHistoryIndicator-legendValue"
                  >
                    {InitializeLabels("CD")}
                  </span>
                </p>
              </CardContent>
            </Card>
          ) : (
            <Card
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
              }}
            >
              <CardContent>
                <strong ref={dateRef}>
                  {InitializeLabels("first_date_A")}
                </strong>
                <p className="tPeerRatingHistoryIndicator-item">
                  <strong className="format-rate format-rate--A">A</strong>:
                  <span
                    ref={refLabelA}
                    className="tPeerRatingHistoryIndicator-legendValue"
                  >
                    {InitializeLabels("A")}
                  </span>
                </p>

                <p className="tPeerRatingHistoryIndicator-item">
                  <strong className="format-rate format-rate--B">B</strong>:
                  <span
                    ref={refLabelB}
                    className="tPeerRatingHistoryIndicator-legendValue"
                  >
                    {InitializeLabels("B")}
                  </span>
                </p>

                <p className="tPeerRatingHistoryIndicator-item">
                  <strong className="format-rate format-rate--C">C</strong>:
                  <span
                    ref={refLabelC}
                    className="tPeerRatingHistoryIndicator-legendValue"
                  >
                    {InitializeLabels("C")}
                  </span>
                </p>

                <p className="tPeerRatingHistoryIndicator-item">
                  <strong className="format-rate format-rate--D">D</strong>:
                  <span
                    ref={refLabelD}
                    className="tPeerRatingHistoryIndicator-legendValue"
                  >
                    {InitializeLabels("D")}
                  </span>
                </p>
              </CardContent>
            </Card>
          )
        ) : (
          <></>
        )}
      </CardContent>
    </Card>
  ) : (
    <Card
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
      }}
    >
      <CardContent sx={{ flex: 1 }}>
        <Stack spacing={1}>
          <Skeleton
            variant="text"
            sx={{
              fontSize: "20px",
              marginBottom: "20px",
            }}
          />

          {/* For other variants, adjust the size with `width` and `height` */}
          <Skeleton variant="rectangular" width={"100%"} height={30} />
          <Skeleton
            variant="rectangular"
            sx={{ marginY: 3 }}
            width={"100%"}
            height={200}
          />
          <Skeleton variant="rectangular" width={"100%"} height={30} />
        </Stack>
      </CardContent>
    </Card>
  );
}

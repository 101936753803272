export const _formatPercentageSign = (value, format, FORMATTER_OPTIONS) => {
  return format.custom("number", {
    options: FORMATTER_OPTIONS.PERCENTAGE_SIGN,
    output: "HTML",
    value: value,
    valueHelper: null,
  });
};
export const _formatPercentageSignColored = (
  value,
  format,
  FORMATTER_OPTIONS
) => {
  return format.custom("number", {
    options: FORMATTER_OPTIONS.PERCENTAGE_SIGN_COLORED,
    output: "HTML",
    value: value,
    valueHelper: null,
  });
};
export const _formatNumber = (value, format, FORMATTER_OPTIONS) => {
  return format.custom("number", {
    options: FORMATTER_OPTIONS.NUMBER,
    output: "HTML",
    value: value,
    valueHelper: null,
  });
};
export const _formatNumberSignColored = (value, format, FORMATTER_OPTIONS) => {
  return format.custom("number", {
    options: FORMATTER_OPTIONS.NUMBER_SIGN_COLORED,
    output: "HTML",
    value: value,
    valueHelper: null,
  });
};
export const _formatNumberSignColoredInverted = (
  value,
  format,
  FORMATTER_OPTIONS
) => {
  return format.custom("number", {
    options: FORMATTER_OPTIONS.NUMBER_SIGN_COLORED_INVERTED,
    output: "HTML",
    value: value,
    valueHelper: null,
  });
};
export const _formatPercentageSignColoredInverted = (
  value,
  format,
  FORMATTER_OPTIONS
) => {
  return format.custom("number", {
    options: FORMATTER_OPTIONS.PERCENTAGE_SIGN_COLORED_INVERTED,
    output: "HTML",
    value: value,
    valueHelper: null,
  });
};
export const _formatPercentageNoDecimals = (
  value,
  format,
  FORMATTER_OPTIONS
) => {
  return format.custom("number", {
    options: FORMATTER_OPTIONS.PERCENTAGE_NO_DECIMALS,
    output: "HTML",
    value: value,
    valueHelper: null,
  });
};
export const _formatNumber4Decimals = (value, format, FORMATTER_OPTIONS) => {
  return format.custom("number", {
    options: FORMATTER_OPTIONS.NUMBER_4_DECIMALS,
    output: "HTML",
    value: value,
    valueHelper: null,
  });
};
export const _formatNumber4DecimalsColored = (
  value,
  format,
  FORMATTER_OPTIONS
) => {
  return format.custom("number", {
    options: FORMATTER_OPTIONS.NUMBER_4_DECIMALS_COLORED,
    output: "HTML",
    value: value,
    valueHelper: null,
  });
};
export const _formatPercentage = (value, format, FORMATTER_OPTIONS) => {
  return format.custom("number", {
    options: FORMATTER_OPTIONS.PERCENTAGE,
    output: "HTML",
    value: value,
    valueHelper: null,
  });
};
export const FORMATTER_OPTIONS = {
  NUMBER: {
    decimals: 0,
    notAvailable: {
      input: null,
      output: "",
    },
  },
  NUMBER_4_DECIMALS: {
    isPercentage: false,
    decimals: 4,
    notAvailable: {
      input: null,
      output: "",
    },
  },
  NUMBER_4_DECIMALS_COLORED: {
    colored: "positive",
    decimals: 4,
    notAvailable: {
      input: null,
      output: "",
    },
  },
  NUMBER_SIGN_COLORED: {
    colored: "positive",
    decimals: 0,
    hasPositiveSign: true,
    notAvailable: {
      input: null,
      output: "",
    },
  },
  NUMBER_SIGN_COLORED_INVERTED: {
    colored: "negative",
    decimals: 0,
    hasPositiveSign: true,
    notAvailable: {
      input: null,
      output: "",
    },
  },
  PERCENTAGE: {
    isPercentage: true,
    notAvailable: {
      input: null,
      output: "",
    },
  },
  PERCENTAGE_NO_DECIMALS: {
    decimals: 0,
    isPercentage: true,
    notAvailable: {
      input: null,
      output: "",
    },
  },
  PERCENTAGE_SIGN: {
    hasPositiveSign: true,
    isPercentage: true,
    notAvailable: {
      input: null,
      output: "",
    },
  },
  PERCENTAGE_SIGN_COLORED: {
    colored: "positive",
    hasPositiveSign: true,
    isPercentage: true,
    notAvailable: {
      input: null,
      output: "",
    },
  },
  PERCENTAGE_SIGN_COLORED_INVERTED: {
    colored: "negative",
    hasPositiveSign: true,
    isPercentage: true,
    notAvailable: {
      input: null,
      output: "",
    },
  },
};

export const existenceChecker = (arr, setHasXXXinfo, key) => {
  let hasElement = false;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][1][key].value != null) {
      hasElement = true;
      break;
    }
  }
  setHasXXXinfo(hasElement);
};

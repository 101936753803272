import { Box, Card, CardContent, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Chart } from "../../../../../../../../../../../components/Chart";
import { useFormatter } from "../../../../../../../../../../../hooks/useFormatter";
import {
  FORMATTER_OPTIONS,
  _formatPercentageSign,
  _formatPercentageSignColored,
  _formatNumberSignColoredInverted,
  _formatNumber,
  _formatPercentageSignColoredInverted,
  existenceChecker,
  _formatPercentage,
} from "./utils";
import { useEnvironment } from "../../../../../../../../../../../hooks/useEnvironment";

type Props = {
  value: any;
  getChartData: any;
  showChart: boolean;
  benchmarkLabel?: string;
};

export default function Risk({
  value,
  getChartData,
  showChart,
  benchmarkLabel,
}: Props) {
  const [hasBenchmarkInfo, setHasBenchmarkInfo] = useState(false);
  const [hasStrategyInfo, setHasStrategyInfo] = useState(false);
  const [hasDeltaInfo, setHasDeltaInfo] = useState(false);

  const environment = useEnvironment();
  const showAnalyticsTtitle = useMemo(() => {
    const uuid = environment.get("account").user.id;

    if (uuid === 2 || uuid === 4 || uuid === 2700 || uuid === 2816) {
      return true;
    }

    return false;
  }, [environment]);

  useEffect(() => {
    const arr: any = Object.entries(value);
    //! checking if we have strategy, benchmark or delta info
    //! because we'll display stuff based on existence of info
    existenceChecker(arr, setHasStrategyInfo, "strategy");
    existenceChecker(arr, setHasBenchmarkInfo, "benchmark");
    existenceChecker(arr, setHasDeltaInfo, "delta");
  }, [value]);

  const formatter = useFormatter();
  const getPercentageValue = useCallback(
    (
      value: { value: number | null; tag: string } | null,
      type: "strategy" | "benchmark" | "delta",
      unsigned?: boolean
    ) => {
      if (value == null || value.value == null) {
        return null;
      }
      let formated: any = null;
      if (type === "strategy" || type === "benchmark") {
        formated =
          unsigned === true
            ? _formatPercentage(value.value, formatter, FORMATTER_OPTIONS)
            : _formatPercentageSign(value.value, formatter, FORMATTER_OPTIONS);
      } else {
        formated = _formatPercentageSignColored(
          value.value,
          formatter,
          FORMATTER_OPTIONS
        );
      }
      return (
        <td style={{ textAlign: "right" }}>
          <span
            title={showAnalyticsTtitle ? value.tag : undefined}
            dangerouslySetInnerHTML={{ __html: formated }}
          ></span>
        </td>
      );
    },
    [formatter, showAnalyticsTtitle]
  );
  const getPercentageValue_standardDeviation = useCallback(
    (
      value: { value: number | null; tag: string } | null,
      type: "strategy" | "benchmark" | "delta"
    ) => {
      if (value == null || value.value == null) {
        return null;
      }
      let formated: any = null;
      if (type === "strategy" || type === "benchmark") {
        formated = _formatPercentageSign(
          value.value,
          formatter,
          FORMATTER_OPTIONS
        );
      } else {
        formated = _formatPercentageSignColoredInverted(
          value.value,
          formatter,
          FORMATTER_OPTIONS
        );
      }
      return (
        <td style={{ textAlign: "right" }}>
          <span
            title={showAnalyticsTtitle ? value.tag : undefined}
            dangerouslySetInnerHTML={{ __html: formated }}
          ></span>
        </td>
      );
    },
    [formatter, showAnalyticsTtitle]
  );
  const getLoosingPeriods = useCallback(
    (
      value: { value: number | null; tag: string } | null,
      type: "strategy" | "benchmark" | "delta"
    ) => {
      if (value == null || value.value == null) {
        return null;
      }
      let formated: any = null;
      if (type === "strategy" || type === "benchmark") {
        formated = _formatNumber(value.value, formatter, FORMATTER_OPTIONS);
      } else {
        formated = _formatNumberSignColoredInverted(
          value.value,
          formatter,
          FORMATTER_OPTIONS
        );
      }
      return (
        <td style={{ textAlign: "right" }}>
          <span
            title={showAnalyticsTtitle ? value.tag : undefined}
            dangerouslySetInnerHTML={{ __html: formated }}
          ></span>
        </td>
      );
    },
    [formatter, showAnalyticsTtitle]
  );
  const [propertiesToGetChartData, setPropertiesToGetChartData] = useState<any>(
    {
      propertyName: "maxDrawdown",
      propertyLabel: "Max drawdown",
      FORMATTER_OPTIONS: FORMATTER_OPTIONS.PERCENTAGE,
    }
  );
  const chartData = useMemo(
    () => getChartData(propertiesToGetChartData),
    [getChartData, propertiesToGetChartData]
  );

  return (
    <Card sx={{ boxShadow: 3, overflow: "visible" }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          pb: "8px !important",
          p: 1,
        }}
      >
        <Box>
          <Typography variant="subtitle1">Risk</Typography>
        </Box>
        <Box display={"flex"} gap={2}>
          <Card sx={{ boxShadow: 3, width: showChart ? "60%" : "100%" }}>
            <CardContent sx={{ pb: "8px !important", p: 1 }}>
              <table width={"100%"}>
                <thead>
                  <tr
                    style={{ borderBottom: 1, borderColor: "rgba(0,0,0,0.5)" }}
                  >
                    <td style={{ width: "40%" }}>&nbsp;</td>
                    {hasStrategyInfo && (
                      <td style={{ textAlign: "right", width: "20%" }}>
                        Strategy
                      </td>
                    )}
                    {hasBenchmarkInfo && (
                      <td style={{ textAlign: "right", width: "20%" }}>
                        {benchmarkLabel ?? "Benchmark"}
                      </td>
                    )}
                    {hasDeltaInfo && (
                      <td style={{ textAlign: "right", width: "20%" }}>
                        Difference
                      </td>
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setPropertiesToGetChartData({
                        propertyName: "maxDrawdown",
                        propertyLabel: "Max drawdown",
                        FORMATTER_OPTIONS: FORMATTER_OPTIONS.PERCENTAGE,
                      })
                    }
                  >
                    <td>Max drawdown</td>
                    {hasStrategyInfo &&
                      getPercentageValue(
                        value.maxDrawdown.strategy,
                        "strategy"
                      )}
                    {hasBenchmarkInfo &&
                      getPercentageValue(
                        value.maxDrawdown.benchmark,
                        "benchmark"
                      )}
                    {hasDeltaInfo &&
                      getPercentageValue(value.maxDrawdown.delta, "delta")}
                  </tr>
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setPropertiesToGetChartData({
                        propertyName: "avgYearlyDrawdown",
                        propertyLabel: "Average yearly drawdown",
                        FORMATTER_OPTIONS: FORMATTER_OPTIONS.PERCENTAGE,
                      })
                    }
                  >
                    <td>Average yearly drawdown</td>
                    {hasStrategyInfo &&
                      getPercentageValue(
                        value.avgYearlyDrawdown.strategy,
                        "strategy"
                      )}
                    {hasBenchmarkInfo &&
                      getPercentageValue(
                        value.avgYearlyDrawdown.benchmark,
                        "benchmark"
                      )}
                    {hasDeltaInfo &&
                      getPercentageValue(
                        value.avgYearlyDrawdown.delta,
                        "delta"
                      )}
                  </tr>
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setPropertiesToGetChartData({
                        propertyName: "monthlyStdDev",
                        propertyLabel: "Monthly standard deviation",
                        FORMATTER_OPTIONS: FORMATTER_OPTIONS.PERCENTAGE,
                        isColorInvertedAdtDifference: true,
                      })
                    }
                  >
                    <td> Monthly standard deviation</td>
                    {hasStrategyInfo &&
                      getPercentageValue(
                        value.monthlyStdDev.strategy,
                        "strategy",
                        true
                      )}
                    {hasBenchmarkInfo &&
                      getPercentageValue(
                        value.monthlyStdDev.benchmark,
                        "benchmark",
                        true
                      )}
                    {hasDeltaInfo &&
                      getPercentageValue_standardDeviation(
                        value.monthlyStdDev.delta,
                        "delta"
                      )}
                  </tr>
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setPropertiesToGetChartData({
                        propertyName: "maxConsecutiveLoosingPeriod",
                        propertyLabel: "Max consecutive negative months",
                        FORMATTER_OPTIONS: FORMATTER_OPTIONS.NUMBER,
                        isColorInvertedAdtDifference: true,
                      })
                    }
                  >
                    <td> Max consecutive negative months</td>
                    {hasStrategyInfo &&
                      getLoosingPeriods(
                        value.maxConsecutiveLoosingPeriod.strategy,
                        "strategy"
                      )}
                    {hasBenchmarkInfo &&
                      getLoosingPeriods(
                        value.maxConsecutiveLoosingPeriod.benchmark,
                        "benchmark"
                      )}
                    {hasDeltaInfo &&
                      getLoosingPeriods(
                        value.maxConsecutiveLoosingPeriod.delta,
                        "delta"
                      )}
                  </tr>
                </tbody>
              </table>
            </CardContent>
          </Card>
          {showChart && (
            <Card sx={{ boxShadow: 3, width: "40%" }}>
              <CardContent sx={{ pb: "8px !important", p: 1 }}>
                <Chart constructorType="chart" options={chartData} />
              </CardContent>
            </Card>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

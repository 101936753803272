import { Box, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Utils } from "../../../../../../../../../../api/compute/Utils";
import { useEnvironment } from "../../../../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../../../../hooks/useFormatter";
import KeyRatios from "./widgets/KeyRatios";
import Performance from "./widgets/Performance";
import Risk from "./widgets/Risk";

type PeriodSelectorProps = {
  firstPosDate?: number;
  changePeriod: (period?) => Promise<any>;
};

type SelectablePeriods = {
  label: string;
  value: number;
  active: boolean;
  key: string;
}[];

type Props = {
  isUsedInCompareStrategies?: boolean;
  showChart?: boolean;
  value: {
    performance: {
      cumulative: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      annualized: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      yearlyAverage: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      winningPeriods: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
    };
    risk: {
      maxDrawdown: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      avgYearlyDrawdown: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      monthlyStdDev: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      maxConsecutiveLoosingPeriod: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
    };
    keyRatios: {
      avgTurnover: {
        strategy: number;
        benchmark: number | null;
        delta: null | number;
      };
      sharpeRatio: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      sterlingRatio: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      sortinoRatio: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      beta: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      trackingError: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      infoRatio: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      treynorRatio: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      percentagePositivePeriod: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      winningPeriod: {
        strategy: number | null;
        benchmark: number | null;
        delta: number | null;
      };
      winningAvgPeriod: {
        strategy: number | null;
        benchmark: number | null;
        delta: number | null;
      };
      losingPeriod: {
        strategy: number | null;
        benchmark: number | null;
        delta: number | null;
      };
      losingAvgPeriod: {
        strategy: number | null;
        benchmark: number | null;
        delta: number | null;
      };
    };
    period: any;
    hasBenchmark: boolean;
  };
  startDate?: number;
  endDate: number;
  onChangePeriod: (period) => any;
};

export default function KeyFacts({
  value,
  showChart = true,
  isUsedInCompareStrategies,
  startDate,
  onChangePeriod,
}: Props) {
  const [periodKeyFacts, setPeriodKeyFacts] = useState<any>();
  const format = useFormatter();
  const benchmarkLabel = useMemo(() => {
    return isUsedInCompareStrategies === true ? "Strategy 2" : undefined;
  }, [isUsedInCompareStrategies]);

  const wrapChangePeriod = useCallback(
    async (from: number) => {
      if (from === -1) {
        setPeriodKeyFacts(undefined);

        return;
      }

      const keyFacts = await onChangePeriod(from);

      setPeriodKeyFacts(keyFacts);
    },
    [onChangePeriod]
  );

  return (
    <Box
      display={"flex"}
      flexDirection="column"
      height={"100%"}
      flex={1}
      justifyContent={"space-between"}
      overflow="auto"
      p={1}
      gap={1}
    >
      <PeriodSelector
        changePeriod={wrapChangePeriod}
        firstPosDate={startDate}
      />
      <Performance
        benchmarkLabel={benchmarkLabel}
        showChart={showChart}
        value={periodKeyFacts?.keyFacts?.performance ?? value.performance}
        getChartData={(obj) =>
          getChartData(
            periodKeyFacts?.keyFacts?.performance ?? value.performance,
            obj.propertyName,
            value.hasBenchmark,
            `Performance - ${obj.propertyLabel}`,
            obj.FORMATTER_OPTIONS,
            format
          )
        }
      />
      <Risk
        benchmarkLabel={benchmarkLabel}
        showChart={showChart}
        value={periodKeyFacts?.keyFacts?.risk ?? value.risk}
        getChartData={(obj) =>
          getChartData(
            periodKeyFacts?.keyFacts?.risk ?? value.risk,
            obj.propertyName,
            value.hasBenchmark,
            `Risk - ${obj.propertyLabel}`,
            obj.FORMATTER_OPTIONS,
            format,
            obj.isColorInvertedAdtDifference ?? obj.isColorInvertedAdtDifference
          )
        }
      />
      <KeyRatios
        benchmarkLabel={benchmarkLabel}
        showChart={showChart}
        value={periodKeyFacts?.keyFacts?.keyRatios ?? value.keyRatios}
        getChartData={(obj) =>
          getChartData(
            periodKeyFacts?.keyFacts?.keyRatios ?? value.keyRatios,
            obj.propertyName,
            value.hasBenchmark,
            `Key ratios - ${obj.propertyLabel}`,
            obj.FORMATTER_OPTIONS,
            format,
            obj.isColorInvertedAdtDifference ?? obj.isColorInvertedAdtDifference
          )
        }
      />
    </Box>
  );
}

const getChartData = (
  data,
  propertyName,
  hasBenchmark,
  label,
  _FORMATTER_OPTIONS,
  format,
  isColorInvertedAdtDifference?
) => {
  const getColorForInverted = (val) => {
    if (val > 0) {
      return "#f00000";
    } else {
      return "#008000";
    }
  };
  const series = [
    {
      _formatter: {
        type: "number",
        options: _FORMATTER_OPTIONS,
      },
      name: label,
      data: [
        {
          x: 0,
          y: data?.[propertyName].strategy.value,
          color:
            isColorInvertedAdtDifference === true
              ? getColorForInverted(data?.[propertyName].strategy.value)
              : null,
        },
        {
          x: 1,
          y: data?.[propertyName].benchmark.value,
          color:
            isColorInvertedAdtDifference === true
              ? getColorForInverted(data?.[propertyName].benchmark.value)
              : null,
        },

        {
          x: 2,
          y: data?.[propertyName].delta.value,
          color:
            isColorInvertedAdtDifference === true
              ? getColorForInverted(data?.[propertyName].delta.value)
              : null,
        },
      ],
    },
  ];
  const options = {
    chart: {
      animation: false,
      borderColor: "#d3d3d3",
      borderRadius: 0,
      height: "150",
      borderWidth: 1,
      type: "bar",
    },
    credits: { enabled: false },
    legend: { enabled: false },
    navigation: { buttonOptions: { enabled: false } },
    plotOptions: {
      bar: {
        animation: false,
        color: "#008000",
        negativeColor: "#f00000",
        states: { hover: { enabled: false } },
      },
    },
    xAxis: {
      categories:
        hasBenchmark === true
          ? ["Strategy", "Benchmark", "Difference"]
          : ["Strategy", "", ""],
      tickLength: 4,
      tickWidth: 0,
    },
    yAxis: {
      labels: {
        formatter: function () {
          const that: any = this;
          return format.custom("number", {
            options: _FORMATTER_OPTIONS,
            output: "HTML",
            value: that.value,
            valueHelper: null,
          });
        },
      },
      title: { text: null },
    },
    series: series,
    title: { text: label },
    tooltip: {
      formatter: function () {
        const that: any = this;
        var formatter = that.series.userOptions._formatter;
        var innerHTML = format.custom(formatter.type, {
          options: formatter.options,
          output: "HTML",
          value: that.point.y,
          valueHelper: null,
        });

        return that.x + "<br/><strong>" + innerHTML + "</strong>";
      },
    },
  };
  return options;
};

const PeriodSelector = ({
  firstPosDate,
  changePeriod,
}: PeriodSelectorProps) => {
  const [selectablePeriods, setSelectablePeriods] = useState<SelectablePeriods>(
    []
  );
  const options = useMemo(
    () => ({
      ALL: { label: "Since inception", key: "Since inception", value: null },
      "5_YEARS": { label: "5 Years", key: "5Y", value: "5,YEARLY" },
      "3_YEARS": { label: "3 Years", key: "3Y", value: "3,YEARLY" },
      "2_YEARS": { label: "2 Years", key: "2Y", value: "2,YEARLY" },
      "1_YEAR": { label: "1 Year", key: "1Y", value: "1,YEARLY" },
    }),
    []
  );

  const environment = useEnvironment();
  const utilsAPI = useMemo(
    () => new Utils(environment.get("setup")),
    [environment]
  );

  const showSelector = useMemo(
    () => firstPosDate && selectablePeriods.length,
    [firstPosDate, selectablePeriods.length]
  );

  const filterImpossibleChoices = useCallback(
    (periods: { optionKey: string; value: number }[]) => {
      const validPeriods: SelectablePeriods = [];
      const start = firstPosDate ?? 99999;

      for (const p of periods) {
        if (p.value > start) {
          validPeriods.push({
            value: p.value,
            label: options[p.optionKey].label,
            key: options[p.optionKey].key,
            active: false,
          });
        }
      }

      if (validPeriods.length) {
        validPeriods.unshift({
          value: -1,
          label: options["ALL"].label,
          key: options["ALL"].key,
          active: true,
        });
      }

      return validPeriods;
    },
    [firstPosDate, options]
  );

  const getPeriods = useCallback(async () => {
    if (!firstPosDate) {
      return;
    }

    const periods: string[] = [];

    for (const key in options) {
      if (key === "ALL") {
        continue;
      }

      periods.push(options[key].value);
    }

    const response = await utilsAPI.today(periods);

    const periodsMap = {};

    for (const key in options) {
      if (key === "ALL") {
        periodsMap[key] = key;
        periodsMap["value"] = null;
      }

      periodsMap[key] = response[options[key].value];
    }

    const possiblePeriods: { optionKey: string; value: number }[] = [];

    for (const key in periodsMap) {
      possiblePeriods.push({ optionKey: key, value: periodsMap[key] });
    }

    setSelectablePeriods(filterImpossibleChoices(possiblePeriods));
  }, [filterImpossibleChoices, firstPosDate, options, utilsAPI]);

  const onComponentMount = useCallback(() => {
    getPeriods();
  }, [getPeriods]);

  const onSelectPeriod = useCallback(
    (p: { label: string; key: string; value: number }) => {
      setSelectablePeriods((current) => {
        for (const period of current) {
          if (period.label !== p.label) {
            period.active = false;
          } else {
            period.active = true;
          }
        }

        return [...current];
      });

      changePeriod(p.key !== "Since Inception" ? p.key : null);
    },
    [changePeriod]
  );

  useEffect(() => {
    onComponentMount();
  }, [onComponentMount]);

  return showSelector ? (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <Typography>Analysis Period </Typography>
        <Box display={"flex"} gap={2}>
          {selectablePeriods.map((period) => {
            return (
              <Box
                key={uuidv4()}
                onClick={() =>
                  onSelectPeriod({
                    label: period.label,
                    key: period.key,
                    value: period.value,
                  })
                }
                component={"li"}
                color={period.active ? "black" : "rgba(0,0,0, 0.7)"}
                borderBottom={period.active ? "2px solid black" : "none"}
                padding={"3px"}
                sx={{ cursor: "pointer", fontSize: "0.8vw" }}
              >
                {period.label}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  ) : (
    <></>
  );
};

import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import React, { useCallback, useMemo } from "react";

type AutoRebalanceSmsNotificationProps = {
  value: true | false | undefined | null;
  setValueCallback: Function;
};

export default function AutoRebalanceSmsNotification({
  value,
  setValueCallback,
}: AutoRebalanceSmsNotificationProps) {
  const notification = useMemo(() => {
    if (typeof value === "boolean") {
      return value;
    } else {
      return undefined;
    }
  }, [value]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let radioValue = (event.target as HTMLInputElement).value;
      if (radioValue === "true") {
        setValueCallback(true);
      } else {
        setValueCallback(false);
      }
    },
    [setValueCallback]
  );
  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardContent sx={{ pb: "16px !important" }}>
        <FormControl>
          <Typography variant="subtitle1">
            Receive a notification on Auto Rebalance Systematic Portfolios 
          </Typography>

          <RadioGroup row value={notification} onChange={handleChange}>
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="I want to be notified"
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="I do not Want to be notified"
            />
          </RadioGroup>
        </FormControl>
      </CardContent>
    </Card>
  );
}

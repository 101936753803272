import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ColumnDefinition } from "tabulator-tables";
import { ErrorBoundary } from "../../../../../../ErrorBoundary";
import { Instruments } from "../../../../../../api/compute/Instruments";
import { extractSymbols } from "../../../../../../api/compute/commons";
import { TableV2 } from "../../../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../../../components/table/v2/TableV2";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { useEventBus } from "../../../../../../hooks/useEventBus";

type HoldingsResultTableProps = {
  data: any[];
  rowClickHandler: (e) => any;
  setColumnsFields: (cols: ColumnDefinition[]) => any;
  expandEventId: string;
};

export function HoldingsResultTable({
  data,
  rowClickHandler,
  setColumnsFields,
  expandEventId,
}: HoldingsResultTableProps) {
  const environment = useEnvironment();
  const tableRef = useRef<{
    getInstance: () => TableV2;
    refreshSearch: () => void;
  }>();
  const [isSearchActive, setIsSearchActive] = useState(false);

  useEffect(() => {
    if (data && data.length) {
      tableRef.current?.refreshSearch();

      // Set FirstData
      const table = tableRef.current?.getInstance();
      table?.insertData(data);
      const symbols = data.map((d) => d.symbol);

      const searchCtx = {
        filters: [{ dimension: "symbol", segments: symbols }],
        sort: [{ dimension: "name", rev: false }],
        page: { page: 1, rows: 20000 },
      };

      table?.updateSearchContext(searchCtx);
    }
  }, [data]);

  const rowsMap = useMemo(() => {
    const currentMap = data.reduce((prev, acc) => {
      prev[acc.symbol] = acc;
      return prev;
    }, {});

    return currentMap;
  }, [data]);

  const apiInstruments = useMemo(() => {
    return new Instruments(environment.get("setup"));
  }, [environment]);

  const config = useMemo(
    () =>
      environment.get("account")?.product?.configuration?.strategy_builder
        ?.tabs ?? null,
    [environment]
  );
  const tabsConfigMap = useMemo(() => {
    if (config) {
      return config.reduce((prev, current) => {
        return { ...prev, [current.id]: current };
      }, {});
    }

    return null;
  }, [config]);

  const editorColumnsConfiguration = useMemo(() => {
    if (tabsConfigMap && "allocation" in tabsConfigMap) {
      return tabsConfigMap["allocation"]?.widgets?.viewer ?? null;
    }

    return null;
  }, [tabsConfigMap]);

  const onClickHandler = useCallback(
    (e, row) => {
      const value = row.getData();

      if (rowClickHandler != null) {
        rowClickHandler(value);
      }
    },
    [rowClickHandler]
  );

  const updateTableData = useCallback(
    (sortedSecurities) => {
      const symbols = extractSymbols(sortedSecurities);
      const table = tableRef.current?.getInstance();

      const newState: any = [];

      symbols.forEach((symbol) => {
        newState.push(rowsMap[symbol]);
      });

      table?.insertData(newState);
    },
    [rowsMap]
  );

  const onColSortHandler = useCallback(
    async ({ field, direction }) => {
      const table = tableRef.current?.getInstance();
      const currentData = table?.getRows().map((row) => row.getData()) ?? [];
      const symbols = extractSymbols(currentData);

      const fieldsConfiguration = environment.get("properties").properties;
      const flatProperties = {};

      for (const group in fieldsConfiguration) {
        for (const field in fieldsConfiguration[group])
          flatProperties[field] = fieldsConfiguration[group][field];
      }

      const payload = {
        constraints: [
          [
            {
              dimension: "symbol",
              operator: "equals",
              segments: symbols,
            },
          ],
        ],
        page: {
          page: 1,
          rows: 30000,
        },
      };

      const fieldSetup = flatProperties?.[field];
      const requireInjection = fieldSetup?.requireInjection ?? false;
      const isPointInTime = fieldSetup?.pointInTime ?? false;
      const backendPropertySort = fieldSetup?.backendPropertySort ?? field;

      if (requireInjection || isPointInTime) {
        const sortId = `${field}${Date.now()}:${field}`;
        payload["sort"] = [{ dimension: sortId, rev: direction === "desc" }];

        if (backendPropertySort !== "marketcap") {
          payload["sort"].push({ dimension: "marketcap", rev: true });
        }

        payload["injestion"] = {
          data: currentData.map((item) => ({
            symbol: item.symbol,
            value:
              typeof item[backendPropertySort] !== "string"
                ? JSON.stringify(item[backendPropertySort])
                : item[field],
          })),
          type:
            typeof currentData?.[0]?.[backendPropertySort] === "string"
              ? "string"
              : "number",
          field: sortId,
        };
      } else {
        payload["sort"] = [
          { dimension: backendPropertySort, rev: direction === "desc" },
        ];

        if (field !== "marketcap") {
          payload["sort"].push({ dimension: "marketcap", rev: true });
        }
      }

      const screeningResponse = await apiInstruments.screening(payload, true);
      const orderedData = screeningResponse?.data?.map((row) => ({
        symbol: row,
      }));
      updateTableData(orderedData);
    },

    [apiInstruments, environment, updateTableData]
  );

  const onTableColsChange = useCallback(
    (columns) => {
      if (columns.length) {
        columns = columns.map((col) => col.getDefinition());

        setColumnsFields(columns);

        if (isSearchActive) {
          tableRef.current?.refreshSearch();
        }
      }
    },
    [isSearchActive, setColumnsFields]
  );

  const { dispatch } = useEventBus();

  const handleTableExpand = useCallback(() => {
    dispatch(expandEventId);
  }, [dispatch, expandEventId]);

  const onClearSearch = useCallback(() => {
    setIsSearchActive(false);

    if (data && data.length) {
      // Set FirstData
      const table = tableRef.current?.getInstance();
      table?.insertData(data);
    }
  }, [data]);

  const handleSearchTitle = useCallback(
    (symbols: string[]) => {
      setIsSearchActive(true);
      const symbolsList = symbols.map((s) => ({ symbol: s }));
      updateTableData(symbolsList);
    },
    [updateTableData]
  );

  const toosEvents = useMemo(
    () => ({
      onExpand: handleTableExpand,
      handleSearchResult: handleSearchTitle,
      onClearSearch,
    }),
    [handleSearchTitle, handleTableExpand, onClearSearch]
  );

  const tableEvents = useMemo(
    () => ({
      rowClick: onClickHandler,
      headerSort: onColSortHandler,
      columnsLoaded: onTableColsChange,
    }),
    [onClickHandler, onColSortHandler, onTableColsChange]
  );

  const toolsConfiguration: any = useMemo(() => {
    return {
      configurator: {
        hasToSkipLastApplied: false,
        defaultTemplateNameBase: "DEFAULT_SYSTEMATIC_PRODUCT_ALLOCATION",
        configurations: editorColumnsConfiguration.table,
        securityType: "security",
        isSaveLastUsedConfigurationColumnsEnabled: true,
      },
      expandTable: { enabled: true },
      search: { enabled: true },
    };
  }, [editorColumnsConfiguration.table]);

  return (
    <ErrorBoundary fallback={<></>}>
      <TrendratingTableV2
        ref={tableRef}
        tools={toolsConfiguration}
        toolsEvents={toosEvents}
        tableEvents={tableEvents}
        rowTooltipFormatter
      />
    </ErrorBoundary>
  );
}

import { Card, CardContent, Typography } from "@mui/material";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useImmerReducer } from "use-immer";
import { ErrorBoundary } from "../../../../../ErrorBoundary";
import { Preferences as _Preferences } from "../../../../../api/account/Preferences";
import { Report as _Report } from "../../../../../api/report/Report";
import Modal from "../../../../../components/Modal/Modal";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { httpAll } from "../../../../../httpAll";
import { messageError, messageSuccess } from "../../../utils";
import { preferenceReducer } from "./Preferences.reducer";
import Account from "./tabs/Account/Account";
import AlertsAndScreening from "./tabs/AlertsAndScreening/AlertsAndScreening";
import AnalysisInstrument from "./tabs/AnalysisInstrument/AnalysisInstrument";
import MailDigest from "./tabs/MailDigest/MailDigest";
import MarketsAnalysis from "./tabs/MarketsAnalysis/MarketsAnalysis";
import PortfolioAnalysis from "./tabs/PortfolioAnalysis/PortfolioAnalysis";
import Report from "./tabs/Report/Report";
import Subscriptions from "./tabs/Subscriptions/Subscriptions";
import { useBroadcast } from "../../../../../hooks/useBroadcast";
import styles from "./Preferences.module.scss";

type TabsType =
  | "account"
  | "analysis_list"
  | "analysis_instrument"
  | "screening"
  | "analysis_market"
  | "report"
  | "subscriptions"
  | "mailDigest";

type PreferencesProps = {
  input: any;
  landingPageDefault: any;
  landingPageOptions: any;
  initTab?: TabsType;
  initSubscriptionTab?:
    | "portfolio"
    | "basket"
    | "ranking"
    | "systematic"
    | "strategy"
    | "combinedStrategy"
    | "combinedProduct";
  userInfo: any;
  closePreferences: Function;
};

export const PreferenceContext = createContext<any>(undefined);

export default function Preferences({
  input,
  landingPageDefault,
  landingPageOptions,
  userInfo,
  closePreferences,
  initSubscriptionTab,
  initTab,
}: PreferencesProps) {
  const [componentToDisplay, setComponentToDisplay] = useState<any>(null);
  const [choosenTab, setChoosenTab] = useState<TabsType>(initTab ?? "account");
  const environment = useEnvironment();
  const report = useMemo(() => {
    let setup = environment.get("setup");
    let rep = new _Report(setup);
    return rep;
  }, [environment]);

  const preferences = useMemo(() => {
    let setup = environment.get("setup");
    let pref = new _Preferences(setup);
    return pref;
  }, [environment]);

  const [preferenceState, preferenceDispatcher] = useImmerReducer(
    preferenceReducer,
    input
  );

  const { broadcast } = useBroadcast();

  const saving = useCallback(
    (state) => {
      const objToSave = {
        preferences: state,
      };
      preferences.save(objToSave).then((response) => {
        if (response.id != null) {
          const [channel, msg] = messageSuccess(
            "Settings changed successfully!"
          );
          broadcast(channel as string, msg);
        } else {
          const [channel, msg] = messageError("Error on changing Settings !");
          broadcast(channel as string, msg);
        }
        // debugger;
      });
    },
    [broadcast, preferences]
  );

  const isModuleActive = useCallback(
    (moduleId) => {
      const product = environment.get("account")["product"];

      return product?.configuration?.[moduleId]?.["enabled"] === true ?? false;
    },
    [environment]
  );

  const moduleName = useCallback(
    (moduleId) => {
      const product = environment.get("account")["product"];

      return product?.configuration?.[moduleId]?.menu_label ?? null;
    },
    [environment]
  );

  const tabs = useMemo(() => {
    const account = environment.get("account");
    const product = account["product"]["configuration"];

    const LABELS_DICT = {
      ALERTS: "Alerts",
      "PORTFOLIO ANALYSIS": "Portfolio Analysis",
      "PORTFOLIO RISK CONTROL": "Portfolio Risk Control",
      SCREENING: "Alerts and Screening",
      "INVESTMENT IDEAS": "Investment Ideas",
      MARKETS: "Markets",
      "DISPERSION OPPORTUNITIES": "Dispersion Opportunities",
      DISPERSION: "Dispersion",
      "SECURITY ANALYSIS": "Security Analysis",
      INSIGHTS: "Insights",
      HELP: "Help",
      RANK: "Rank",
      "STRATEGY BUILDER": "Strategies",
      STRATEGIES: "Strategies",
      "STRATEGY TRAKER": "Strategy Traker",
    };

    const FUNCTION_TAB_LABEL = {
      report: "Report",
      subscriptions: "Subscriptions",
      mailDigest: "Mail Digest",
    };

    const options = [
      {
        module: "analysis_list",
        type: "page",
      },
      {
        module: "screening",
        type: "page",
      },
      {
        module: "analysis_market",
        type: "page",
      },
      {
        module: "analysis_instrument",
        type: "page",
      },
      { module: "report", type: "function" },
      { module: "subscriptions", type: "function" },
      { module: "mailDigest", type: "function" },
    ];

    let arrTab: any[] = [{ label: "Account", value: "account" }];

    for (const opt of options) {
      if (opt.type === "page") {
        if (isModuleActive(opt.module)) {
          arrTab.push({
            label: LABELS_DICT[moduleName(opt.module)],
            value: opt.module,
          });
        }
      } else {
        switch (opt.module) {
          case "report": {
            arrTab.push({
              label: FUNCTION_TAB_LABEL[opt.module],
              value: opt.module,
            });

            break;
          }

          case "subscriptions": {
            const enabledSubscriptionsMap = product["subscriptions"];
            const isSomethingActive = Object.values(
              enabledSubscriptionsMap
            ).some((value) => value === true);

            if (isSomethingActive) {
              arrTab.push({
                label: FUNCTION_TAB_LABEL[opt.module],
                value: opt.module,
              });
            }

            break;
          }

          case "mailDigest": {
            // const user = account.user;

            // if (user.dailyReportV1 === true || user.dailyReportV2 === true) {
              arrTab.push({
                label: FUNCTION_TAB_LABEL[opt.module],
                value: opt.module,
              });
            // }
          }
        }
      }
    }

    return arrTab;
  }, [environment, isModuleActive, moduleName]);

  const getTabLabelForWidget = useCallback(
    (value: string): string => {
      const obj = tabs.filter((item) => item.value === value)[0];
      return obj.label;
    },
    [tabs]
  );
  useEffect(() => {
    switch (choosenTab) {
      case "account":
        setComponentToDisplay(
          <Account
            preferenceDispatcher={preferenceDispatcher}
            landingPageDefault={landingPageDefault}
            landingPageOptions={landingPageOptions}
            value={preferenceState["general"]?.home ?? "alerts"}
            saving={saving}
          />
        );
        break;
      case "analysis_list":
        setComponentToDisplay(
          <PortfolioAnalysis
            preferenceDispatcher={preferenceDispatcher}
            saving={saving}
            value={preferenceState["analysisList"]}
          />
        );
        break;
      case "screening":
        setComponentToDisplay(
          <AlertsAndScreening
            value={preferenceState["screening"].markets.ids ?? []}
            preferenceDispatcher={preferenceDispatcher}
            saving={saving}
            widgetTtle={getTabLabelForWidget(choosenTab)}
          />
        );
        break;
      case "analysis_market":
        setComponentToDisplay(
          <MarketsAnalysis
            value={preferenceState.analysisMarket?.where?.markets ?? []}
            preferenceDispatcher={preferenceDispatcher}
            saving={saving}
            widgetTtle={getTabLabelForWidget(choosenTab)}
          />
        );
        break;
      case "report":
        httpAll({
          disclaimer: report.disclaimer(),
          logo: report.logo(),
        }).then((response) => {
          var defaultDisclaimer = response["disclaimer"];
          var defaultLogo = response["logo"];
          var userPreference = null;
          if (
            input != null &&
            input["report"] != null &&
            input["report"]["general"] != null
          ) {
            userPreference = preferenceState["report"]["general"];
          }

          var disclaimer =
            userPreference != null && userPreference["disclaimer"] != null
              ? userPreference["disclaimer"]
              : defaultDisclaimer;

          var logo =
            userPreference != null && userPreference["logo"] != null
              ? userPreference["logo"]["base64"]
              : null;
          setComponentToDisplay(
            <Report
              defaultDisclaimer={defaultDisclaimer}
              defaultLogo={defaultLogo}
              value={{
                disclaimer: disclaimer,
                logo: logo,
              }}
              preferenceDispatcher={preferenceDispatcher}
              saving={saving}
            />
          );
        });

        break;
      case "subscriptions":
        setComponentToDisplay(<Subscriptions input={undefined} />);
        break;
      case "mailDigest":
        setComponentToDisplay(
          <MailDigest
            emailDigest={preferenceState?.emailDigest ?? null}
            alerts={preferenceState?.alerts}
            userInfo={userInfo}
            preferenceDispatcher={preferenceDispatcher}
            saving={saving}
          />
        );
        break;
      case "analysis_instrument":
        setComponentToDisplay(
          <AnalysisInstrument
            saving={saving}
            preferenceDispatcher={preferenceDispatcher}
            analysisSecurity={preferenceState?.analysisSecurity}
          />
        );
        break;
      default:
        setComponentToDisplay(<>ERROR!</>);
    }
  }, [
    choosenTab,
    getTabLabelForWidget,
    input,
    landingPageDefault,
    landingPageOptions,
    preferenceDispatcher,
    preferenceState,
    report,
    saving,
    userInfo,
  ]);

  const onChooseTab = useCallback((tab: TabsType) => {
    setChoosenTab(tab);
  }, []);

  const [subscriptionTab, setSubscriptionTab] = useState<any>(
    initSubscriptionTab ?? "portfolio"
  );

  useEffect(() => {
    return () => {
      setSubscriptionTab(initSubscriptionTab ?? "portfolio");
    };
  }, [componentToDisplay, initSubscriptionTab]);

  const onClosePreferences = useCallback(() => {
    if (JSON.stringify(input) !== JSON.stringify(preferenceState)) {
      window.location.reload();
    } else {
      closePreferences();
    }
  }, [closePreferences, input, preferenceState]);
  return (
    <ErrorBoundary
      fallback={
        <Typography variant="subtitle1">
          unknown error! contact our customer support
        </Typography>
      }
    >
      <PreferenceContext.Provider
        value={{ subscriptionTab, setSubscriptionTab }}
      >
        <Modal
          isResizable
          isDraggable
          closeIcon={false}
          closeOnClickAway={false}
          bodyCustomClass={styles.overrideBody}
          customWrapperCss={{ zIndex: 500 }}
          customCss={{ height: 700, maxHeight: 800, width: "60%" }}
          headerConfig={{ headerContent: "Preferences" }}
          buttonsEnalbed={true}
          buttons={[
            {
              name: "Close",
              callback: onClosePreferences,
              variant: "cancel",
            },
          ]}
          onClose={() => onClosePreferences()}
        >
          <Card sx={{ boxShadow: 3, width: "20%" }}>
            <CardContent sx={{ pb: "16px !important" }}>
              <ul style={{ display: "flex", flexDirection: "column", gap: 16 }}>
                {tabs.map((tab) => (
                  <li
                    key={tab.value}
                    style={{ cursor: "pointer" }}
                    onClick={() => onChooseTab(tab.value)}
                  >
                    <Typography
                      lineHeight={1}
                      color={choosenTab === tab.value ? "primary" : ""}
                      variant="subtitle1"
                    >
                      {choosenTab === tab.value ? (
                        <strong>{tab.label}</strong>
                      ) : (
                        tab.label
                      )}
                    </Typography>
                  </li>
                ))}
              </ul>
            </CardContent>
          </Card>

          <Card sx={{ boxShadow: 3, width: "80%", overflow: "auto" }}>
            <CardContent
              sx={{
                pb: "16px !important",
                overflow: "auto",
                p: choosenTab === "subscriptions" ? 0 : 2,
                height: "100%",
              }}
            >
              {componentToDisplay}
            </CardContent>
          </Card>
        </Modal>
      </PreferenceContext.Provider>
    </ErrorBoundary>
  );
}

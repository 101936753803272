import React, { useCallback, useMemo } from "react";
import DailyReportV2 from "./widgets/v2/DailyReportV2";
import DailyReportV1 from "./widgets/V1/DailyReportV1";
import { Box, Typography } from "@mui/material";
import AutoRebalanceSmsNotification from "./widgets/AutoRebalanceSmsNotification/AutoRebalanceSmsNotification";

type MailDigestProps = {
  emailDigest: any;
  alerts: any;
  userInfo: any;
  preferenceDispatcher: any;
  saving: any;
};

export default function MailDigest({
  emailDigest,
  alerts,
  userInfo,
  preferenceDispatcher,
  saving,
}: MailDigestProps) {
  const isDailyReportV1 = useMemo(
    () => userInfo["dailyReportV1"] === true,
    [userInfo]
  );

  const isDailyReportV2 = useMemo(
    () => userInfo["dailyReportV2"] === true,
    [userInfo]
  );

  const setDays = useCallback(
    (daysObj) => {
      preferenceDispatcher({
        type: "SET_EMAILDIGEST_DAYS",
        payload: daysObj,
        saving: saving,
      });
    },
    [preferenceDispatcher, saving]
  );
  const setAutoRebalanceSmsNotification = useCallback(
    (value: true | false) => {
      preferenceDispatcher({
        type: "SET_EMAILDIGEST_SMS_AUTO_REBALANCE",
        payload: value,
        saving: saving,
      });
    },
    [preferenceDispatcher, saving]
  );

  const setListNews = useCallback(
    (listNewsObj, typeOfList) => {
      switch (typeOfList) {
        case "PORTFOLIO":
          preferenceDispatcher({
            type: "SET_EMAILDIGEST_PORTFOLIO_NEWS",
            payload: listNewsObj,
            saving: saving,
          });
          break;
        case "BASKET":
          preferenceDispatcher({
            type: "SET_EMAILDIGEST_BASKET_NEWS",
            payload: listNewsObj,
            saving: saving,
          });
          break;
      }
    },
    [preferenceDispatcher, saving]
  );

  const setUniverse = useCallback(
    (universeObj) => {
      preferenceDispatcher({
        type: "SET_EMAILDIGEST_UNIVERSE",
        payload: universeObj,
        saving: saving,
      });
    },
    [preferenceDispatcher, saving]
  );

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      {isDailyReportV1 === true && isDailyReportV2 === true ? (
        <Typography sx={{ color: "red" }}>
          Both versions of daily email are enabled. Days from both are
          synchronized.
        </Typography>
      ) : null}
      {isDailyReportV2 && (
        <DailyReportV2 setDays={setDays} days={emailDigest?.days} />
      )}
      {isDailyReportV1 && (
        <DailyReportV1
          days={emailDigest?.days}
          alerts={alerts ?? alertsDefaultValue}
          setDays={setDays}
          setListNews={setListNews}
          setUniverse={setUniverse}
        />
      )}
      {
        <AutoRebalanceSmsNotification
          value={emailDigest?.autoRebalanceSystematicPortfolioNotification}
          setValueCallback={setAutoRebalanceSmsNotification}
        />
      }
    </Box>
  );
}

//!!TODO decide to keep this or remove after solve bug
const alertsDefaultValue = {
  baskets: {
    enabled: false,
    nhnl: false,
    notifications: true,
    rerating: true,
  },
  portfolios: {
    enabled: false,
    nhnl: false,
    notifications: true,
    rerating: true,
  },
  universe: {
    enabled: false,
    nhnl: false,
    notifications: true,
    rerating: true,
  },
  pinned: [],
};

import { Analytics } from "../../../api/compute/Analytics/Analytics";
import { deepClone } from "../../../deepClone";
import { Strategy } from "../../../types/Api";
import { StrategyComputedTabType } from "../pages/strategies/builder/editors/Advanced/Result/Result";
import { StrategiesStorage, rawAnalytics } from "./StrategiesStorage";

interface CompareStrategiesStorageInterface {
  loadStrategies: (
    strategyId1: string,
    strategyId2: string
  ) => Promise<{ strategy1: Strategy; strategy2: Strategy } | undefined>;
  getAnalytics: (tab: StrategyComputedTabType) => Promise<any>;
}

type LoadingCallbacks = {
  startNew: Function;
  update: Function;
  complete: Function;
};

export class CompareStrategiesStorage
  extends StrategiesStorage
  implements CompareStrategiesStorageInterface
{
  loadingCallbacks: LoadingCallbacks;

  constructor(environment, loadingBehaviours: LoadingCallbacks) {
    super(environment);

    this.loadingCallbacks = loadingBehaviours;
  }

  public async loadStrategies(strategyId1, strategyId2) {
    const strategyAPI = this.apiStrategies;

    try {
      const strategy1 = await strategyAPI.getById(strategyId1);
      const strategy2 = await strategyAPI.getById(strategyId2);

      strategy1.params.busId = this.progressBarInit(
        this.loadingCallbacks,
        strategy1.name
      );

      strategy2.params.busId = this.progressBarInit(
        this.loadingCallbacks,
        strategy2.name,
        true
      );

      strategy2.params.backtesting.period.type =
        strategy1.params.backtesting.period.type;
      strategy2.params.backtesting.period.value =
        strategy1.params.backtesting.period.value;

      // strategy2["params"]["strategy"]["currency"] =
      //   strategy1["params"]["strategy"]["currency"];

      this.analyticsCollector = await Analytics.initialize(
        this.environment,
        {
          type: "STRATEGY",
          entity: strategy1,
        },
        {
          type: "STRATEGY",
          entity: strategy2,
        }
      );

      return { strategy1, strategy2 };
    } catch (error) {
      console.log(error);
    }
  }

  public async getAnalytics(
    tab: StrategyComputedTabType,
    startDate?,
    endDate?,
    period?
  ) {
    const factoryTag = this.analyticsCollector?.aTag;

    if (factoryTag == null) {
      console.error(
        "Cannot Build tags for analytics. factoryTag function is undefined it probably means that getAnalytics method was called before the processStrategy method"
      );
      return;
    }

    let analytics: string[] = [];

    const parameterSet: any[][] = deepClone(rawAnalytics[tab].H.default);

    // Has always the benchmark and the benchmark is the second strategy
    parameterSet.push(
      ...rawAnalytics[tab].B.default,
      ...rawAnalytics[tab].D.default
    );

    if ("withBenchmark" in rawAnalytics[tab].H) {
      parameterSet.push(...rawAnalytics[tab].H.withBenchmark);
    }

    let analytic: string | null = null;

    const decodeMap = {};
    let dynamicPeriod: any = null;

    for (const set of parameterSet) {
      if (period !== undefined && (set[0] === "keyFacts" || set[0] === "pos")) {
        dynamicPeriod = period;
      } else {
        dynamicPeriod = set?.[3];
      }
      analytic = factoryTag(set[0], set[1], set[2], dynamicPeriod);

      if (analytic != null) {
        decodeMap[analytic] = null;
        analytics.push(analytic);
      }
    }

    let response: any = null;

    // removes duplicated analytics
    analytics = [...new Set(analytics)];

    response = await this.analyticsCollector?.getAnalytics(
      analytics,
      startDate,
      endDate
    );

    if ("status" in response && response.status !== 200) {
      // An error occured
      throw response;
    }

    if (response.data) {
      for (const [key, value] of Object.entries(response.data)) {
        decodeMap[key] = value;
      }

      const result = {};

      for (const analytic of parameterSet) {
        if (
          period !== undefined &&
          (analytic[0] === "keyFacts" || analytic[0] === "pos")
        ) {
          dynamicPeriod = period;
        } else {
          dynamicPeriod = analytic?.[3];
        }
        const key = factoryTag(
          analytic[0],
          analytic[1],
          analytic[2],
          dynamicPeriod
        );

        result[analytic[analytic.length - 1]] = result[
          analytic[analytic.length - 1]
        ] = {
          value: decodeMap[key],
          indicator: key,
        };
      }

      response = result;

      return this.responseTranformer(tab, response);
    }
  }
}

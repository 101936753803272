import { Box, Card, CardContent, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Chart } from "../../../../../../../../../../../components/Chart";
import { useFormatter } from "../../../../../../../../../../../hooks/useFormatter";
import {
  FORMATTER_OPTIONS,
  _formatNumber,
  _formatNumberSignColored,
  _formatPercentageSign,
  _formatPercentageSignColored,
  existenceChecker,
} from "./utils";
import { useEnvironment } from "../../../../../../../../../../../hooks/useEnvironment";

type Props = {
  value: any;
  getChartData: any;
  showChart: boolean;
  benchmarkLabel?: string;
};

export default function Performance({
  value,
  getChartData,
  showChart,
  benchmarkLabel,
}: Props) {
  const [hasBenchmarkInfo, setHasBenchmarkInfo] = useState(false);
  const [hasStrategyInfo, setHasStrategyInfo] = useState(false);
  const [hasDeltaInfo, setHasDeltaInfo] = useState(false);

  const environment = useEnvironment();
  const showAnalyticsTtitle = useMemo(() => {
    const uuid = environment.get("account").user.id;

    if (uuid === 2 || uuid === 4 || uuid === 2700 || uuid === 2816) {
      return true;
    }

    return false;
  }, [environment]);

  useEffect(() => {
    const arr: any = Object.entries(value);
    //! checking if we have strategy, benchmark or delta info
    //! because we'll display stuff based on existence of info
    existenceChecker(arr, setHasStrategyInfo, "strategy");
    existenceChecker(arr, setHasBenchmarkInfo, "benchmark");
    existenceChecker(arr, setHasDeltaInfo, "delta");
  }, [value]);
  const formatter = useFormatter();

  const getAnnualized_Or_Cumulative_Value = useCallback(
    (
      value: { value: number | null; tag: string } | null,
      type: "strategy" | "benchmark" | "delta"
    ) => {
      if (value == null || value.value == null) {
        return null;
      }
      let formated: any = null;
      if (type === "strategy" || type === "benchmark") {
        formated = _formatPercentageSign(
          value.value,
          formatter,
          FORMATTER_OPTIONS
        );
      } else {
        formated = _formatPercentageSignColored(
          value.value,
          formatter,
          FORMATTER_OPTIONS
        );
      }
      return (
        <td style={{ textAlign: "right" }}>
          <strong
            title={showAnalyticsTtitle ? value.tag : undefined}
            dangerouslySetInnerHTML={{ __html: formated }}
          ></strong>
        </td>
      );
    },
    [formatter, showAnalyticsTtitle]
  );
  const getYearlyAverageValue = useCallback(
    (
      value: { value: number | null; tag: string } | null,
      type: "strategy" | "benchmark" | "delta"
    ) => {
      if (value == null || value.value == null) {
        return null;
      }
      let formated: any = null;
      if (type === "strategy" || type === "benchmark") {
        formated = _formatPercentageSign(
          value.value,
          formatter,
          FORMATTER_OPTIONS
        );
      } else {
        formated = _formatPercentageSignColored(
          value.value,
          formatter,
          FORMATTER_OPTIONS
        );
      }
      return (
        <td style={{ textAlign: "right" }}>
          <span
            dangerouslySetInnerHTML={{ __html: formated }}
            title={showAnalyticsTtitle ? value.tag : undefined}
          ></span>
        </td>
      );
    },
    [formatter, showAnalyticsTtitle]
  );
  const getWinningPeriods = useCallback(
    (
      value: { value: number | null; tag: string } | null,
      type: "strategy" | "benchmark" | "delta"
    ) => {
      if (value == null || value.value == null) {
        return null;
      }
      let formated: any = null;
      if (type === "strategy" || type === "benchmark") {
        formated = _formatNumber(value.value, formatter, FORMATTER_OPTIONS);
      } else {
        formated = _formatNumberSignColored(
          value.value,
          formatter,
          FORMATTER_OPTIONS
        );
      }
      return (
        <td style={{ textAlign: "right" }}>
          <span
            dangerouslySetInnerHTML={{ __html: formated }}
            title={showAnalyticsTtitle ? value.tag : undefined}
          ></span>
        </td>
      );
    },
    [formatter, showAnalyticsTtitle]
  );

  const [propertiesToGetChartData, setPropertiesToGetChartData] = useState<any>(
    {
      propertyName: "cumulative",
      propertyLabel: "Cumulative",
      FORMATTER_OPTIONS: FORMATTER_OPTIONS.PERCENTAGE,
    }
  );

  const chartData = useMemo(
    () => getChartData(propertiesToGetChartData),
    [getChartData, propertiesToGetChartData]
  );
  return (
    <Card sx={{ boxShadow: 3, overflow: "visible" }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          pb: "8px !important",
          p: 1,
        }}
      >
        <Box>
          <Typography variant="subtitle1">Performance</Typography>
        </Box>
        <Box display={"flex"} gap={2}>
          <Card sx={{ boxShadow: 3, width: showChart ? "60%" : "100%" }}>
            <CardContent sx={{ pb: "8px !important", p: 1 }}>
              <table width={"100%"}>
                <thead>
                  <tr>
                    <td style={{ textAlign: "right", width: "40%" }}>&nbsp;</td>
                    {hasStrategyInfo && (
                      <td style={{ textAlign: "right", width: "20%" }}>
                        Strategy
                      </td>
                    )}
                    {hasBenchmarkInfo && (
                      <td style={{ textAlign: "right", width: "20%" }}>
                        {benchmarkLabel ?? "Benchmark"}
                      </td>
                    )}
                    {hasDeltaInfo && (
                      <td style={{ textAlign: "right", width: "20%" }}>
                        Difference
                      </td>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {/* cumulative */}
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setPropertiesToGetChartData({
                        propertyName: "cumulative",
                        propertyLabel: "Cumulative",
                        FORMATTER_OPTIONS: FORMATTER_OPTIONS.PERCENTAGE,
                      })
                    }
                  >
                    <td>Cumulative</td>
                    {hasStrategyInfo &&
                      getAnnualized_Or_Cumulative_Value(
                        value.cumulative.strategy,
                        "strategy"
                      )}
                    {hasBenchmarkInfo &&
                      getAnnualized_Or_Cumulative_Value(
                        value.cumulative.benchmark,
                        "benchmark"
                      )}
                    {hasDeltaInfo &&
                      getAnnualized_Or_Cumulative_Value(
                        value.cumulative.delta,
                        "delta"
                      )}
                  </tr>
                  {/* annualized */}
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setPropertiesToGetChartData({
                        propertyName: "annualized",
                        propertyLabel: "Annualized",
                        FORMATTER_OPTIONS: FORMATTER_OPTIONS.PERCENTAGE,
                      })
                    }
                  >
                    <td>Annualized</td>
                    {hasStrategyInfo &&
                      getAnnualized_Or_Cumulative_Value(
                        value.annualized.strategy,
                        "strategy"
                      )}
                    {hasBenchmarkInfo &&
                      getAnnualized_Or_Cumulative_Value(
                        value.annualized.benchmark,
                        "benchmark"
                      )}
                    {hasDeltaInfo &&
                      getAnnualized_Or_Cumulative_Value(
                        value.annualized.delta,
                        "delta"
                      )}
                  </tr>
                  {/* yearlyAverage */}
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setPropertiesToGetChartData({
                        propertyName: "yearlyAverage",
                        propertyLabel: "Yearly average",
                        FORMATTER_OPTIONS: FORMATTER_OPTIONS.PERCENTAGE,
                      })
                    }
                  >
                    <td>Yearly average</td>
                    {hasStrategyInfo &&
                      getYearlyAverageValue(
                        value.yearlyAverage.strategy,
                        "strategy"
                      )}
                    {hasBenchmarkInfo &&
                      getYearlyAverageValue(
                        value.yearlyAverage.benchmark,
                        "benchmark"
                      )}
                    {hasDeltaInfo &&
                      getYearlyAverageValue(value.yearlyAverage.delta, "delta")}
                  </tr>
                  {/* winningPeriods */}
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setPropertiesToGetChartData({
                        propertyName: "winningPeriods",
                        propertyLabel: "Max consecutive positive months",
                        FORMATTER_OPTIONS: FORMATTER_OPTIONS.NUMBER,
                      })
                    }
                  >
                    <td>Max consecutive positive months</td>
                    {hasStrategyInfo &&
                      getWinningPeriods(
                        value.winningPeriods.strategy,
                        "strategy"
                      )}
                    {hasBenchmarkInfo &&
                      getWinningPeriods(
                        value.winningPeriods.benchmark,
                        "benchmark"
                      )}
                    {hasDeltaInfo &&
                      getWinningPeriods(value.winningPeriods.delta, "delta")}
                  </tr>
                </tbody>
              </table>
            </CardContent>
          </Card>
          {showChart && (
            <Card sx={{ boxShadow: 3, width: "40%" }}>
              <CardContent sx={{ height: "100%", p: 1, pb: "8px !important" }}>
                <Chart constructorType="chart" options={chartData} />
              </CardContent>
            </Card>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

//TODO: Change module name when Strategies will be completly migrated

import { Preferences } from "../../../api/account/Preferences";
import { Analytics } from "../../../api/compute/Analytics/Analytics";
import { Instruments } from "../../../api/compute/Instruments";
import { Lists } from "../../../api/compute/Lists";
import { Strategies } from "../../../api/compute/Strategies";
import { SystematicProducts } from "../../../api/compute/SystematicProducts";
import { deepClone } from "../../../deepClone";
import { httpAll } from "../../../httpAll";
import { TDate } from "../../../trendrating/date/TDate";
import { Strategy } from "../../../types/Api";
import { AppEnvironment } from "../../../types/Defaults";
import { StrategyComputedTabType } from "../pages/strategies/builder/editors/Advanced/Result/Result";
import { widgetsConfiguration } from "../widgets/widgetsConfiguration";

type LoadingCallbacks = {
  startNew: Function;
  update: Function;
  complete: Function;
};

type HoldingsAllocatinoObject = {
  d: number | null;
  A: number | null;
  P: number | null;
  CARD: number | null;
  ratings: {
    A: number | null;
    B: number | null;
    C: number | null;
    NA: number | null;
    D: number | null;
  };
  ratingWeights: {
    A: number | null;
    B: number | null;
    C: number | null;
    NA: number | null;
    D: number | null;
  };
};

export interface StrategiesInterface {
  getAnalytics: (tab: StrategyComputedTabType) => Promise<any>;
  getCurves: () => Promise<{
    CURVES: {
      H: undefined | any[];
      B: undefined | any[];
      long: any[][];
      short: any[][];
    };
    POS: { H: undefined | any[]; B: undefined | any[] };
  }>;
  processStrategy?: () => void;
  set?: (strategy: Strategy, loadingBehaviours: LoadingCallbacks) => void;
  getHposRange: (
    params: {
      ge: number;
      lt: number;
      factor: string;
      id: string;
    }[]
  ) => Promise<any>;
  getKeyFactsFrom: (period) => Promise<any>;
}

type AnalyticsSchema = {
  H: {
    default: string[][];
    withBenchmark?: string[][];
    withLongShort?: string[][];
  };
  B: {
    default: string[][];
    withBenchmark?: string[][];
    withLongShort?: string[][];
  };
  D: {
    default: string[][];

    withBenchmark?: string[][];
    withLongShort?: string[][];
  };
};

type AnalyticsForTabs = {
  keyFacts: AnalyticsSchema;
  analytics: AnalyticsSchema;
  holdings: AnalyticsSchema;
  strategyPerformances: AnalyticsSchema;
  productChart: AnalyticsSchema;
};

const CURVE_DICT = {
  H: "strategy",
  B: "benchmark",
  D: "delta",
};

export const rawAnalytics: AnalyticsForTabs = {
  keyFacts: {
    H: {
      default: [
        ["keyFacts", "totalperf", "H", "performance|cumulative|H"],
        ["keyFacts", "perf", "H", "performance|annualized|H"],
        ["keyFacts", "perfAVG", "H", "performance|yearlyAverage|H"],
        ["keyFacts", "winningP", "H", "performance|winningPeriods|H"],
        ["keyFacts", "drawdown", "H", "risk|maxDrawdown|H"],
        ["keyFacts", "avgDrawdown", "H", "risk|avgYearlyDrawdown|H"],
        ["keyFacts", "volatility", "H", "risk|monthlyStdDev|H"],
        ["keyFacts", "losingP", "H", "risk|maxConsecutiveLoosingPeriod|H"],
        ["keyFacts", "sharpe", "H", "keyRatios|sharpeRatio|H"],
        ["keyFacts", "sterling", "H", "keyRatios|sterlingRatio|H"],
        ["keyFacts", "sortino", "H", "keyRatios|sortinoRatio|H"],
        [
          "keyFacts",
          "percWinningP",
          "H",
          "keyRatios|percentagePositivePeriod|H",
        ],
        ["pos", "globalAnnualizedTurnover", "H", "keyRatios|avgTurnover|H"],
      ],

      withBenchmark: [
        ["keyFacts", "beta", "H,B", "keyRatios|beta|H"],
        ["keyFacts", "information", "H,B", "keyRatios|infoRatio|H"],
        ["keyFacts", "treynor", "H,B", "keyRatios|treynorRatio|H"],
        ["keyFacts", "trackingError", "H,B", "keyRatios|trackingError|H"],
      ],
    },

    B: {
      default: [
        ["keyFacts", "totalperf", "B", "performance|cumulative|B"],
        ["keyFacts", "perf", "B", "performance|annualized|B"],
        ["keyFacts", "perfAVG", "B", "Y", "performance|yearlyAverage|B"],
        ["keyFacts", "winningP", "B", "performance|winningPeriods|B"],
        ["keyFacts", "drawdown", "B", "risk|maxDrawdown|B"],
        ["keyFacts", "avgDrawdown", "B", "Y", "risk|avgYearlyDrawdown|B"],
        ["keyFacts", "volatility", "B", "risk|monthlyStdDev|B"],
        ["keyFacts", "losingP", "B", "risk|maxConsecutiveLoosingPeriod|B"],
        ["keyFacts", "sharpe", "B", "keyRatios|sharpeRatio|B"],
        ["keyFacts", "sterling", "B", "keyRatios|sterlingRatio|B"],
        ["keyFacts", "sortino", "B", "keyRatios|sortinoRatio|B"],
        [
          "keyFacts",
          "percWinningP",
          "B",
          "keyRatios|percentagePositivePeriod|B",
        ],
        ["pos", "globalAnnualizedTurnover", "H", "keyRatios|avgTurnover|H"],
      ],
    },

    D: {
      default: [
        ["keyFacts", "totalperf", "D", "performance|cumulative|D"],
        ["keyFacts", "perf", "D", "performance|annualized|D"],
        ["keyFacts", "perfAVG", "D", "Y", "performance|yearlyAverage|D"],
        ["keyFacts", "winningP", "D", "performance|winningPeriods|D"],
        ["keyFacts", "drawdown", "D", "risk|maxDrawdown|D"],
        ["keyFacts", "avgDrawdown", "D", "Y", "risk|avgYearlyDrawdown|D"],
        ["keyFacts", "volatility", "D", "risk|monthlyStdDev|D"],
        ["keyFacts", "losingP", "D", "risk|maxConsecutiveLoosingPeriod|D"],
        ["keyFacts", "sharpe", "D", "keyRatios|sharpeRatio|D"],
        ["keyFacts", "sterling", "D", "keyRatios|sterlingRatio|D"],
        ["keyFacts", "sortino", "D", "keyRatios|sortinoRatio|D"],
        [
          "keyFacts",
          "percWinningP",
          "D",
          "keyRatios|percentagePositivePeriod|D",
        ],
      ],
    },
  },

  analytics: {
    H: {
      default: [
        ["details", "perf", "H", "Y", "performance|yearly|H"],
        ["details", "perf", "H", "Q", "performance|quarterly|H"],
        ["details", "perf", "H", "M", "performance|monthly|H"],
        ["details", "drawdown", "H", "Y", "drawdown|yearly|H"],
        ["details", "drawdown", "H", "Q", "drawdown|quarterly|H"],
        ["details", "drawdown", "H", "M", "drawdown|monthly|H"],
        ["details", "volatility", "H", "Y", "volatility|yearly|H"],
        ["details", "volatility", "H", "Q", "volatility|quarterly|H"],
        ["details", "volatility", "H", "M", "volatility|monthly|H"],

        [
          "details",
          "performanceAnnualized",
          "H",
          "Y",
          "performance|annualized|H",
        ],

        ["details", "volatilityAvg", "H", "volatilityAvg|yearly|H"],
        ["details", "volatilityAvg_M", "H", "volatilityAvg|monthly|H"],
        ["details", "volatilityAvg_Q", "H", "volatilityAvg|quarterly|H"],

        ["keyFacts", "avgDrawdown", "H", "drawdownAvg|yearly|H"],
        ["keyFacts", "avgDrawdown_M", "H", "drawdownAvg|monthly|H"],
        ["keyFacts", "avgDrawdown_Q", "H", "drawdownAvg|quarterly|H"],

        ["keyFacts", "perfAVG", "H", "performanceAvg|yearly|H"],
        ["keyFacts", "perfAVG_M", "H", "performanceAvg|monthly|H"],
        ["keyFacts", "perfAVG_Q", "H", "performanceAvg|quarterly|H"],

        ["perfClosed", "M:1", "H", "", "performance|oneMonth|H"],
        ["perfClosed", "M:3", "H", "", "performance|threeMonths|H"],
        ["perfClosed", "M:6", "H", "", "performance|sixMonths|H"],
        ["perfClosed", "Y:1", "H", "", "performance|oneYear|H"],
        ["perfClosed", "Y:2", "H", "Y", "performance|twoYears|H"],
        ["perfClosed", "Y:3", "H", "Y", "performance|threeYears|H"],
        ["perfClosed", "Y:5", "H", "Y", "performance|fiveYears|H"],
        ["perfClosed", "Y:10", "H", "Y", "performance|tenYears|H"],

        ["pos", "yearlyTurnover", "H", "", "turnover|yearly|H"],
        ["pos", "globalAnnualizedTurnover", "H", "turnover|avgTurnover|H"],
      ],
    },

    B: {
      default: [
        ["details", "perf", "B", "Y", "performance|yearly|B"],
        ["details", "perf", "B", "Q", "performance|quarterly|B"],
        ["details", "perf", "B", "M", "performance|monthly|B"],
        ["details", "drawdown", "B", "Y", "drawdown|yearly|B"],
        ["details", "drawdown", "B", "Q", "drawdown|quarterly|B"],
        ["details", "drawdown", "B", "M", "drawdown|monthly|B"],
        ["details", "volatility", "B", "Y", "volatility|yearly|B"],
        ["details", "volatility", "B", "Q", "volatility|quarterly|B"],
        ["details", "volatility", "B", "M", "volatility|monthly|B"],
        ["details", "volatilityAvg", "B", "volatilityAvg|yearly|B"],
        ["details", "volatilityAvg_M", "B", "volatilityAvg|monthly|B"],
        ["details", "volatilityAvg_Q", "B", "volatilityAvg|quarterly|B"],
        [
          "details",
          "performanceAnnualized",
          "B",
          "Y",
          "performance|annualized|B",
        ],
        ["keyFacts", "avgDrawdown", "B", "drawdownAvg|yearly|B"],
        ["keyFacts", "avgDrawdown_M", "B", "drawdownAvg|monthly|B"],
        ["keyFacts", "avgDrawdown_Q", "B", "drawdownAvg|quarterly|B"],
        ["keyFacts", "perfAVG", "B", "performanceAvg|yearly|B"],
        ["keyFacts", "perfAVG_M", "B", "performanceAvg|monthly|B"],
        ["keyFacts", "perfAVG_Q", "B", "performanceAvg|quarterly|B"],

        ["perfClosed", "M:1", "B", "", "performance|oneMonth|B"],
        ["perfClosed", "M:3", "B", "", "performance|threeMonths|B"],
        ["perfClosed", "M:6", "B", "", "performance|sixMonths|B"],
        ["perfClosed", "Y:1", "B", "", "performance|oneYear|B"],
        ["perfClosed", "Y:2", "B", "Y", "performance|twoYears|B"],
        ["perfClosed", "Y:3", "B", "Y", "performance|threeYears|B"],
        ["perfClosed", "Y:5", "B", "Y", "performance|fiveYears|B"],
        ["perfClosed", "Y:10", "B", "Y", "performance|tenYears|B"],
      ],
    },

    D: {
      default: [
        ["details", "perf", "D", "Y", "performance|yearly|D"],
        ["details", "perf", "D", "Q", "performance|quarterly|D"],
        ["details", "perf", "D", "M", "performance|monthly|D"],
        ["details", "drawdown", "D", "Y", "drawdown|yearly|D"],
        ["details", "drawdown", "D", "Q", "drawdown|quarterly|D"],
        ["details", "drawdown", "D", "M", "drawdown|monthly|D"],
        ["details", "volatility", "D", "Y", "volatility|yearly|D"],
        ["details", "volatility", "D", "Q", "volatility|quarterly|D"],
        ["details", "volatility", "D", "M", "volatility|monthly|D"],
        [
          "details",
          "performanceAnnualized",
          "D",
          "Y",
          "performance|annualized|D",
        ],
        ["details", "volatilityAvg", "D", "volatilityAvg|yearly|D"],
        ["details", "volatilityAvg_M", "D", "volatilityAvg|monthly|D"],
        ["details", "volatilityAvg_Q", "D", "volatilityAvg|quarterly|D"],
        ["keyFacts", "avgDrawdown", "D", "drawdownAvg|yearly|D"],
        ["keyFacts", "avgDrawdown_M", "D", "drawdownAvg|monthly|D"],
        ["keyFacts", "avgDrawdown_Q", "D", "drawdownAvg|quarterly|D"],
        ["keyFacts", "perfAVG", "D", "performanceAvg|yearly|D"],
        ["keyFacts", "perfAVG_M", "D", "performanceAvg|monthly|D"],
        ["keyFacts", "perfAVG_Q", "D", "performanceAvg|quarterly|D"],

        ["perfClosed", "M:1", "D", "", "performance|oneMonth|D"],
        ["perfClosed", "M:3", "D", "", "performance|threeMonths|D"],
        ["perfClosed", "M:6", "D", "", "performance|sixMonths|D"],
        ["perfClosed", "Y:1", "D", "", "performance|oneYear|D"],
        ["perfClosed", "Y:2", "D", "Y", "performance|twoYears|D"],
        ["perfClosed", "Y:3", "D", "Y", "performance|threeYears|D"],
        ["perfClosed", "Y:5", "D", "Y", "performance|fiveYears|D"],
        ["perfClosed", "Y:10", "D", "Y", "performance|tenYears|D"],
      ],
    },
  },

  holdings: {
    H: {
      default: [
        ["holdingsHistory", "constituents_count", "constituents|count"],
        ["holdingsHistory", "constituents_weight", "constituents|weight"],
        ["holdingsHistory", "rating_A", "rating|A_card"],
        ["holdingsHistory", "rating_B", "rating|B_card"],
        ["holdingsHistory", "rating_C", "rating|C_card"],
        ["holdingsHistory", "rating_D", "rating|D_card"],
        ["holdingsHistory", "rating_A_%", "rating|A_perc"],
        ["holdingsHistory", "rating_B_%", "rating|B_perc"],
        ["holdingsHistory", "rating_C_%", "rating|C_perc"],
        ["holdingsHistory", "rating_D_%", "rating|D_perc"],
        ["holdingsHistory", "perf", "performance"],

        [
          "holdingsHistory",
          "constituents_weight_L",
          "constituents|weight|long",
        ],
        [
          "holdingsHistory",
          "constituents_weight_S",
          "constituents|weight|short",
        ],

        ["holdingsHistory", "rating_L_A_%", "rating|A_perc|long"],
        ["holdingsHistory", "rating_L_B_%", "rating|B_perc|long"],
        ["holdingsHistory", "rating_L_C_%", "rating|C_perc|long"],
        ["holdingsHistory", "rating_L_D_%", "rating|D_perc|long"],

        ["holdingsHistory", "rating_S_A_%", "rating|A_perc|short"],
        ["holdingsHistory", "rating_S_B_%", "rating|B_perc|short"],
        ["holdingsHistory", "rating_S_C_%", "rating|C_perc|short"],
        ["holdingsHistory", "rating_S_D_%", "rating|D_perc|short"],

        ["holdingsHistory", "perf_S", "performance|short"],
        ["holdingsHistory", "perf_L", "performance|long"],
      ],
    },

    B: {
      default: [],
    },

    D: {
      default: [],
    },
  },

  strategyPerformances: {
    H: {
      default: [
        ["keyFacts", "D:1", "H", "performance|D:1|H"],
        ["keyFacts", "W:1", "H", "performance|W:1|H"],

        ["keyFacts", "Y:1", "H", "Y", "performance|Y:1|H"],
        ["keyFacts", "Y:3", "H", "Y", "performance|Y:3|H"],
        ["keyFacts", "Y:5", "H", "Y", "performance|Y:5|H"],
        ["keyFacts", "Y:10", "H", "Y", "performance|Y:10|H"],

        ["keyFacts", "perf", "H", "performance|LTD|H"],

        ["keyFacts", "M:1", "H", "performance|M:1|H"],
        ["keyFacts", "Q:1", "H", "performance|Q:1|H"],

        ["current", "YTD", "H", "performance|YTD|H"],
        ["current", "MTD", "H", "performance|MTD|H"],
        ["current", "QTD", "H", "performance|QTD|H"],
      ],
    },
    B: {
      default: [
        ["keyFacts", "D:1", "B", "performance|D:1|B"],
        ["keyFacts", "W:1", "B", "performance|W:1|B"],

        ["keyFacts", "Y:1", "B", "Y", "performance|Y:1|B"],
        ["keyFacts", "Y:3", "B", "Y", "performance|Y:3|B"],
        ["keyFacts", "Y:5", "B", "Y", "performance|Y:5|B"],
        ["keyFacts", "Y:10", "B", "Y", "performance|Y:10|B"],

        ["keyFacts", "perf", "B", "performance|LTD|B"],

        ["keyFacts", "M:1", "B", "performance|M:1|B"],
        ["keyFacts", "Q:1", "B", "performance|Q:1|B"],

        ["current", "YTD", "B", "performance|YTD|B"],
        ["current", "MTD", "B", "performance|MTD|B"],
        ["current", "QTD", "B", "performance|QTD|B"],
      ],
    },
    D: {
      default: [
        ["keyFacts", "D:1", "D", "performance|D:1|D"],
        ["keyFacts", "W:1", "D", "performance|W:1|D"],

        ["keyFacts", "Y:1", "D", "Y", "performance|Y:1|D"],
        ["keyFacts", "Y:3", "D", "Y", "performance|Y:3|D"],
        ["keyFacts", "Y:5", "D", "Y", "performance|Y:5|D"],
        ["keyFacts", "Y:10", "D", "Y", "performance|Y:10|D"],

        ["keyFacts", "perf", "D", "performance|LTD|D"],

        ["keyFacts", "M:1", "D", "performance|M:1|D"],
        ["keyFacts", "Q:1", "D", "performance|Q:1|D"],

        ["current", "YTD", "D", "performance|YTD|D"],
        ["current", "MTD", "D", "performance|MTD|D"],
        ["current", "QTD", "D", "performance|QTD|D"],
      ],
    },
  },

  productChart: {
    H: {
      default: [["details", "perf", "H", "Q", "performance|quarterly|H"]],
    },

    B: {
      default: [[]],
    },

    D: {
      default: [["details", "perf", "D", "Q", "performance|quarterly|D"]],
    },
  },
};

export class StrategiesStorage implements StrategiesInterface {
  private strategy: Strategy | undefined;
  protected analyticsCollector: Analytics | undefined;
  private setup: AppEnvironment;
  protected apiLists: Lists;
  protected apiStrategies: Strategies;
  protected apiInstruments: Instruments;
  private apiSMS: SystematicProducts;
  private loadingBehaviours: LoadingCallbacks | undefined;
  private apiPreferences: Preferences;

  public benchmarkInfo: undefined | { name: string; symbol: string };

  constructor(protected environment: AppEnvironment) {
    this.strategy = undefined;
    this.analyticsCollector = undefined;
    this.setup = environment;
    this.apiLists = new Lists(this.setup);
    this.apiStrategies = new Strategies(this.setup);
    this.apiInstruments = new Instruments(this.setup);
    this.apiPreferences = new Preferences(this.setup);
    this.apiSMS = new SystematicProducts(this.setup);

    this.benchmarkInfo = undefined;
    this.loadingBehaviours = undefined;
  }

  NEUTRAL_STRATEGY_TAG = "TRENDRATING_NEUTRAL_STRATEGY";
  NEUTRAL_STRATEGY_EQUAL_WEIGBTED_TAG =
    "TRENDRATING_NEUTRAL_STRATEGY_EQUAL_WEIGHTED";
  BLENDED_TAG = "COLLECTION:";

  public set(strategy: Strategy, loadingBehaviours: LoadingCallbacks) {
    const clonedStrategy = deepClone(strategy);
    this.loadingBehaviours = loadingBehaviours;

    this.strategy = clonedStrategy;
  }

  public async processStrategy() {
    try {
      if (this.strategy) {
        const strategy = deepClone(this.strategy);
        const benchmarkInfo = strategy?.params?.strategy?.benchmark;
        const mainEntity = strategy;
        let secondEntity: any = undefined;

        //!!check universe before run
        // parsing params to check if universe exists
        let _encoded = this.apiStrategies.encode(strategy.params);
        const isUniverseEmpty =
          !("relations" in _encoded?.universe?.search) &&
          !("filters" in _encoded?.universe?.search);

        if (isUniverseEmpty) {
          throw new Error("EMPTY_UNIVERSE");
        }
        //!! ----------------------

        if (benchmarkInfo) {
          secondEntity = await this.fromBenchmarkToEntity(benchmarkInfo);
        }

        const eventSourceId = this.progressBarInit(
          this.loadingBehaviours!,
          mainEntity.name
        );

        if (eventSourceId) {
          mainEntity.params.busId = eventSourceId;
        }

        this.analyticsCollector = await Analytics.initialize(
          this.environment,
          {
            type: "STRATEGY",
            entity: mainEntity,
          },
          secondEntity
        );
      }

      return;
    } catch (error: any) {
      throw new Error(error.message as any);
    }
  }

  public async getCurves(startDate?, endDate?) {
    const defaultResult: {
      CURVES: {
        H: undefined | any[];
        B: undefined | any[];
        long: any[][];
        short: any[][];
      };
      POS: { H: undefined | any[]; B: undefined | any[] };
    } = {
      CURVES: { H: undefined, B: undefined, long: [], short: [] },
      POS: { H: undefined, B: undefined },
    };

    if (this.analyticsCollector) {
      try {
        const H =
          (await this.analyticsCollector.get(
            "H",
            "prices",
            startDate,
            endDate
          )) ?? undefined;
        const B =
          (await this.analyticsCollector.get(
            "B",
            "prices",
            startDate,
            endDate
          )) ?? undefined;
        const HPOS =
          (await this.analyticsCollector.get("H", "allocations")) ?? undefined;
        const BPOS =
          (await this.analyticsCollector.get("B", "allocations")) ?? undefined;

        defaultResult.CURVES.H = H;
        defaultResult.CURVES.B = B;
        defaultResult.POS.H = HPOS;
        defaultResult.POS.B = BPOS;
      } catch (error) {
        console.log(error);
      }
    }

    return defaultResult;
  }

  public invalidateCache() {
    this.analyticsCollector?.invalidate("H", "H_PRICES");
    this.analyticsCollector?.invalidate("H", "H_POS");
    this.analyticsCollector?.invalidate("B", "H_PRICES");
    this.analyticsCollector?.invalidate("B", "H_POS");
  }

  public async getAnalytics(
    tab: StrategyComputedTabType,
    startDate?: number,
    endDate?: number,
    period?: string
  ) {
    const factoryTag = this.analyticsCollector?.aTag;

    if (factoryTag == null) {
      console.error(
        "Cannot Build tags for analytics. factoryTag function is undefined it probably means that getAnalytics method was called before the processStrategy method"
      );
      return;
    }

    let analytics: string[] = [];
    const hasValidBenchmark =
      (this.strategy?.params?.strategy?.benchmark ?? null) != null;
    const parameterSet: any[][] = deepClone(rawAnalytics[tab].H.default);

    if (hasValidBenchmark === true) {
      parameterSet.push(
        ...rawAnalytics[tab].B.default,
        ...rawAnalytics[tab].D.default
      );

      if ("withBenchmark" in rawAnalytics[tab].H) {
        parameterSet.push(...rawAnalytics[tab].H.withBenchmark);
      }
    }

    let analytic: string | null = null;

    const decodeMap = {};
    let dynamicPeriod: any = null;

    for (const set of parameterSet) {
      if (period !== undefined && (set[0] === "keyFacts" || set[0] === "pos")) {
        dynamicPeriod = period;
      } else {
        dynamicPeriod = set?.[3];
      }

      analytic = factoryTag(set[0], set[1], set[2], dynamicPeriod);

      if (analytic != null) {
        decodeMap[analytic] = null;
        analytics.push(analytic);
      }
    }

    let response: any = null;

    // removes duplicated analytics
    analytics = [...new Set(analytics)];

    response = await this.analyticsCollector?.getAnalytics(
      analytics,
      startDate,
      endDate
    );

    if ("status" in response && response.status !== 200) {
      // An error occured
      throw response;
    }

    if (response.data) {
      for (const [key, value] of Object.entries(response.data)) {
        decodeMap[key] = value;
      }

      const result = {};

      for (const analytic of parameterSet) {
        if (
          period !== undefined &&
          (analytic[0] === "keyFacts" || analytic[0] === "pos")
        ) {
          dynamicPeriod = period;
        } else {
          dynamicPeriod = analytic?.[3];
        }
        const key = factoryTag(
          analytic[0],
          analytic[1],
          analytic[2],
          dynamicPeriod
        );

        result[analytic[analytic.length - 1]] = {
          value: decodeMap[key],
          indicator: key,
        };
      }

      response = result;

      return this.responseTranformer(tab, response);
    }
  }

  public async getHposRange(
    rangeParams: {
      ge: number;
      lt: number;
      factor: string;
    }[]
  ) {
    const analytics: string[] = [];

    const factoryTag = this.analyticsCollector?.aTag;

    if (factoryTag) {
      const analyticsMnemonic = {};
      let analytic = "";

      for (const option of rangeParams) {
        analytic = factoryTag(
          "holdingsHistory",
          "range",
          "H",
          undefined,
          undefined,
          option
        );
        analyticsMnemonic[option["id"]] = analytic;
        analytics.push(analytic);
      }

      const response = await this.analyticsCollector?.getAnalytics(analytics);

      if (response.status === 200) {
        const result = {};

        for (const key in analyticsMnemonic) {
          result[key] = response["data"][analyticsMnemonic[key]];
        }

        return result;
      }

      return undefined;
    }
  }

  /**
   * This is a special get analytics function specific for the report widget AS_OF_TODAY_PERFORMANCE
   *
   * this function fetch the needed analytics based on what the user has selected in the configuration panel of the
   * report section. In particular it ensure the distinction between SOLAR and ROLLING analytics.
   *
   * This function will be used only by the InputManager class under the AS_OF_TODAY_PERFORMACE section
   */
  public async getAsOfTodayPerfAnalytics(
    section,
    hasBenchmark,
    startDate,
    endDate
  ) {
    const analiticEncoder = this.analyticsCollector?.aTag;

    const sectionContent = section.content;

    // Defaults
    const performances = {
      1: {
        annualized: sectionContent?.performance1Year?.annualized ?? true,
        strict: sectionContent?.performance1Year?.strict ?? true,
      },
      3: {
        annualized: sectionContent?.performance3Years?.annualized ?? true,
        strict: sectionContent?.performance3Years?.strict ?? true,
      },
      5: {
        annualized: sectionContent?.performance5Years?.annualized ?? true,
        strict: sectionContent?.performance5Years?.strict ?? false,
      },
      10: {
        annualized: sectionContent?.performance10Year?.annualized ?? true,
        strict: sectionContent?.performance10Years?.strict ?? false,
      },
      LTD: {
        annualized: sectionContent?.performanceLTD?.annualized ?? true,
      },
    };

    if (analiticEncoder) {
      const tagsMap = {
        H: {
          DAILY: sectionContent.performance1Day.isEnabled
            ? analiticEncoder("keyFacts", "D:1", "H")
            : undefined,
          WEEKLY: sectionContent.performance1Week.isEnabled
            ? analiticEncoder("keyFacts", "W:1", "H")
            : undefined,
          MONTHLY: sectionContent.performance1Month.isEnabled
            ? analiticEncoder("keyFacts", "M:1", "H", undefined, false)
            : undefined,
          QUARTERLY: sectionContent.performance3Months.isEnabled
            ? analiticEncoder("keyFacts", "M:3", "H", undefined, false)
            : undefined,
          YEARLY: sectionContent.performance1Year.isEnabled
            ? analiticEncoder(
                "keyFacts",
                "Y:1",
                "H",
                performances["1"].annualized ? "Y" : undefined,
                !performances["1"].strict
              )
            : undefined,
          YEARLY_3_N: sectionContent.performance3Years.isEnabled
            ? analiticEncoder(
                "keyFacts",
                "Y:3",
                "H",
                performances["3"].annualized ? "Y" : undefined,
                !performances["3"].strict
              )
            : undefined,
          YEARLY_5_N: sectionContent.performance5Years.isEnabled
            ? analiticEncoder(
                "keyFacts",
                "Y:5",
                "H",
                performances["5"].annualized ? "Y" : undefined,
                !performances["5"].strict
              )
            : undefined,
          YEARLY_10_N: sectionContent.performance10Years.isEnabled
            ? analiticEncoder(
                "keyFacts",
                "Y:10",
                "H",
                performances["10"].annualized ? "Y" : undefined,
                !performances["10"].strict
              )
            : undefined,
          MTD: sectionContent.performanceMTD.isEnabled
            ? analiticEncoder("current", "MTD", "H")
            : undefined,
          QTD: sectionContent.performanceQTD.isEnabled
            ? analiticEncoder("current", "QTD", "H")
            : undefined,
          YTD: sectionContent.performanceYTD.isEnabled
            ? analiticEncoder("current", "YTD", "H")
            : undefined,
          LTD: sectionContent.performanceLTD.isEnabled
            ? performances["LTD"].annualized
              ? analiticEncoder("keyFacts", "perf", "H")
              : analiticEncoder("keyFacts", "totalperf", "H")
            : undefined,
        },
        B: {
          DAILY: sectionContent.performance1Day.isEnabled
            ? analiticEncoder("keyFacts", "D:1", "B")
            : undefined,
          WEEKLY: sectionContent.performance1Week.isEnabled
            ? analiticEncoder("keyFacts", "W:1", "B")
            : undefined,
          MONTHLY: sectionContent.performance1Month.isEnabled
            ? analiticEncoder("keyFacts", "M:1", "B", undefined, false)
            : undefined,
          QUARTERLY: sectionContent.performance3Months.isEnabled
            ? analiticEncoder("keyFacts", "M:3", "B", undefined, false)
            : undefined,
          YEARLY: sectionContent.performance1Year.isEnabled
            ? analiticEncoder(
                "keyFacts",
                "Y:1",
                "B",
                performances["1"].annualized ? "Y" : undefined,
                !performances["1"].strict
              )
            : undefined,
          YEARLY_3_N: sectionContent.performance3Years.isEnabled
            ? analiticEncoder(
                "keyFacts",
                "Y:3",
                "B",
                performances["3"].annualized ? "Y" : undefined,
                !performances["3"].strict
              )
            : undefined,
          YEARLY_5_N: sectionContent.performance5Years.isEnabled
            ? analiticEncoder(
                "keyFacts",
                "Y:5",
                "B",
                performances["5"].annualized ? "Y" : undefined,
                !performances["5"].strict
              )
            : undefined,
          YEARLY_10_N: sectionContent.performance10Years.isEnabled
            ? analiticEncoder(
                "keyFacts",
                "Y:10",
                "B",
                performances["10"].annualized ? "Y" : undefined,
                !performances["10"].strict
              )
            : undefined,
          MTD: sectionContent.performanceMTD.isEnabled
            ? analiticEncoder("current", "MTD", "B")
            : undefined,
          QTD: sectionContent.performanceQTD.isEnabled
            ? analiticEncoder("current", "QTD", "B")
            : undefined,
          YTD: sectionContent.performanceYTD.isEnabled
            ? analiticEncoder("current", "YTD", "B")
            : undefined,
          LTD: sectionContent.performanceLTD.isEnabled
            ? performances["LTD"].annualized
              ? analiticEncoder("keyFacts", "perf", "B")
              : analiticEncoder("keyFacts", "totalperf", "B")
            : undefined,
        },
        D: {
          DAILY: sectionContent.performance1Day.isEnabled
            ? analiticEncoder("keyFacts", "D:1", "D")
            : undefined,
          WEEKLY: sectionContent.performance1Week.isEnabled
            ? analiticEncoder("keyFacts", "W:1", "D")
            : undefined,
          MONTHLY: sectionContent.performance1Month.isEnabled
            ? analiticEncoder("keyFacts", "M:1", "D", undefined, false)
            : undefined,
          QUARTERLY: sectionContent.performance3Months.isEnabled
            ? analiticEncoder("keyFacts", "M:3", "D", undefined, false)
            : undefined,
          YEARLY: sectionContent.performance1Year.isEnabled
            ? analiticEncoder(
                "keyFacts",
                "Y:1",
                "D",
                performances["1"].annualized ? "Y" : undefined,
                !performances["1"].strict
              )
            : undefined,
          YEARLY_3_N: sectionContent.performance3Years.isEnabled
            ? analiticEncoder(
                "keyFacts",
                "Y:3",
                "D",
                performances["3"].annualized ? "Y" : undefined,
                !performances["3"].strict
              )
            : undefined,
          YEARLY_5_N: sectionContent.performance5Years.isEnabled
            ? analiticEncoder(
                "keyFacts",
                "Y:5",
                "D",
                performances["5"].annualized ? "Y" : undefined,
                !performances["5"].strict
              )
            : undefined,
          YEARLY_10_N: sectionContent.performance10Years.isEnabled
            ? analiticEncoder(
                "keyFacts",
                "Y:10",
                "D",
                performances["10"].annualized ? "Y" : undefined,
                !performances["10"].strict
              )
            : undefined,
          MTD: sectionContent.performanceMTD.isEnabled
            ? analiticEncoder("current", "MTD", "D")
            : undefined,
          QTD: sectionContent.performanceQTD.isEnabled
            ? analiticEncoder("current", "QTD", "D")
            : undefined,
          YTD: sectionContent.performanceYTD.isEnabled
            ? analiticEncoder("current", "YTD", "D")
            : undefined,
          LTD: sectionContent.performanceLTD.isEnabled
            ? performances["LTD"].annualized
              ? analiticEncoder("keyFacts", "perf", "D")
              : analiticEncoder("keyFacts", "totalperf", "D")
            : undefined,
        },
      };

      const analyticsList: any = [];

      analyticsList.push(...Object.values(tagsMap["H"]));

      if (hasBenchmark) {
        analyticsList.push(...Object.values(tagsMap["B"]));
        analyticsList.push(...Object.values(tagsMap["D"]));
      }

      const analyticsToFetch = analyticsList.filter((item) => item != null);
      const response = await this.analyticsCollector?.getAnalytics(
        analyticsToFetch,
        startDate,
        endDate
      );

      const analytics: any = {
        H: {
          DAILY: null,
          WEEKLY: null,
          MONTHLY: null,
          QUARTERLY: null,
          YEARLY: null,
          YEARLY_3_N: null,
          YEARLY_5_N: null,
          YEARLY_10_N: null,
          MTD: null,
          QTD: null,
          YTD: null,
          LTD: null,
        },
      };

      if (response?.data) {
        for (const [key, value] of Object.entries(tagsMap["H"])) {
          if (value) {
            analytics["H"][key] = Object.values(response?.data?.[value])?.[0];
          }
        }

        if (hasBenchmark) {
          analytics["B"] = {};
          analytics["D"] = {};

          for (const [key, value] of Object.entries(tagsMap["B"])) {
            if (value) {
              analytics["B"][key] = Object.values(response?.data?.[value])?.[0];
            }
          }

          for (const [key, value] of Object.entries(tagsMap["D"])) {
            if (value) {
              analytics["D"][key] = Object.values(response?.data?.[value])?.[0];
            }
          }
        }
      }

      return {
        strategy: analytics?.H ? { ...analytics?.H } : undefined,
        benchmark: analytics?.B ? { ...analytics.B } : undefined,
        delta: analytics?.D ? { ...analytics.D } : undefined,
      };
    }
  }

  /**
   * Retrieves instruments if strategy has a benchmark and/or hedging
   * instrument defined
   *
   * @param {object} strategy
   *
   * @returns {dojo/promise/Promise} a promise fulfilled with the
   *       handled data of the response
   */
  public async getBenchmarkAndHedgingInstrument(strategy) {
    var requests: any = {
      instrumentBenchmark: null,
      instrumentHedging: null,
    };

    var properties =
      widgetsConfiguration["widgets/builder/benchmark"]["properties"];

    var strategyParams = strategy.params;
    var benchmark = strategyParams.strategy.benchmark;
    if (
      benchmark != null &&
      benchmark !== this.NEUTRAL_STRATEGY_TAG &&
      benchmark !== this.NEUTRAL_STRATEGY_EQUAL_WEIGBTED_TAG &&
      !benchmark.startsWith(this.BLENDED_TAG)
    ) {
      requests.instrumentBenchmark = this.apiInstruments.fetch({
        properties: properties,
        symbols: [benchmark],
        type: "security",
      });
    }

    if (strategyParams.hedging != null) {
      requests.instrumentHedging = this.apiInstruments.fetch({
        properties: properties,
        symbols: [strategyParams.hedging.instrument],
        type: "security",
      });
    }

    return httpAll(requests).then(
      (response) => {
        var unboxed = {
          instrumentBenchmark: null,
          instrumentHedging: null,
        };

        if (
          response.instrumentBenchmark != null &&
          response.instrumentBenchmark.data.length === 1
        ) {
          unboxed.instrumentBenchmark = response.instrumentBenchmark.data[0];
        }

        if (
          response.instrumentHedging != null &&
          response.instrumentHedging.data.length === 1
        ) {
          unboxed.instrumentHedging = response.instrumentHedging.data[0];
        }

        return unboxed;
      },
      (error) => {
        console.log("error!", error);
        return null;
      }
    );
  }

  /**
   * Get App configuration
   *
   * @returns {object} the App configuration
   */
  public getConfiguration() {
    return this.environment["configuration"].configuration;
  }

  /**
   * Add tracking to the strategy
   *
   * @param {object} strategy - strategy
   * @param {string} isoDate - a date in ISO 8601 format
   *
   * @returns {dojo/promise/Promise} a promise fulfilled with the
   *       handled data of the response
   */
  public async addTracking(strategy, isoDate) {
    //
    // __V19__
    //
    var strategyToUpdate = deepClone(strategy);
    if (strategy.LEGACY != null) {
      strategyToUpdate.LEGACY.params.indexContext.trackingDay = isoDate;
    } else {
      strategyToUpdate.params.tracking = {
        trackingDate: isoDate,
      };
    }

    const strategyUpdated = await this.apiStrategies.update(strategyToUpdate);
    var trackedStrategies = this.apiPreferences.getTrackedStrategies();

    var trackedStrategiesUpdated = trackedStrategies
      ? deepClone(trackedStrategies)
      : [];
    trackedStrategiesUpdated.push(Number(strategy.id));

    await this.apiPreferences.setPreferenceKey(
      "indexes",
      trackedStrategiesUpdated
    );

    return {
      isTracked: true,
      strategy: strategyUpdated,
    };
  }

  /**
   * Remove tracking from the strategy
   *
   * @param {object} strategy - strategy
   *
   * @returns {dojo/promise/Promise} a promise fulfilled with the
   *       handled data of the response
   */
  public async removeTracking(strategy) {
    //
    // __V19__
    //
    var strategyToUpdate = deepClone(strategy);
    if (strategy.LEGACY != null) {
      delete strategyToUpdate.LEGACY.params.indexContext.trackingDay;
    } else {
      strategyToUpdate.params.tracking = null;
    }

    const strategyUpdated = await this.apiStrategies.update(strategyToUpdate);
    var trackedStrategies = this.getTrackedStrategies();

    var trackedStrategiesUpdated = trackedStrategies?.filter(function (item) {
      if (item !== strategyUpdated.id) {
        return true;
      }

      return false;
    });

    await this.apiPreferences.setPreferenceKey(
      "indexes",
      trackedStrategiesUpdated
    );

    return {
      isTracked: false,
      strategy: strategyUpdated,
    };
  }

  public getTrackedStrategies() {
    return this.apiPreferences.getTrackedStrategies();
  }

  private getSrategyPriceIndexParams(backtestParams) {
    let startDate: any = undefined;

    if (
      "includeFromDay" in backtestParams.backtesting &&
      backtestParams.backtesting.includeFromDay != null
    ) {
      startDate = backtestParams.backtesting.includeFromDay;
    } else {
      const today = TDate.today();
      const yearsInDays = backtestParams.backtesting.yearsBack! * 260;
      const offset = 20;

      const daysBack = today - yearsInDays - offset;
      const date = TDate.dateToIso8601(TDate.daysToDate(daysBack));

      startDate = date;
    }

    const strategyCurrency =
      this.strategy?.params?.strategy?.currency ?? "local";
    const rebalance = this.strategy?.params?.strategy?.rebalance ?? "20_DAYS";
    const granularity_dict = {
      "05_DAYS": "WEEKLY",
      "20_DAYS": "MONTHLY",
      "60_DAYS": "QUARTERLY",
    };
    const granularity = granularity_dict[rebalance];

    return { startDate, strategyCurrency, granularity };
  }

  protected async fromBenchmarkToEntity(benchmarkTag: string) {
    const backtestParams = this.apiStrategies.prepareParamsForRun(
      this.strategy!.params,
      undefined
    );

    if (
      benchmarkTag === this.NEUTRAL_STRATEGY_TAG ||
      benchmarkTag === this.NEUTRAL_STRATEGY_EQUAL_WEIGBTED_TAG
    ) {
      const benchmarkName =
        benchmarkTag === this.NEUTRAL_STRATEGY_TAG
          ? "Neutral Strategy"
          : "Neutral Strategy Equal Weighted";
      this.benchmarkInfo = { name: benchmarkName, symbol: benchmarkTag };

      const neutralStrategyParams =
        this.apiStrategies.prepareParamsForNeutralStrategy(
          this.strategy!.params
        );

      const decoded = this.apiStrategies.decode(neutralStrategyParams);
      const neutralStrategy = {
        name: this.NEUTRAL_STRATEGY_TAG,
        params: decoded,
      };

      const eventSourceId = this.progressBarInit(
        this.loadingBehaviours!,
        benchmarkName,
        true
      );

      if (eventSourceId) {
        neutralStrategy.params.busId = eventSourceId;
      }

      return {
        type: "STRATEGY",
        entity: neutralStrategy,
      };
    }

    if (benchmarkTag.startsWith(this.BLENDED_TAG)) {
      const explodedTag = benchmarkTag.split(":");
      const portfolioId = explodedTag[1];

      const list = await this.apiLists.get(parseInt(portfolioId));

      this.benchmarkInfo = { name: list?.name ?? "", symbol: benchmarkTag };

      const { startDate, granularity, strategyCurrency } =
        this.getSrategyPriceIndexParams(backtestParams);

      const entity = {
        portfolio: list,
        params: {
          inceptionDay: backtestParams.pricing.inceptionDay,
          inceptionValue: backtestParams.pricing.inceptionValue,
          method: backtestParams.pricing.method,
          spanGranularity: granularity,
          currency: strategyCurrency,
        },
        includeFromDay: startDate,
      };

      return { type: "LIST", entity };
    }

    // The benchmark is an Instrument

    const strategyCurrency =
      this.strategy?.params?.strategy?.currency ?? "local";

    try {
      const response = await this.apiInstruments.fetch({
        symbols: [benchmarkTag],
        type: "security",
        properties: [{ date: null, property: "name" }],
      });

      const instrumentName = response?.data?.[0]?.name ?? "";

      this.benchmarkInfo = { name: instrumentName, symbol: benchmarkTag };
    } catch (error) {
      console.log(error);
    }

    const { startDate, granularity } =
      this.getSrategyPriceIndexParams(backtestParams);

    return {
      type: "INSTRUMENT",
      entity: {
        symbol: benchmarkTag,
        params: {
          inceptionDay: backtestParams.pricing.inceptionDay,
          inceptionValue: backtestParams.pricing.inceptionValue,
          method: backtestParams.pricing.method,
          spanGranularity: granularity,
          currency: strategyCurrency,
        },
      },
      includeFromDay: startDate,
    };
  }

  protected responseTranformer(tab: StrategyComputedTabType, input) {
    switch (tab) {
      case "keyFacts":
        return { [tab]: this.transformToKeyFacts(input) };

      case "analytics": {
        return this.transformToAnalytics(input);
      }

      case "holdings": {
        return this.transformToHoldings(this.flatInput(input));
      }

      case "strategyPerformances": {
        return this.transformToPerformances(this.flatInput(input));
      }

      default:
        return this.flatInput(input);
    }
  }

  private flatInput(input) {
    const flatMap = {};

    for (const key in input) {
      flatMap[key] = input[key].value;
    }

    return flatMap;
  }

  private transformToPerformances(input) {
    const getPerfBySerie = (serie: "H" | "B" | "D") => {
      return {
        DAILY: Object.values(input?.[`performance|D:1|${serie}`] ?? {})?.[0],
        LTD: Object.values(input?.[`performance|LTD|${serie}`] ?? {})?.[0],
        MONTHLY: Object.values(input?.[`performance|M:1|${serie}`] ?? {})?.[0],
        QUARTERLY: Object.values(
          input?.[`performance|Q:1|${serie}`] ?? {}
        )?.[0],
        WEEKLY: Object.values(input?.[`performance|W:1|${serie}`] ?? {})?.[0],
        YEARLY: Object.values(input?.[`performance|Y:1|${serie}`] ?? {})?.[0],
        YEARLY_10_N: Object.values(
          input?.[`performance|Y:10|${serie}`] ?? {}
        )?.[0],
        YEARLY_3_N: Object.values(
          input?.[`performance|Y:3|${serie}`] ?? {}
        )?.[0],
        YEARLY_5_N: Object.values(
          input?.[`performance|Y:5|${serie}`] ?? {}
        )?.[0],
        MTD: Object.values(input?.[`performance|MTD|${serie}`] ?? {})?.[0],
        QTD: Object.values(input?.[`performance|QTD|${serie}`] ?? {})?.[0],
        YTD: Object.values(input?.[`performance|YTD|${serie}`] ?? {})?.[0],
      };
    };

    const performances = {
      benchmark: getPerfBySerie("B"),
      delta: getPerfBySerie("D"),
      strategy: getPerfBySerie("H"),
    };

    return performances;
  }

  private transformToHoldings(input) {
    const TAGS = {
      constituentsCard: "constituents|count",
      constituentsWeight: "constituents|weight",
      rating_A_number: "rating|A_card",
      rating_B_number: "rating|B_card",
      rating_C_number: "rating|C_card",
      rating_D_number: "rating|D_card",
      rating_A_perc: "rating|A_perc",
      rating_B_perc: "rating|B_perc",
      rating_C_perc: "rating|C_perc",
      rating_D_perc: "rating|D_perc",
      perf: "performance",
    };

    const TAGS_SHORT = {
      constituentsWeight: "constituents|weight|short",
      rating_A_perc: "rating|A_perc|short",
      rating_B_perc: "rating|B_perc|short",
      rating_C_perc: "rating|C_perc|short",
      rating_D_perc: "rating|D_perc|short",
      perf: "performance|short",
    };

    const TAGS_LONG = {
      constituentsWeight: "constituents|weight|long",
      rating_A_perc: "rating|A_perc|long",
      rating_B_perc: "rating|B_perc|long",
      rating_C_perc: "rating|C_perc|long",
      rating_D_perc: "rating|D_perc|long",
      perf: "performance|long",
    };

    if (!input) {
      return [];
    }

    const holdingsData = this.holdingTransformerHelper(input, TAGS);

    const result: any = {
      main: holdingsData,
      long: [],
      short: [],
    };

    const shortPortfolioData = this.holdingTransformerHelper(input, TAGS_SHORT);
    const longPortfolioData = this.holdingTransformerHelper(input, TAGS_LONG);

    result["long"] = longPortfolioData;
    result["short"] = shortPortfolioData;

    let hasShortPositions = false;

    for (const pos of shortPortfolioData) {
      if (pos.A && pos.A < 0) {
        hasShortPositions = true;

        break;
      }
    }

    if (hasShortPositions === true) {
      return result;
    } else {
      result["long"] = [];
      result["short"] = [];

      return result;
    }
  }

  private holdingTransformerHelper(data, TAGS) {
    const input = deepClone(data);
    const allocation: HoldingsAllocatinoObject = {
      d: null,
      A: null,
      P: null,
      CARD: null,
      ratings: {
        A: null,
        B: null,
        C: null,
        NA: null,
        D: null,
      },

      ratingWeights: {
        A: null,
        B: null,
        C: null,
        NA: null,
        D: null,
      },
    };

    const prototype: HoldingsAllocatinoObject[] = [];

    for (const [date, performance] of Object.entries<any>(
      input[TAGS["perf"]]
    )) {
      allocation["d"] = parseInt(date);
      allocation["P"] = performance;
      allocation["CARD"] = input?.[TAGS?.["constituentsCard"]]?.[date] ?? null;
      allocation["A"] = input?.[TAGS?.["constituentsWeight"]]?.[date] ?? null;

      // Ratings cardinality
      allocation["ratings"]["A"] =
        input?.[TAGS?.["rating_A_number"]]?.[date] ?? null;
      allocation["ratings"]["B"] =
        input?.[TAGS?.["rating_B_number"]]?.[date] ?? null;
      allocation["ratings"]["C"] =
        input?.[TAGS?.["rating_C_number"]]?.[date] ?? null;
      allocation["ratings"]["D"] =
        input?.[TAGS?.["rating_D_number"]]?.[date] ?? null;

      // Ratings percentage
      allocation["ratingWeights"]["A"] =
        input?.[TAGS?.["rating_A_perc"]]?.[date] ?? null;
      allocation["ratingWeights"]["B"] =
        input?.[TAGS?.["rating_B_perc"]]?.[date] ?? null;
      allocation["ratingWeights"]["C"] =
        input?.[TAGS?.["rating_C_perc"]]?.[date] ?? null;
      allocation["ratingWeights"]["D"] =
        input?.[TAGS?.["rating_D_perc"]]?.[date] ?? null;

      // Clone to not push a reference to original object
      prototype.push(deepClone(allocation));
    }

    return prototype;
  }

  private resolvePathToObject(path: string) {
    const params: any = { page: null, section: null, curve: null };
    const urlNodes = path.split("|");

    if (urlNodes.length > 0) {
      params["page"] = urlNodes[0];
      params["section"] = urlNodes[1];
      params["curve"] = CURVE_DICT[urlNodes[2]];
    }

    return params;
  }

  private transformToAnalytics(input) {
    let dataList: any = [];
    const performances = {
      H: {},
      B: {},
      D: {},
    };

    for (const key in input) {
      let [analytic, period, curve] = key.split("|");

      const performanceTableKeys = {
        "performance|oneMonth|H": true,
        "performance|threeMonths|H": true,
        "performance|sixMonths|H": true,
        "performance|oneYear|H": true,
        "performance|twoYears|H": true,
        "performance|threeYears|H": true,
        "performance|fiveYears|H": true,
        "performance|tenYears|H": true,

        "performance|oneMonth|B": true,
        "performance|threeMonths|B": true,
        "performance|sixMonths|B": true,
        "performance|oneYear|B": true,
        "performance|twoYears|B": true,
        "performance|threeYears|B": true,
        "performance|fiveYears|B": true,
        "performance|tenYears|B": true,

        "performance|oneMonth|D": true,
        "performance|threeMonths|D": true,
        "performance|sixMonths|D": true,
        "performance|oneYear|D": true,
        "performance|twoYears|D": true,
        "performance|threeYears|D": true,
        "performance|fiveYears|D": true,
        "performance|tenYears|D": true,
      };

      if (performanceTableKeys[key]) {
        performances[curve][period] = {
          value: Object.values(input?.[key].value)?.[0] ?? null,
          tag: input[key].indicator,
        };

        continue;
      }

      input[key] = Object.entries<any>(input?.[key].value ?? {}).map(
        ([date, value]) => ({
          timeframe: date,
          value,
          tag: input?.[key].indicator,
          analytic,
          period,
          curve,
        })
      );

      dataList = dataList.concat(input[key]);
    }

    const prototype: any = {
      yearly: [],
      monthly: [],
      quarterly: [],
      performances,
    };

    let annualizedYearly = {
      timeFrame: "Annualized",
      strategyYearlyPerformance: {
        value: input["performance|annualized|H"]?.[0]?.["value"] ?? null,
        tag: input["performance|annualized|H"]?.[0]?.["tag"],
      },
      strategyMaxDrawdown: null,
      strategyVolatility: null,
      benchmarkYearlyPerformance: {
        value: input["performance|annualized|B"]?.[0]?.["value"] ?? null,
        tag: input["performance|annualized|B"]?.[0]?.["tag"],
      },
      benchmarkMaxDrawdown: null,
      benchmarkVolatility: null,
      deltaYearlyPerformance: {
        value: input["performance|annualized|D"]?.[0]?.["value"] ?? null,
        tag: input["performance|annualized|D"]?.[0]?.["tag"],
      },
      deltaMaxDrawdown: null,
      deltaVolatility: null,
      turnover: null,
    };

    let avgYearlyRow = {
      timeFrame: "average",
      strategyYearlyPerformance: {
        value: input["performanceAvg|yearly|H"]?.[0]?.["value"] ?? null,
        tag: input["performanceAvg|yearly|H"]?.[0]?.["tag"],
      },
      strategyMaxDrawdown: {
        value: input["drawdownAvg|yearly|H"]?.[0]?.["value"] ?? null,
        tag: input["drawdownAvg|yearly|H"]?.[0]?.["tag"],
      },
      strategyVolatility: {
        value: input["volatilityAvg|yearly|H"]?.[0]?.["value"] ?? null,
        tag: input["volatilityAvg|yearly|H"]?.[0]?.["tag"],
      },
      benchmarkYearlyPerformance: {
        value: input["performanceAvg|yearly|B"]?.[0]?.["value"] ?? null,
        tag: input["performanceAvg|yearly|B"]?.[0]?.["tag"],
      },
      benchmarkMaxDrawdown: {
        value: input["drawdownAvg|yearly|B"]?.[0]?.["value"] ?? null,
        tag: input["drawdownAvg|yearly|B"]?.[0]?.["tag"],
      },
      benchmarkVolatility: {
        value: input["volatilityAvg|yearly|B"]?.[0]?.["value"] ?? null,
        tag: input["volatilityAvg|yearly|B"]?.[0]?.["tag"],
      },
      deltaYearlyPerformance: {
        value: input["performanceAvg|yearly|D"]?.[0]?.["value"] ?? null,
        tag: input["performanceAvg|yearly|D"]?.[0]?.["tag"],
      },
      deltaMaxDrawdown: {
        value: input["drawdownAvg|yearly|D"]?.[0]?.["value"] ?? null,
        tag: input["drawdownAvg|yearly|D"]?.[0]?.["tag"],
      },
      deltaVolatility: {
        value: input["volatilityAvg|yearly|D"]?.[0]?.["value"] ?? null,
        tag: input["volatilityAvg|yearly|D"]?.[0]?.["tag"],
      },
      turnover: null,
    };

    let avgMonthlyRow = {
      timeFrame: "average",
      strategyMonthlyPerformance: {
        value: input["performanceAvg|monthly|H"]?.[0]?.["value"] ?? null,
        tag: input["performanceAvg|monthly|H"]?.[0]?.["tag"],
      },
      strategyMaxDrawdown: {
        value: input["drawdownAvg|monthly|H"]?.[0]?.["value"] ?? null,
        tag: input["drawdownAvg|monthly|H"]?.[0]?.["tag"],
      },
      strategyVolatility: {
        value: input["volatilityAvg|monthly|H"]?.[0]?.["value"] ?? null,
        tag: input["volatilityAvg|monthly|H"]?.[0]?.["tag"],
      },
      benchmarkMonthlyPerformance: {
        value: input["performanceAvg|monthly|B"]?.[0]?.["value"] ?? null,
        tag: input["performanceAvg|monthly|B"]?.[0]?.["tag"],
      },
      benchmarkMaxDrawdown: {
        value: input["drawdownAvg|monthly|B"]?.[0]?.["value"] ?? null,
        tag: input["drawdownAvg|monthly|B"]?.[0]?.["tag"],
      },
      benchmarkVolatility: {
        value: input["volatilityAvg|monthly|B"]?.[0]?.["value"] ?? null,
        tag: input["volatilityAvg|monthly|B"]?.[0]?.["tag"],
      },
      deltaMonthlyPerformance: {
        value: input["performanceAvg|monthly|D"]?.[0]?.["value"] ?? null,
        tag: input["performanceAvg|monthly|D"]?.[0]?.["tag"],
      },
      deltaMaxDrawdown: {
        value: input["drawdownAvg|monthly|D"]?.[0]?.["value"] ?? null,
        tag: input["drawdownAvg|monthly|D"]?.[0]?.["tag"],
      },
      deltaVolatility: {
        value: input["volatilityAvg|monthly|D"]?.[0]?.["value"] ?? null,
        tag: input["volatilityAvg|monthly|D"]?.[0]?.["tag"],
      },
      turnover: null,
    };

    let avgQuarterlyRow = {
      timeFrame: "average",
      strategyQuarterlyPerformance: {
        value: input["performanceAvg|quarterly|H"]?.[0]?.["value"] ?? null,
        tag: input["performanceAvg|quarterly|H"]?.[0]?.["tag"],
      },
      strategyMaxDrawdown: {
        value: input["drawdownAvg|quarterly|H"]?.[0]?.["value"] ?? null,
        tag: input["drawdownAvg|quarterly|H"]?.[0]?.["tag"],
      },
      strategyVolatility: {
        value: input["volatilityAvg|quarterly|H"]?.[0]?.["value"] ?? null,
        tag: input["volatilityAvg|quarterly|H"]?.[0]?.["tag"],
      },
      benchmarkQuarterlyPerformance: {
        value: input["performanceAvg|quarterly|B"]?.[0]?.["value"] ?? null,
        tag: input["performanceAvg|quarterly|B"]?.[0]?.["tag"],
      },
      benchmarkMaxDrawdown: {
        value: input["drawdownAvg|quarterly|B"]?.[0]?.["value"] ?? null,
        tag: input["drawdownAvg|quarterly|B"]?.[0]?.["tag"],
      },
      benchmarkVolatility: {
        value: input["volatilityAvg|quarterly|B"]?.[0]?.["value"] ?? null,
        tag: input["volatilityAvg|quarterly|B"]?.[0]?.["tag"],
      },
      deltaQuarterlyPerformance: {
        value: input["performanceAvg|quarterly|D"]?.[0]?.["value"] ?? null,
        tag: input["performanceAvg|quarterly|D"]?.[0]?.["tag"],
      },
      deltaMaxDrawdown: {
        value: input["drawdownAvg|yearly|D"]?.[0]?.["value"] ?? null,
        tag: input["drawdownAvg|yearly|D"]?.[0]?.["tag"],
      },
      deltaVolatility: {
        value: input["volatilityAvg|yearly|D"]?.[0]?.["value"] ?? null,
        tag: input["volatilityAvg|yearly|D"]?.[0]?.["tag"],
      },
      turnover: null,
    };

    let annualizedMonthly = {
      timeFrame: "Annualized",
      strategyMonthlyPerformance: {
        value: input["performance|annualized|H"]?.[0]?.["value"] ?? null,
        tag: input["performance|annualized|H"]?.[0]?.["tag"],
      },
      strategyMaxDrawdown: null,
      strategyVolatility: null,
      benchmarkMonthlyPerformance: {
        value: input["performance|annualized|B"]?.[0]?.["value"] ?? null,
        tag: input["performance|annualized|B"]?.[0]?.["tag"],
      },
      benchmarkMaxDrawdown: null,
      benchmarkVolatility: null,
      deltaMonthlyPerformance: {
        value: input["performance|annualized|D"]?.[0]?.["value"] ?? null,
        tag: input["performance|annualized|D"]?.[0]?.["tag"],
      },
      deltaMaxDrawdown: null,
      deltaVolatility: null,
      turnover: null,
    };
    let annualizedQuarterly = {
      timeFrame: "Annualized",
      strategyQuarterlyPerformance: {
        value: input["performance|annualized|H"]?.[0]?.["value"] ?? null,
        tag: input["performance|annualized|H"]?.[0]?.["tag"],
      },
      strategyMaxDrawdown: null,
      strategyVolatility: null,
      benchmarkQuarterlyPerformance: {
        value: input["performance|annualized|B"]?.[0]?.["value"] ?? null,
        tag: input["performance|annualized|B"]?.[0]?.["tag"],
      },
      benchmarkMaxDrawdown: null,
      benchmarkVolatility: null,
      deltaQuarterlyPerformance: {
        value: input["performance|annualized|D"]?.[0]?.["value"] ?? null,
        tag: input["performance|annualized|D"]?.[0]?.["tag"],
      },
      deltaMaxDrawdown: null,
      deltaVolatility: null,
      turnover: null,
      tag: "",
    };

    for (const row of dataList) {
      if (row.period && prototype[row.period] && row.analytic !== "turnover") {
        prototype[row.period].push(row);
      }
    }

    for (const key in prototype) {
      if (key === "performances") {
        continue;
      }

      prototype[key] = this.transformToUISchema(
        this.groupByTimeframe(prototype[key])
      );
    }

    prototype.yearly.push(avgYearlyRow);
    prototype.monthly.push(avgMonthlyRow);
    prototype.quarterly.push(avgQuarterlyRow);

    prototype.yearly.push(annualizedYearly);
    prototype.monthly.push(annualizedMonthly);
    prototype.quarterly.push(annualizedQuarterly);

    //Assign to yearly data the turnovers
    if (input["turnover|yearly|H"] && input["turnover|yearly|H"].length) {
      const turnoverMap = {};

      for (const turnoverObj of input["turnover|yearly|H"]) {
        const formattedKey = TDate.dateToIso8601(
          TDate.daysToDate(parseInt(turnoverObj.timeframe))
        );
        const [year, ,] = formattedKey.split("-");

        turnoverMap[year] = {
          value: turnoverObj?.value ?? null,
          tag: turnoverObj?.tag,
        };
      }

      turnoverMap["average"] = {
        value: input["turnover|avgTurnover|H"]?.[0]?.value ?? null,
        tag: input["turnover|avgTurnover|H"]?.[0]?.tag,
      };

      prototype.yearly = prototype.yearly.map((item) => ({
        ...item,
        turnover: turnoverMap?.[item.timeFrame] ?? null,
      }));
    }

    return prototype;
  }

  private transformToUISchema(map) {
    const result: any = [];

    const listFromMap: any = Object.values(map);

    const dateMap = {};

    listFromMap.forEach((item) => {
      let data = item.reduce((prev: any, current: any) => {
        const date = TDate.dateToIso8601(TDate.daysToDate(current.timeframe));

        const [years, months] = date.split("-");

        const dateForUI =
          current.period !== "yearly" ? `${years}_${months}` : years;

        prev["timeFrame"] = dateForUI;

        const keyBuilderInfo = {
          analytic: current.analytic,
          period: current.period,
          curve: current.curve,
        };

        const key = this.buildAnalyticsObjKey(keyBuilderInfo);

        // Keys about average and annualization are handled differently
        if (key) {
          prev[key] = { value: current.value, tag: current.tag };
        }

        return prev;
      }, {});

      // This operation is needed to avoid entries duplication cause by different dates between the benchmark and the strategy curve
      // this situation might be caused if the strategy use the price of today but the benchmark price is not updated for some reason
      // (market extraordinary closure).
      // In this scenario the POS of the benchmark and the POS of the main history can differ by some days but the year still be the
      // same so the entry is duplicated and has to be reduced in a single one.
      if (dateMap[data.timeFrame]) {
        dateMap[data.timeFrame]++;
      } else {
        dateMap[data.timeFrame] = 1;
      }

      result.push(data);
    });

    const adjustedEntries: any = [];
    let multipleEntries: any = null;
    let singleEntry: any = {};

    for (const el of result) {
      const timeframe = el.timeFrame;
      const elementsPerDate = dateMap[timeframe];
      if (elementsPerDate > 0) {
        if (elementsPerDate > 1) {
          multipleEntries = result.filter(
            (item) => item.timeFrame === timeframe
          );

          if (multipleEntries && multipleEntries.length) {
            singleEntry = multipleEntries.reduce((prev, current) => {
              return { ...prev, ...current };
            }, {});
          }

          adjustedEntries.push({ ...singleEntry });
          dateMap[timeframe] = 0;
          singleEntry = {};
          multipleEntries = null;
        } else {
          adjustedEntries.push(el);
          dateMap[timeframe]--;
        }
      }
    }

    return adjustedEntries;
  }

  private buildAnalyticsObjKey({
    curve,
    period,
    analytic,
  }: {
    curve: "H" | "B" | "D";
    period: "yearly" | "quarterly" | "monthly" | "annualized";
    analytic: "performance" | "drawdown" | "volatility";
  }) {
    const DICT_KEY = {
      analytic: {
        performance: "Performance",
        drawdown: "MaxDrawdown",
        volatility: "Volatility",
      },
      period: {
        yearly: "Yearly",
        monthly: "Monthly",
        quarterly: "Quarterly",
        annualized: "Annualized",
      },
      curve: {
        H: "strategy",
        B: "benchmark",
        D: "delta",
      },
    };

    if (period === "annualized") {
      return DICT_KEY["period"][period];
    }

    if (analytic !== "performance") {
      if (DICT_KEY["curve"][curve] && DICT_KEY["analytic"][analytic]) {
        return DICT_KEY["curve"][curve] + DICT_KEY["analytic"][analytic];
      } else {
        return undefined;
      }
    } else {
      if (
        DICT_KEY["curve"][curve] &&
        DICT_KEY["period"][period] &&
        DICT_KEY["analytic"][analytic]
      ) {
        return (
          DICT_KEY["curve"][curve] +
          DICT_KEY["period"][period] +
          DICT_KEY["analytic"][analytic]
        );
      } else {
        return undefined;
      }
    }
  }

  private groupByTimeframe(arr) {
    const property = "timeframe";
    let timeframe: any = null;

    return arr.reduce((prev, current) => {
      timeframe = current[property];

      if (!prev[timeframe]) {
        prev[timeframe] = [];
      }

      prev[timeframe].push(current);

      return prev;
    }, {});
  }

  private transformToKeyFacts(input) {
    const prototype = {
      performance: {
        cumulative: {
          strategy: { value: null, tag: "" },
          benchmark: { value: null, tag: "" },
          delta: { value: null, tag: "" },
        },
        annualized: {
          strategy: { value: null, tag: "" },
          benchmark: { value: null, tag: "" },
          delta: { value: null, tag: "" },
        },
        yearlyAverage: {
          strategy: { value: null, tag: "" },
          benchmark: { value: null, tag: "" },
          delta: { value: null, tag: "" },
        },
        winningPeriods: {
          strategy: { value: null, tag: "" },
          benchmark: { value: null, tag: "" },
          delta: { value: null, tag: "" },
        },
      },
      risk: {
        maxDrawdown: {
          strategy: { value: null, tag: "" },
          benchmark: { value: null, tag: "" },
          delta: { value: null, tag: "" },
        },
        avgYearlyDrawdown: {
          strategy: { value: null, tag: "" },
          benchmark: { value: null, tag: "" },
          delta: { value: null, tag: "" },
        },
        monthlyStdDev: {
          strategy: { value: null, tag: "" },
          benchmark: { value: null, tag: "" },
          delta: { value: null, tag: "" },
        },
        maxConsecutiveLoosingPeriod: {
          strategy: { value: null, tag: "" },
          benchmark: { value: null, tag: "" },
          delta: { value: null, tag: "" },
        },
      },
      keyRatios: {
        avgTurnover: {
          strategy: { value: null, tag: "" },
          benchmark: { value: null, tag: "" },
          delta: { value: null, tag: "" },
        },
        sharpeRatio: {
          strategy: { value: null, tag: "" },
          benchmark: { value: null, tag: "" },
          delta: { value: null, tag: "" },
        },
        sterlingRatio: {
          strategy: { value: null, tag: "" },
          benchmark: { value: null, tag: "" },
          delta: { value: null, tag: "" },
        },
        sortinoRatio: {
          strategy: { value: null, tag: "" },
          benchmark: { value: null, tag: "" },
          delta: { value: null, tag: "" },
        },
        beta: {
          strategy: { value: null, tag: "" },
          benchmark: { value: null, tag: "" },
          delta: { value: null, tag: "" },
        },
        trackingError: {
          strategy: { value: null, tag: "" },
          benchmark: { value: null, tag: "" },
          delta: { value: null, tag: "" },
        },
        infoRatio: {
          strategy: { value: null, tag: "" },
          benchmark: { value: null, tag: "" },
          delta: { value: null, tag: "" },
        },
        treynorRatio: {
          strategy: { value: null, tag: "" },
          benchmark: { value: null, tag: "" },
          delta: { value: null, tag: "" },
        },
        percentagePositivePeriod: {
          strategy: { value: null, tag: "" },
          benchmark: { value: null, tag: "" },
          delta: { value: null, tag: "" },
        },
        winningPeriod: {
          strategy: { value: null, tag: "" },
          benchmark: { value: null, tag: "" },
          delta: { value: null, tag: "" },
        },
        winningAvgPeriod: {
          strategy: { value: null, tag: "" },
          benchmark: { value: null, tag: "" },
          delta: { value: null, tag: "" },
        },
        losingPeriod: {
          strategy: { value: null, tag: "" },
          benchmark: { value: null, tag: "" },
          delta: { value: null, tag: "" },
        },
        losingAvgPeriod: {
          strategy: { value: null, tag: "" },
          benchmark: { value: null, tag: "" },
          delta: { value: null, tag: "" },
        },
      },
      period: null,
      hasBenchmark: false,
    };

    return this.transformer(prototype, input);
  }

  private transformer(prototype, input) {
    for (const [key, value] of Object.entries<{
      [key: string]: { value: any; indicator: string };
    }>(input)) {
      const { page, section, curve } = this.resolvePathToObject(key);

      // Check useful for analitics calculated in group and not divided by tab
      if (
        page in prototype &&
        section in prototype[page] &&
        curve in prototype[page][section]
      )
        prototype[page][section][curve] = {
          value:
            value.value != null ? Object.values(value.value as any)[0] : null,
          tag: value.indicator,
        };
    }

    return prototype;
  }

  protected progressBarInit = (
    loaderFn: LoadingCallbacks,
    strategyName: string,
    isBenchmark = false
  ) => {
    if (!loaderFn) {
      console.warn(
        "No loading behaviour is specified so the app not crash but cannot show the progress bar"
      );

      return;
    }

    let eventSourceId =
      this.apiStrategies.generateBusId() +
      "_" +
      Math.floor(Math.random() * 1000);

    if (isBenchmark) {
      eventSourceId += "-2";
    }

    // setTimeout(() =>
    this.apiStrategies.status(eventSourceId, (chunk) => {
      if ("status" in chunk && chunk.status === "start") {
        loaderFn.startNew({
          min: chunk.d,
          max: chunk.de,
          point: chunk.d,
          id: eventSourceId,
          name: strategyName,
        });
      } else if (!("status" in chunk)) {
        loaderFn.update(eventSourceId, chunk.d);
      } else if ("status" in chunk && chunk.status === "stop") {
        loaderFn.complete(eventSourceId);
      }
    });
    // );

    return eventSourceId;
  };

  protected async getCurve(
    who: "H" | "B",
    what: "prices" | "allocations" | "components"
  ) {
    return await this.analyticsCollector!.get(who, what);
  }

  async holdings() {
    const listHistory = await this.getCurve("H", "allocations");
    const currency = this.strategy?.params?.strategy?.currency ?? "local";
    const lastAllocation = listHistory?.[listHistory.length - 1];
    const snapshot = await this.snapshot();

    const paramsAllocationAt = {
      cutoff: "last",
      date: lastAllocation?.d,
      listHistory: { POS: listHistory, currency },
      product: { id: null },
    };

    let startIndex: any = null;
    for (let i = 0, length = listHistory.length; i < length; i++) {
      if (listHistory[i]["d"] <= lastAllocation.d) {
        startIndex = i;
      }
    }
    if (startIndex == null) {
      startIndex = listHistory.length - 1;
    }

    const allocationAt = await this.apiSMS.allocationAt(paramsAllocationAt);

    // snapshot holdings weight are actualized at
    // today
    const snapshotHoldingsMap = {};
    let holding: any = null;
    for (var i = 0, length = snapshot["positions"].length; i < length; i++) {
      holding = snapshot["positions"][i];
      snapshotHoldingsMap[holding["symbol"]] = holding;
    }
    // merging actualized weights with contributions
    // data
    const holdings: any = [];
    for (let i = 0; i < allocationAt["positions"].length; i++) {
      holding = allocationAt["positions"][i];
      // skip expired/unavailable
      if (holding["symbol"] in snapshotHoldingsMap) {
        holding["weight"] = snapshotHoldingsMap[holding["symbol"]]["weight"];

        holdings.push(holding);
      }
    }

    return { holdings };
  }

  async snapshot() {
    const listHistory = await this.getCurve("H", "allocations");
    const currency = this.strategy?.params?.strategy?.currency ?? "local";

    let positionsToday: any = null;
    const lastAllocation = listHistory?.[listHistory.length - 1];
    const postionsOfLastAllocation = lastAllocation.v.map((pos) => ({
      symbol: pos.S,
      weight: pos.A,
    }));
    const asOfDate = TDate.daysToIso8601(this.environment.today.today);
    const fromDate = TDate.daysToIso8601(lastAllocation.d);

    const responseWeights = await this.apiLists._updateWeights(
      postionsOfLastAllocation,
      currency,
      fromDate,
      asOfDate
    );

    positionsToday = responseWeights.v;

    const paramsRatingAt = {
      adjustWeights: true,
      currency,
      equalWeighted: false,
      normalize: false,
      perfMetricMode: "active",
      v: positionsToday,
    };

    const response = await this.apiSMS.ratingAt(paramsRatingAt);

    return response;
  }

  async info() {
    if (this.strategy) {
      return deepClone(this.strategy);
    }
  }

  async getKeyFactsFrom(period) {
    return await this.getAnalytics("keyFacts", undefined, undefined, period);
  }
}

import { current } from "immer";
export type PreferencesType = {
  alerts: {
    baskets: any;
    portfolios: any;
    universe: any;
    pinned: any;
  };
  analysisList: {
    exposure: {
      ratings: "A_B_vs_C_D" | "A_B_C_D";
    };
    allocation: {
      where: "Country" | "Region" | "Area";
      what: "1 Industry" | "3 Sector";
    };
    alerts: {
      hasUpgradeDowngrade: boolean;
      hasNewLow: boolean;
      hasNewHigh: boolean;
      hasNotification: boolean;
    };
    view: "table" | "tile";
  };
  analysisMarket: {
    where: {
      markets: any;
    };
  };
  analysisSecurity: {
    compare: {
      lastRating: boolean;
    };
    historical: {
      enabled: boolean;
    };
  };
  emailDigest: {
    days: any;
    period: number;
    autoRebalanceSystematicPortfolioNotification: true|false|undefined|null
  };
  report: any;
  //    {
  //     general: {
  //       disclaimer: string;
  //       logo: {
  //         base64: string;
  //         url: string | null;
  //       };
  //       theme: any;
  //     };
  //   };
  screening: {
    markets: {
      ids: any[];
    };
  };
  favorites: {
    data: {
      symbol: string;
      timestamp: number;
    }[];
    index: any;
  };
  general: {
    home: "systematic_portfolios" | "analysis_list" | "screening" | "alerts";
  };
};

type ActionType = {
  type:
    | "SET_LANDING_PAGE"
    | "SET_UPGRADES_DOWNGRADES"
    | "SET_NEW_LOWS"
    | "SET_VIEW"
    | "SET_NEW_HIGHS"
    | "SET_ANALYSISLIST_ALLOCATION_WHERE"
    | "SET_ANALYSISLIST_ALLOCATION_WHAT"
    | "SET_ANALYSISLIST_EXPOSURE_RATINGS"
    | "SET_REPORT_DISCLAIMER"
    | "SET_REPORT_LOGO"
    | "SET_SCREENING_FILTERS"
    | "SET_EMAILDIGEST_DAYS"
    | "SET_EMAILDIGEST_SMS_AUTO_REBALANCE"
    | "SET_EMAILDIGEST_PORTFOLIO_NEWS"
    | "SET_EMAILDIGEST_BASKET_NEWS"
    | "SET_EMAILDIGEST_UNIVERSE"
    | "SET_ANALYSIS_INSTRUMENT"
    | "SET_MARKETS_FILTERS";
  payload: any;
  saving?: Function;
};

export const preferenceReducer = (
  draft: PreferencesType,
  action: ActionType
) => {
  switch (action.type) {
    case "SET_LANDING_PAGE":
      const copy = current(draft);
      if (copy.general != null) {
        draft.general.home = action.payload;
        if (action.saving) {
          action.saving(current(draft));
        }
      } else {
        copy["general"] = { home: action.payload };
        if (action.saving) {
          action.saving(copy);
        }
        draft = copy;
        return draft;
      }
      break;

    case "SET_UPGRADES_DOWNGRADES":
      draft.analysisList.alerts.hasUpgradeDowngrade = action.payload;
      if (action.saving) {
        action.saving(current(draft));
      }
      break;
    case "SET_NEW_LOWS":
      draft.analysisList.alerts.hasNewLow = action.payload;
      if (action.saving) {
        action.saving(current(draft));
      }
      break;
    case "SET_NEW_HIGHS":
      draft.analysisList.alerts.hasNewHigh = action.payload;
      if (action.saving) {
        action.saving(current(draft));
      }
      break;
    case "SET_VIEW":
      draft.analysisList.view = action.payload;
      if (action.saving) {
        action.saving(current(draft));
      }
      break;
    case "SET_ANALYSISLIST_ALLOCATION_WHERE":
      draft.analysisList.allocation.where = action.payload;
      if (action.saving) {
        action.saving(current(draft));
      }
      break;
    case "SET_ANALYSISLIST_ALLOCATION_WHAT":
      draft.analysisList.allocation.what = action.payload;
      if (action.saving) {
        action.saving(current(draft));
      }
      break;
    case "SET_ANALYSISLIST_EXPOSURE_RATINGS":
      draft.analysisList.exposure.ratings = action.payload;
      if (action.saving) {
        action.saving(current(draft));
      }
      break;
    case "SET_REPORT_DISCLAIMER":
      draft.report.general.disclaimer = action.payload;
      if (action.saving) {
        action.saving(current(draft));
      }
      break;
    case "SET_REPORT_LOGO":
      if (
        draft.report.general.logo != null &&
        "base64" in draft.report.general.logo
      ) {
        draft.report.general.logo.base64 = action.payload;
      } else {
        draft.report.general.logo = {
          base64: action.payload,
        };
      }

      if (action.saving) {
        action.saving(current(draft));
      }
      break;
    case "SET_SCREENING_FILTERS":
      draft.screening.markets.ids = action.payload;
      if (action.saving) {
        action.saving(current(draft));
      }
      break;
    case "SET_MARKETS_FILTERS":
      draft.analysisMarket.where.markets = action.payload;
      if (action.saving) {
        action.saving(current(draft));
      }
      break;
    case "SET_EMAILDIGEST_DAYS":
      draft.emailDigest.days = action.payload;
      if (action.saving) {
        action.saving(current(draft));
      }
      break;
    case "SET_EMAILDIGEST_SMS_AUTO_REBALANCE":
      draft.emailDigest.autoRebalanceSystematicPortfolioNotification = action.payload;
      if (action.saving) {
        action.saving(current(draft));
      }
      break;
    case "SET_EMAILDIGEST_PORTFOLIO_NEWS":
      draft.alerts.portfolios = action.payload;
      if (action.saving) {
        action.saving(current(draft));
      }
      break;
    case "SET_EMAILDIGEST_BASKET_NEWS":
      draft.alerts.baskets = action.payload;
      if (action.saving) {
        action.saving(current(draft));
      }
      break;

    case "SET_EMAILDIGEST_UNIVERSE":
      // debugger;
      if (draft.alerts == null) {
        draft.alerts = {
          baskets: {
            enabled: false,
            nhnl: false,
            notifications: true,
            rerating: true,
          },
          portfolios: {
            enabled: false,
            nhnl: false,
            notifications: true,
            rerating: true,
          },
          universe: {
            enabled: false,
            nhnl: false,
            notifications: true,
            rerating: true,
          },
          pinned: [],
        };
      }
      draft.alerts.universe = action.payload;
      if (action.saving) {
        action.saving(current(draft));
      }

      break;
    case "SET_ANALYSIS_INSTRUMENT":
      draft.analysisSecurity = action.payload;
      if (action.saving) {
        action.saving(current(draft));
      }
      break;
    default:
      break;
  }
};

import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  formatTaxonPrefixingParent,
  getTaxonById,
} from "../../../../../../api/compute/Taxon";
import { deepClone } from "../../../../../../deepClone";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../hooks/useFormatter";
import { CellTooltip } from "./CellTooltip";

type PeerTableBodyProps = {
  params: any;
  data: any;
  storage: any;
  decorator: (data, cellType) => string;
  getTooltipData: Function;
  onCellClick: (peerId) => any;
};

type BodyProps = {
  params: PeerTableBodyProps["params"];
  storage: PeerTableBodyProps["storage"];
  decorator: PeerTableBodyProps["decorator"];
  row: any;
  rowIndex: number;
  getTooltipData: PeerTableBodyProps["getTooltipData"];
  onCellClick: (peerId) => any;
};

type TableBodyProps = {
  params: PeerTableBodyProps["params"];
  storage: PeerTableBodyProps["storage"];
  decorator: PeerTableBodyProps["decorator"];
  row: any;
  collapserListener: (cellId, rowId) => void;
  rowIndex: number;
  getTooltipData: PeerTableBodyProps["getTooltipData"];
  onCellClick: PeerTableBodyProps["onCellClick"];
};

type TableDataCellsProps = {
  params: PeerTableBodyProps["params"];
  storage: PeerTableBodyProps["storage"];
  decorator: PeerTableBodyProps["decorator"];
  cellData: any;
  index: number;
  collapserListener: TableBodyProps["collapserListener"];
  rowIndex: TableBodyProps["rowIndex"];
  getTooltipData: PeerTableBodyProps["getTooltipData"];
  onCellClick: PeerTableBodyProps["onCellClick"];
};

type EmptyCellProps = {
  decorator: PeerTableBodyProps["decorator"];
  cellData: any;
  cssClassCellContentWrapper: string;
};

type CellCollapserProps = {
  peerWhatWhereInfo: any;
  cellId: any;
  cellData: any;
  decorator: PeerTableBodyProps["decorator"];
  collapserListener: TableBodyProps["collapserListener"];
  rowIndex: TableBodyProps["rowIndex"];
  storage: PeerTableBodyProps["storage"];
  params: PeerTableBodyProps["params"];
  getTooltipData: PeerTableBodyProps["getTooltipData"];
  onClickCell: PeerTableBodyProps["onCellClick"];
};

type CellTcrProps = {
  params: PeerTableBodyProps["params"];
  cellData: any;
  cellId: string;
  storage: any;
  t: any;
  getTooltipData: any;
};

type CellNumberProps = CellTcrProps;

type CellNumberContentProps = {
  analytic: any;
  timeframe: any;
  cellData: any;
  visualization: any;
  dispersionTimeframe: any;
};

const baseClass = "tPeerTable";
const cssClassCellContentWrapper = "tPeerTable-tableCellContentWrapper";
const cssClassCellContentWrapperWhat = cssClassCellContentWrapper + "--what";
const cssClassCellHasTooltip = "tPeerTable-tableCell--hasTooltip";
const cssClassCellContentTcr = baseClass + "-tableCellContentTcr";
const cssClassCellContentArrow = baseClass + "-arrow";
const cssClassCellContentArrowAngleDowngrade = baseClass + "-arrow--downgrade";
const cssClassCellContentArrowAngleUpgrade = baseClass + "-arrow--upgrade";
const cssClassCellContentNumber = baseClass + "-tableCellContentNumber";

const _getTcrPrevious = (peer, analytic) => {
  switch (analytic) {
    case "tcr_lastWeek_change":
    case "tcr_lastWeek_color":
    case "tcr_lastWeek_reversal": {
      return peer["tcr"]["lastWeek"];
    }
    case "tcr_lastMonth_change":
    case "tcr_lastMonth_color":
    case "tcr_lastMonth_reversal": {
      return peer["tcr"]["lastMonth"];
    }
    case "tcr_today_change":
    case "tcr_today_reversal": {
      return peer["tcr"]["yesterday"];
    }
    case "tcr_today_color": {
      return peer["tcr"]["today"];
    }

    // no default
  }
};

/**
 * Format a float number as percentage
 *
 * @param {Number} value - float number rapresenting percentage. 1 means
 *      100%, 0.15 means 15%
 * @param {Boolean} hasArrow - add an arrow according to value: up arrow
 *      if value is positive, down arrow if value is negative
 *
 * @returns {string}
 *
 * @ignore
 */
const formatPercentage = (format, value, hasArrow) => {
  var formatted: any = [];

  if (hasArrow === true) {
    if (value > 0) {
      formatted.push('<i class="i-arrow-u" style="color: green;"></i>');
    } else if (value < 0) {
      formatted.push('<i class="i-arrow-d" style="color: red;"></i>');
    }
  }

  var percentage = format.custom("number", {
    options: {
      decimals: value === 1 ? 0 : 2,
      isPercentage: true,
      notAvailable: {
        input: 0,
        output: "-",
      },
    },
    output: "HTML",
    value: value,
    valueHelper: null,
  });
  percentage =
    /00%$/gi.test(percentage) === true
      ? percentage.replace(".00%", "%")
      : percentage;

  formatted.push(percentage);

  return formatted.join(" ");
};

const formatNumber = (value, format) => {
  return format.custom("number", {
    options: {
      decimals: 0,
      notAvailable: {
        input: 0,
        output: "-",
      },
    },
    output: "HTML",
    value: value,
    valueHelper: null,
  });
};

export function PeerTableBody({
  params,
  data,
  storage,
  decorator,
  getTooltipData,
  onCellClick,
}: PeerTableBodyProps) {
  return (
    <>
      {data?.map((row, i) => (
        <Body
          onCellClick={onCellClick}
          key={`${row.id}__${row.name}__${i}`}
          storage={storage}
          decorator={decorator}
          params={params}
          rowIndex={i}
          row={row}
          getTooltipData={getTooltipData}
        />
      ))}
    </>
  );
}

const CellNumber = ({ params, cellData }: CellNumberProps) => {
  const analytic = params["analyticKey"];
  const timeframe = params["timeframe"];
  const visualization = params["visualization"];
  const dispersionTimeframe = params["dispersionTimeframe"];

  return (
    <div className={cssClassCellContentWrapper}>
      <div className={cssClassCellContentNumber}>
        <CellNumberContent
          analytic={analytic}
          timeframe={timeframe}
          visualization={visualization}
          cellData={cellData}
          dispersionTimeframe={dispersionTimeframe}
        />
      </div>
    </div>
  );
};

const Body = ({
  storage,
  row,
  decorator,
  params,
  rowIndex,
  getTooltipData,
  onCellClick,
}: BodyProps) => {
  const [childMap, setChildMap] = useState({});

  const listenerCollapserClick = useCallback(
    async (peerId, rowId) => {
      const newState = deepClone(childMap);

      if (childMap[rowId] != null) {
        delete newState[rowId];
      } else {
        newState[rowId] = await storage.getChildrenBySector(peerId);
      }

      setChildMap(newState);
    },
    [childMap, storage]
  );

  return (
    <>
      <TableBody
        onCellClick={onCellClick}
        storage={storage}
        row={row}
        params={params}
        decorator={decorator}
        collapserListener={listenerCollapserClick}
        rowIndex={rowIndex}
        getTooltipData={getTooltipData}
      />
      <tbody className={baseClass + "-rowChildren"}>
        {childMap[rowIndex] != null &&
          childMap[rowIndex].map((rowChild, index) => (
            <tr key={`${rowChild.id}__${rowChild.name}__${index}`}>
              {rowChild.map((cellData, index) => (
                <TableDataCells
                  onCellClick={onCellClick}
                  key={`${cellData.id}__${cellData.name}__${index}`}
                  rowIndex={rowIndex}
                  collapserListener={listenerCollapserClick}
                  cellData={cellData}
                  index={index}
                  storage={storage}
                  decorator={decorator}
                  params={params}
                  getTooltipData={getTooltipData}
                />
              ))}
            </tr>
          ))}
      </tbody>
    </>
  );
};

const TableBody = ({
  storage,
  row,
  decorator,
  params,
  collapserListener,
  rowIndex,
  getTooltipData,
  onCellClick,
}: TableBodyProps) => {
  return (
    <tbody>
      <tr>
        {row.map((cellData, index) => (
          <TableDataCells
            onCellClick={onCellClick}
            key={`${cellData.id}__${cellData.name}__${index}`}
            rowIndex={rowIndex}
            collapserListener={collapserListener}
            cellData={cellData}
            index={index}
            storage={storage}
            decorator={decorator}
            params={params}
            getTooltipData={getTooltipData}
          />
        ))}
      </tr>
    </tbody>
  );
};

const TableDataCells = ({
  cellData,
  storage,
  index,
  decorator,
  params,
  collapserListener,
  rowIndex,
  getTooltipData,
  onCellClick,
}: TableDataCellsProps) => {
  const cellId = cellData["id"];
  const hasData = cellData["info"]["cardinality"] > 0 ? true : false;
  const { t } = useTranslation();
  const dispersionTimeframe = params["dispersionTimeframe"];

  if (hasData) {
    const peerWhatWhereInfo = storage.getWhatWhereInfo(cellId);

    if (index === 0) {
      return (
        <CellCollapser
          onClickCell={onCellClick}
          storage={storage}
          rowIndex={rowIndex}
          collapserListener={collapserListener}
          cellData={cellData}
          cellId={cellId}
          decorator={decorator}
          peerWhatWhereInfo={peerWhatWhereInfo}
          params={params}
          getTooltipData={getTooltipData}
        />
      );
    } else {
      const analytic = params["analyticKey"];

      switch (analytic) {
        case "tcr_lastMonth_change":
        case "tcr_lastMonth_color":
        case "tcr_lastMonth_reversal":
        case "tcr_lastWeek_change":
        case "tcr_lastWeek_color":
        case "tcr_lastWeek_reversal":
        case "tcr_today_change":
        case "tcr_today_color":
        case "tcr_today_reversal": {
          return (
            <td
              onClick={() => onCellClick(cellId)}
              data-peer-id={cellId}
              className={`${cssClassCellHasTooltip} ${decorator(
                cellData,
                "cell"
              )}`}
            >
              <CellTcr
                getTooltipData={getTooltipData}
                cellId={cellId}
                storage={storage}
                t={t}
                params={params}
                cellData={cellData}
              />
            </td>
          );
        }
        case "abPercentage_lastMonth_percentage":
        case "abPercentage_lastWeek_percentage":
        case "abPercentage_today_percentage":
        case "cdPercentage_lastMonth_percentage":
        case "cdPercentage_lastWeek_percentage":
        case "cdPercentage_today_percentage":

        // eslint-disable-next-line no-fallthrough
        case "upgradesDowngrades_lastMonth_number":
        case "upgradesDowngrades_lastMonth_percentage":
        case "upgradesDowngrades_lastWeek_number":
        case "upgradesDowngrades_lastWeek_percentage":
        case "upgradesDowngrades_today_number":
        case "upgradesDowngrades_today_percentage":
        case "topBottom_today_top_bottom":
        case "topBottom_today_top":
        case "topBottom_today_bottom":
        case "topBottom_lastWeek_top_bottom":
        case "topBottom_lastWeek_top":
        case "topBottom_lastWeek_bottom":
        case "topBottom_lastMonth_top_bottom":
        case "topBottom_lastMonth_top":
        case "topBottom_lastMonth_bottom": {
          return (
            <td
              data-peer-id={cellId}
              onClick={() => onCellClick(cellId)}
              className={`${cssClassCellHasTooltip} ${decorator(
                cellData,
                "cellEmpty"
              )}`}
            >
              <CellTooltip
                dispersionAnalytic={dispersionTimeframe}
                getData={() => getTooltipData(cellId, storage, params, t)}
              >
                <CellNumber
                  getTooltipData={getTooltipData}
                  cellId={cellId}
                  storage={storage}
                  t={t}
                  params={params}
                  cellData={cellData}
                />
              </CellTooltip>
            </td>
          );
        }

        default:
          return <></>;
      }
    }
  } else {
    return (
      <EmptyCell
        cellData={cellData}
        decorator={decorator}
        cssClassCellContentWrapper={cssClassCellContentWrapper}
      />
    );
  }
};

const CellNumberContent = ({
  analytic,
  cellData,
  timeframe,
  visualization,
  dispersionTimeframe,
}: CellNumberContentProps) => {
  const format = useFormatter();
  switch (analytic) {
    case "cdPercentage_lastMonth_percentage":
    case "cdPercentage_lastWeek_percentage":
    case "cdPercentage_today_percentage": {
      var cd = cellData["statistic"]["cdPercentage"][timeframe];

      return (
        <div
          dangerouslySetInnerHTML={{
            __html: formatPercentage(
              format,
              cd,
              analytic === "cdPercentage_today_percentage" ? false : true
            ),
          }}
        ></div>
      );
    }
    case "abPercentage_lastMonth_percentage":
    case "abPercentage_lastWeek_percentage":
    case "abPercentage_today_percentage": {
      var ab = cellData["statistic"]["abPercentage"][timeframe];

      return (
        <div
          dangerouslySetInnerHTML={{
            __html: formatPercentage(
              format,
              ab,
              analytic === "abPercentage_today_percentage" ? false : true
            ),
          }}
        ></div>
      );
    }
    case "upgradesDowngrades_lastMonth_number":
    case "upgradesDowngrades_lastWeek_number":
    case "upgradesDowngrades_today_number": {
      var downgrades =
        cellData["statistic"]["downgrades"][timeframe][visualization];
      var upgrades =
        cellData["statistic"]["upgrades"][timeframe][visualization];

      if (downgrades > 0 || upgrades > 0) {
        return (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  upgrades === 0
                    ? "&nbsp;"
                    : "Up " + formatNumber(upgrades, format),
              }}
            ></div>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  downgrades === 0
                    ? "&nbsp;"
                    : "Dn " + formatNumber(downgrades, format),
              }}
            ></div>
          </>
        );
      } else {
        return <></>;
      }
    }
    case "upgradesDowngrades_lastMonth_percentage":
    case "upgradesDowngrades_lastWeek_percentage":
    case "upgradesDowngrades_today_percentage": {
      var percentageDowngrades =
        cellData["statistic"]["downgrades"][timeframe][visualization];
      var percentageUpgrades =
        cellData["statistic"]["upgrades"][timeframe][visualization];

      if (percentageDowngrades > 0 || percentageUpgrades > 0) {
        return (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  percentageUpgrades === 0
                    ? "&nbsp;"
                    : "Up " +
                      formatPercentage(format, percentageUpgrades, false),
              }}
            ></div>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  percentageDowngrades === 0
                    ? "&nbsp;"
                    : "Dn " +
                      formatPercentage(format, percentageDowngrades, false),
              }}
            ></div>
          </>
        );
      } else {
        return <></>;
      }
    }

    case "topBottom_today_top_bottom":
    case "topBottom_lastWeek_top_bottom":
    case "topBottom_lastMonth_top_bottom": {
      var dispersionTop =
        cellData["statistic"]["dispersion"][dispersionTimeframe ?? "pq"]?.[
          "top"
        ];
      var dispersionBottom =
        cellData["statistic"]["dispersion"][dispersionTimeframe ?? "pq"]?.[
          "bottom"
        ];

      if (dispersionTop != null || dispersionBottom != null) {
        return (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  dispersionTop === 0
                    ? "&nbsp;"
                    : formatPercentage(format, dispersionTop, false),
              }}
            ></div>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  dispersionBottom === 0
                    ? "&nbsp;"
                    : formatPercentage(format, dispersionBottom, false),
              }}
            ></div>
          </>
        );
      } else {
        return <></>;
      }
    }

    case "topBottom_today_top":
    case "topBottom_lastWeek_top":
    case "topBottom_lastMonth_top": {
      var dispersionTopValues =
        cellData["statistic"]["dispersion"][dispersionTimeframe ?? "pq"]?.[
          "top"
        ];

      if (dispersionTopValues != null) {
        return (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  dispersionTopValues === 0
                    ? "&nbsp;"
                    : formatPercentage(format, dispersionTopValues, false),
              }}
            ></div>
          </>
        );
      } else {
        return <></>;
      }
    }
    case "topBottom_today_bottom":
    case "topBottom_lastWeek_bottom":
    case "topBottom_lastMonth_bottom": {
      var dispersionBottomValues =
        cellData["statistic"]["dispersion"][dispersionTimeframe ?? "pq"]?.[
          "bottom"
        ];

      if (dispersionBottomValues != null) {
        return (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  dispersionBottomValues === 0
                    ? "&nbsp;"
                    : formatPercentage(format, dispersionBottomValues, false),
              }}
            ></div>
          </>
        );
      } else {
        return <></>;
      }
    }

    default:
      return <></>;
  }
};

const CellTcr = ({
  params,
  cellData,
  cellId,
  storage,
  t,
  getTooltipData,
}: CellTcrProps) => {
  const format = useFormatter();
  const analytic = params["analyticKey"];

  const tcr = cellData["tcr"]["today"];
  const tcrPrevious = _getTcrPrevious(cellData, analytic);
  const dispersionTimeframe = params["dispersionTimeframe"];

  // 0 is B-
  const _tcr = tcr === 0 ? 0.1 : tcr;
  const _tcrPrevious = tcrPrevious === 0 ? 0.1 : tcrPrevious;

  if (
    analytic !== "tcr_lastMonth_color" &&
    analytic !== "tcr_lastWeek_color" &&
    analytic !== "tcr_today_color"
  ) {
    if (_tcr != null && (_tcr !== _tcrPrevious || _tcr * _tcrPrevious < 0)) {
      var cssClassDirection: any = null;

      switch (analytic) {
        case "tcr_lastWeek_change":
        case "tcr_lastMonth_change":
        case "tcr_today_change": {
          if (_tcr !== _tcrPrevious) {
            cssClassDirection =
              tcr > tcrPrevious
                ? ["i-arrow-u", cssClassCellContentArrow, "greenArrow"].join(
                    " "
                  )
                : ["i-arrow-d", cssClassCellContentArrow, "redArrow"].join(" ");
          }

          break;
        }
        case "tcr_lastMonth_reversal":
        case "tcr_lastWeek_reversal":
        case "tcr_today_reversal": {
          if (_tcr * _tcrPrevious < 0) {
            cssClassDirection =
              tcr > tcrPrevious
                ? [
                    "i-arrow-angle",
                    cssClassCellContentArrow,
                    cssClassCellContentArrowAngleUpgrade,
                  ].join(" ")
                : [
                    "i-arrow-angle",
                    cssClassCellContentArrow,
                    cssClassCellContentArrowAngleDowngrade,
                  ].join(" ");
          }

          break;
        }

        // no default
      }

      if (cssClassDirection != null) {
        return (
          <div className={cssClassCellContentWrapper}>
            <CellTooltip
              dispersionAnalytic={dispersionTimeframe}
              getData={() => getTooltipData(cellId, storage, params, t)}
            >
              <div className={cssClassCellContentTcr}>
                <span className={cssClassDirection}></span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: format.tcr(tcr, "HTML"),
                  }}
                ></span>
              </div>
            </CellTooltip>
          </div>
        );
      }
    }
  }

  return (
    <CellTooltip
      dispersionAnalytic={dispersionTimeframe}
      getData={() => getTooltipData(cellId, storage, params, t)}
    >
      <div style={{ height: "100%", width: "100%", visibility: "hidden" }}>
        <div>no-content</div>
      </div>
    </CellTooltip>
  );
};

const EmptyCell = ({ cellData, decorator }: EmptyCellProps) => {
  const cssClassCellContentWrapperEmpty =
    cssClassCellContentWrapper + "--empty";
  return (
    <td>
      <div
        className={`${[
          cssClassCellContentWrapper,
          cssClassCellContentWrapperEmpty,
        ].join(" ")} ${decorator(cellData, "cellEmpty")}`}
      ></div>
    </td>
  );
};

const CellCollapser = ({
  peerWhatWhereInfo,
  cellId,
  cellData,
  decorator,
  collapserListener,
  rowIndex,
  storage,
  getTooltipData,
  params,
  onClickCell,
}: CellCollapserProps) => {
  const { t } = useTranslation();
  const environment = useEnvironment();
  const taxonomies = useMemo(
    () => environment.get("setup")["taxonomies"],
    [environment]
  );
  const fieldsMap = useMemo(
    () => environment.get("setup")["taxonomyFields"],
    [environment]
  );

  const peerType = cellData["type"] === "ETF" ? "ETF" : "security";
  const field = peerType === "ETF" ? "etfclass" : "icb";
  const sectorTaxonomyLevel = peerWhatWhereInfo["whatType"];
  const sectorTaxonomy = taxonomies[fieldsMap[peerType][field]];

  const dispersionTimeframe = params["dispersionTimeframe"];

  const cellName = useMemo(() => {
    if (peerType === "ETF" && sectorTaxonomyLevel === "3 Sector") {
      return formatTaxonPrefixingParent(
        getTaxonById(peerWhatWhereInfo["what"], [sectorTaxonomy], 0),
        [sectorTaxonomy],
        sectorTaxonomyLevel
      );
    } else {
      return cellData.name;
    }
  }, [
    cellData.name,
    peerWhatWhereInfo,
    sectorTaxonomy,
    sectorTaxonomyLevel,
    peerType,
  ]);

  return peerWhatWhereInfo["whatType"] !== "1 Industry" ? (
    <td
      onClick={() => onClickCell(cellId)}
      data-peer-id={`${
        peerWhatWhereInfo["whatType"] !== "1 Industry" ? cellId : null
      }`}
      className={`${
        peerWhatWhereInfo["whatType"] !== "1 Industry"
          ? cssClassCellHasTooltip
          : ""
      }`}
    >
      <CellTooltip
        dispersionAnalytic={dispersionTimeframe}
        getData={() => getTooltipData(cellId, storage, params, t)}
      >
        <div
          className={`${[
            cssClassCellContentWrapper,
            cssClassCellContentWrapperWhat,
          ].join(" ")} ${decorator(cellData, "cellWhat")}`}
        >
          <div
            className={`${
              peerWhatWhereInfo["whatType"] === "1 Industry"
                ? cssClassCellHasTooltip
                : ""
            }`}
            data-peer-id={`${
              peerWhatWhereInfo["whatType"] === "1 Industry" ? cellId : null
            }`}
            dangerouslySetInnerHTML={{
              __html: cellData["name"] === "__ROOT__" ? t("Any") : cellName,
            }}
          ></div>
          {peerWhatWhereInfo["whatType"] === "1 Industry" && (
            <button
              className={`${[
                "tPeerTable-rowChildrenCollapser",
                "i-triangle-down",
              ].join(" ")}`}
              title={t("Show_industries").replace(
                "name",
                cellData["name"] === "__ROOT__" ? t("Any") : cellData["name"]
              )}
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                collapserListener(cellId, rowIndex);
              }}
            ></button>
          )}
        </div>
      </CellTooltip>
    </td>
  ) : (
    <td
      onClick={() => onClickCell(cellId)}
      data-peer-id={`${
        peerWhatWhereInfo["whatType"] !== "1 Industry" ? cellId : null
      }`}
    >
      <div
        className={`${[
          cssClassCellContentWrapper,
          cssClassCellContentWrapperWhat,
        ].join(" ")} ${decorator(cellData, "cellWhat")}`}
      >
        <CellTooltip
          dispersionAnalytic={dispersionTimeframe}
          getData={() => getTooltipData(cellId, storage, params, t)}
        >
          <div
            className={`${
              peerWhatWhereInfo["whatType"] === "1 Industry"
                ? cssClassCellHasTooltip
                : ""
            }`}
            data-peer-id={`${
              peerWhatWhereInfo["whatType"] === "1 Industry" ? cellId : null
            }`}
            dangerouslySetInnerHTML={{
              __html:
                cellData["name"] === "__ROOT__" ? t("Any") : cellData["name"],
            }}
          ></div>
        </CellTooltip>
        {peerWhatWhereInfo["whatType"] === "1 Industry" && (
          <button
            className={`${[
              "tPeerTable-rowChildrenCollapser",
              "i-triangle-down",
            ].join(" ")}`}
            title={t("Show_industries").replace(
              "name",
              cellData["name"] === "__ROOT__" ? t("Any") : cellData["name"]
            )}
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              collapserListener(cellId, rowIndex);
            }}
          ></button>
        )}
      </div>
    </td>
  );
};

import { Card, CardContent } from "@mui/material";
import {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { Chart } from "../../../../../../../../../../../components/Chart";
import { useEnvironment } from "../../../../../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../../../../../hooks/useFormatter";
import { TDate } from "../../../../../../../../../../../trendrating/date/TDate";
import { Entity } from "../utils/Entity";

type Props = {
  timelineData: any;
  onTimeFrameChange;
  showSize: Boolean;
};

const entity = new Entity();
const _colors = {
  border: "#d3d3d3",
  lineGrid: "#d3d3d3",
  line: "#d3d3d3",
  securityDefault: "#2a7092", // #2F7ED8
  securityBenchmark: "#ffcc00", // #133458
  securityRateA: entity.get(2, "RATE").color,
  securityRateB: entity.get(1, "RATE").color,
  securityRateC: entity.get(-1, "RATE").color,
  securityRateD: entity.get(-2, "RATE").color,
  securityRateU: entity.get("U", "RATE").color,
  trendrating: "#2a7092", // #ffc001 #0da760,
  trendratingGold: "#ffc001",
  trendratingGrey: "#a3a3a3",
};

export const Timeline = memo(
  forwardRef(({ timelineData, onTimeFrameChange, showSize }: Props, ref) => {
    const _timelineData = useMemo(() => timelineData, [timelineData]);
    const chartRef = useRef<any>();
    const format = useFormatter();
    const previousSelected = "PERFORMANCE";
    const environment = useEnvironment();
    const appEnvironment = useMemo(
      () => environment.get("setup"),
      [environment]
    );
    const today = useMemo(
      () => appEnvironment.today.today,
      [appEnvironment.today.today]
    );

    const formatDate = useCallback(
      (milliseconds) => {
        return format.custom("date", {
          options: {
            format: ["d", "M", "Y"],
            isMillisecond: true,
            notAvailable: {
              input: null,
              output: "",
            },
            separator: " ",
          },
          output: "HTML",
          value: milliseconds,
          valueHelper: null,
        });
      },
      [format]
    );

    const getSizeSeries = useCallback(async () => {
      const getAnalyticsCallback = timelineData.getSerie;

      // const options: {
      //   factor: string;
      //   ge: number | string;
      //   lt: number | string;
      //   id: string;
      // }[] = [];
      // for (const opt of formOptions) {
      //   options.push({
      //     factor: "marketcap_%",
      //     lt: opt.le ? opt.le * 1000000 : "NULL",
      //     ge: opt.ge ? opt.ge * 1000000 : 0,
      //     id: opt.innerHTML.toLowerCase(),
      //   });
      // }

      const options = [
        {
          factor: "marketcap_%",
          lt: 300000000,
          ge: 1,
          id: "micro",
        },
        {
          factor: "marketcap_%",
          lt: 2000000000,
          ge: 300000000,
          id: "small",
        },
        {
          factor: "marketcap_%",
          lt: 10000000000,
          ge: 2000000000,
          id: "mid",
        },
        {
          factor: "marketcap_%",
          lt: "NULL",
          ge: 10000000000,
          id: "large",
        },
        // {
        //   factor: "marketcap_%",
        //   lt: "NULL",
        //   ge: 200000000000,
        //   id: "mega",
        // },
      ];

      const { analytics, HPOS } = await getAnalyticsCallback(options);
      const series = {};
      let serie: {
        x: number;
        y: number;
        name: string;
        d: number;
        de: number | null;
        trendrating: any;
      }[];

      let serieName = "";
      let pos: any = null;
      let de: any = null;

      for (const key in analytics) {
        serie = [];

        for (let i = 0, N = HPOS.length; i < N; i++) {
          pos = HPOS[i];
          de = HPOS?.[i + 1]?.["d"] ?? today;

          if (i === N - 1) {
            serieName = formatDate(TDate.daysToMilliseconds(pos["d"]));
          } else {
            serieName = `${formatDate(
              TDate.daysToMilliseconds(pos["d"])
            )} - ${formatDate(TDate.daysToMilliseconds(de))}`;
          }

          serie.push({
            d: pos.d,
            de: de,
            x: TDate.daysToMilliseconds(pos.d),
            y: analytics[key][de],
            name: serieName,
            trendrating: {
              allocation: undefined,
              cardinality: undefined,
              day: pos["d"],
              frameIndex: i,
              name: serieName,
              performance: undefined,
              A: 0,
              B: 0,
              C: 0,
              D: 0,
              NA: 0,
            },
          });
        }

        series[key] = serie;
      }

      return series;
    }, [formatDate, timelineData.getSerie, today]);

    useImperativeHandle(
      ref,
      () => ({
        insertSerie: (holdingExposure) => {
          if (holdingExposure == null) {
            return;
          }
          if (chartRef?.current?.get("HOLDING_EXPOSURE") != null) {
            chartRef?.current?.get("HOLDING_EXPOSURE").remove();
          }
          var data: any = [];
          var frame: any = null;
          var frames = _timelineData.frames;
          var framesRaw = holdingExposure.strategyResult.POS;
          var holding = holdingExposure.holding;
          var item: any = null;
          var symbol = holding.symbol;
          var x: any = null;
          var y: any = null;
          for (let i = 0, lengthI = framesRaw.length; i < lengthI; i++) {
            item = framesRaw[i];
            x = TDate.daysToMilliseconds(item.d);
            y = 0;
            for (let j = 0, lengthJ = item.v.length; j < lengthJ; j++) {
              if (item.v[j].S === symbol) {
                y = item.v[j].A * 100;
                break;
              }
            }

            frame = null;
            if (y !== 0) {
              for (const fr of frames) {
                if (fr.day === x) {
                  frame = fr;

                  break;
                }
              }
            }

            data.push({
              x: x,
              y: y,
              trendrating: frame,
            });
          }
          var serie = {
            color: _colors.securityDefault,
            data: data,
            dataGrouping: {
              enabled: false,
            },
            events: {
              legendItemClick: function (e) {
                const self: any = this;
                _listenerLegend(e, self.chart);
              },
            },
            fillColor: "#333",
            id: "HOLDING_EXPOSURE",
            lineWidth: 0,
            name: holding.ticker,
            tooltip: {
              valueDecimals: 2,
              valueSuffix: "%",
            },
            type: "column",
            yAxis: 1,
          };
          chartRef?.current?.addSeries(serie);
          _listenerLegend(
            {
              preventDefault: function () {
                return false;
              },
              target: {
                options: {
                  id: "HOLDING_EXPOSURE",
                },
              },
            },
            chartRef.current
          );
        },
      }),
      [_timelineData.frames]
    );

    const options = useMemo(() => {
      if (_timelineData != null) {
        return getChartOptions(
          previousSelected,
          _timelineData,
          format,
          onTimeFrameChange,
          getSizeSeries,
          showSize
        );
      }
    }, [_timelineData, format, getSizeSeries, onTimeFrameChange, showSize]);

    return (
      <Card
        sx={{
          width: "100%",
          minHeight: 200,
          position: "relative",
          boxShadow: 3,
        }}
      >
        <CardContent sx={{ height: "100%", p: 1, pb: "8px !important" }}>
          {_timelineData != null ? (
            <Chart ref={chartRef} options={options} />
          ) : null}
        </CardContent>
      </Card>
    );
  })
);

const getChartOptions = (
  previousSelected,
  value,
  format,
  onTimeFrameChange,
  getSizeSeries,
  showSize
) => {
  var yAxis: any = [
    {
      endOnTick: false,
      id: "Y_AXYS_MOMENTUM",
      lineWidth: 1,
      // max: 1,
      // min: 0,
      opposite: false,
      showEmpty: false,
      startOnTick: false,
      title: { text: "rating %" },
      type: "linear",
      visible: previousSelected === "MOMENTUM_A",
    },
    {
      endOnTick: false,
      id: "Y_AXYS_CARDINALITY",
      lineWidth: 1,
      opposite: false,
      showEmpty: false,
      startOnTick: false,
      title: { text: "Number of securities" },
      type: "linear",
      visible: previousSelected === "CARDINALITY",
    },
    {
      endOnTick: false,
      events: {
        afterSetExtremes: function (event) {
          var info = event;
          if (info.min && info.max) {
            var limit = Math.max(Math.abs(info.min), Math.abs(info.max));
            event.target.setExtremes(-1 * limit, limit);
          }
        },
      },
      id: "Y_AXYS_PERFORMANCE",
      lineWidth: 1,
      opposite: false,
      showEmpty: false,
      startOnTick: false,
      title: { text: "performance %" },
      type: "linear",
      visible: previousSelected == null || previousSelected === "PERFORMANCE",
    },
    {
      endOnTick: false,
      id: "Y_AXYS_ALLOCATION",
      lineWidth: 1,
      softMax: 1,
      softMin: 0,
      opposite: false,
      showEmpty: false,
      startOnTick: false,
      title: { text: "allocation %" },
      type: "linear",
      visible: previousSelected === "ALLOCATION",
    },
    {
      endOnTick: false,
      id: "Y_AXYS_SIZE",
      lineWidth: 1,
      opposite: false,
      showEmpty: false,
      startOnTick: false,
      title: { text: "Size %" },
      type: "linear",
      visible: previousSelected === "SIZE",
    },
  ];

  var data = value;

  var series: any = [
    _prepareSerie(
      data.performance,
      "PERFORMANCE",
      format,
      previousSelected,
      showSize
    ),
    _prepareSerie(
      data.momentum.D,
      "MOMENTUM_D",
      format,
      previousSelected,
      showSize
    ),
    _prepareSerie(
      data.momentum.C,
      "MOMENTUM_C",
      format,
      previousSelected,
      showSize
    ),
    _prepareSerie(
      data.momentum.B,
      "MOMENTUM_B",
      format,
      previousSelected,
      showSize
    ),
    _prepareSerie(
      data.momentum.A,
      "MOMENTUM_A",
      format,
      previousSelected,
      showSize
    ),
    _prepareSerie(
      data.cardinality,
      "CARDINALITY",
      format,
      previousSelected,
      showSize
    ),
    _prepareSerie(
      data.allocation,
      "ALLOCATION",
      format,
      previousSelected,
      showSize
    ),
    _prepareSerie(
      data.size,
      "SIZE_MICRO",
      format,
      previousSelected,
      showSize,
      getSizeSeries
    ),
    _prepareSerie(
      data.size,
      "SIZE_SMALL",
      format,
      previousSelected,
      showSize,
      getSizeSeries
    ),
    _prepareSerie(
      data.size,
      "SIZE_MID",
      format,
      previousSelected,
      showSize,
      getSizeSeries
    ),
    _prepareSerie(
      data.size,
      "SIZE_LARGE",
      format,
      previousSelected,
      showSize,
      getSizeSeries
    ),
    // _prepareSerie(
    //   data.size,
    //   "SIZE_MEGA",
    //   format,
    //   previousSelected,
    //   getSizeSeries
    // ),
  ];

  if ("long" in data && data.long && data.long.length) {
    yAxis.push({
      endOnTick: false,
      id: "Y_AXYS_ALLOCATION_LONG",
      lineWidth: 1,
      // max: 1,
      // min: 0,
      opposite: false,
      showEmpty: false,
      startOnTick: false,
      title: { text: "allocation long %" },
      type: "linear",
      visible: previousSelected === "ALLOCATION_LONG",
    });

    series.push(
      _prepareSerie(
        data.long,
        "ALLOCATION_LONG",
        format,
        previousSelected,
        showSize
      )
    );
  }

  if ("short" in data && data.short && data.short.length) {
    yAxis.push({
      endOnTick: false,
      id: "Y_AXYS_ALLOCATION_SHORT",
      lineWidth: 1,
      // max: 0,
      // min: -1,
      // reversed: true,
      opposite: false,
      showEmpty: false,
      startOnTick: false,
      title: { text: "allocation short %" },
      type: "linear",
      visible: previousSelected === "ALLOCATION_SHORT",
    });

    series.push(
      _prepareSerie(
        data.short,
        "ALLOCATION_SHORT",
        format,
        previousSelected,
        showSize
      )
    );
  }

  const listenerClick = (e) => onTimeFrameChange(e.point.options.trendrating);

  const options: Highcharts.Options = {
    chart: {
      alignTicks: false,
      animation: true,
      borderWidth: 0,
      margin: [0, 20, 50, 0],
      type: "stock",
      spacing: [0, 0, 0, 0],
      zoomType: "x",
    },
    tooltip: {
      shared: true,
      useHTML: true,
      padding: 4,
    },
    credits: { enabled: false },
    exporting: { enabled: false },
    legend: {
      enabled: true,
      labelFormatter: function () {
        const self: any = this;
        var name: any = self.name;
        switch (name) {
          case "Momentum A": {
            return "Rating";
          }
          case "Micro": {
            return "Size";
          }
          default: {
            return name;
          }
        }
      },
    },
    navigator: { enabled: false },
    plotOptions: {
      column: {
        borderColor: "#fff",
        borderWidth: 1,
        events: {
          click: listenerClick,
        },
        groupPadding: 0,
        pointPadding: 0,
        stacking: "normal",
        states: {
          hover: {
            halo: {
              size: 0,
            },
          },
          inactive: {
            opacity: 1,
          },
        },
      },
      series: {
        animation: false,
      },
    },
    rangeSelector: { enabled: false },
    scrollbar: { enabled: false },
    series: series,
    xAxis: {
      lineColor: _colors.line,
      tickColor: _colors.line,
    },
    yAxis: yAxis,
  };
  return options;
};
const _prepareSerie = (
  data,
  id,
  format,
  previousSelected,
  showSize,
  callback?
) => {
  var tooltip: any = {
    number: { distance: 24, valueDecimals: 0 },
    percentage: {
      distance: 24,
      // shared: true,
      useHTML: true,
      // followPointer: false,
      style: {
        pointerEvents: "none",
      },
      pointFormatter: function () {
        const self: any = this;
        const value = [
          '<span style="color:',
          self.series.color,
          '">\u25CF</span> ',
          self.series.name,
          " ",
          format.custom("number", {
            options: {
              isPercentage: true,
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "HTML",
            value: self.y,
            valueHelper: null,
          }),
        ].join("");

        return value;
      },
    },
  };
  var attributes = {
    ALLOCATION: {
      color: _colors.securityDefault,
      name: "Allocation",
      showInLegend: true,
      step: true,
      size: 2,
      tooltip: tooltip["percentage"],
      visible: previousSelected === "ALLOCATION",
      y: "Y_AXYS_ALLOCATION",
    },
    ALLOCATION_LONG: {
      color: "#2f863f",
      name: "Allocation long",
      negativeColor: "#f25437",
      showInLegend: true,
      size: 1,
      tooltip: tooltip["percentage"],
      visible: previousSelected === "ALLOCATION_LONG",
      y: "Y_AXYS_ALLOCATION_LONG",
    },
    ALLOCATION_SHORT: {
      color: "#f25437",
      name: "Allocation Short",
      showInLegend: true,
      size: 1,
      tooltip: tooltip["percentage"],
      visible: previousSelected === "ALLOCATION_SHORT",
      y: "Y_AXYS_ALLOCATION_SHORT",
    },
    CARDINALITY: {
      color: _colors.securityDefault,
      name: "# constituents",
      showInLegend: true,
      size: 1,
      tooltip: tooltip["number"],
      visible: previousSelected === "CARDINALITY",
      y: "Y_AXYS_CARDINALITY",
    },
    MOMENTUM_A: {
      color: _colors.securityRateA,
      name: "Momentum A",
      showInLegend: true,
      size: 1,
      tooltip: tooltip["percentage"],
      visible: previousSelected === "MOMENTUM_A",
      y: "Y_AXYS_MOMENTUM",
    },
    MOMENTUM_B: {
      color: _colors.securityRateB,
      name: "Momentum B",
      showInLegend: false,
      size: 1,
      tooltip: tooltip["percentage"],
      visible: previousSelected === "MOMENTUM_A",
      y: "Y_AXYS_MOMENTUM",
    },
    MOMENTUM_C: {
      color: _colors.securityRateC,
      name: "Momentum C",
      showInLegend: false,
      size: 1,
      tooltip: tooltip["percentage"],
      visible: previousSelected === "MOMENTUM_A",
      y: "Y_AXYS_MOMENTUM",
    },
    MOMENTUM_D: {
      color: _colors.securityRateD,
      name: "Momentum D",
      showInLegend: false,
      size: 1,
      tooltip: tooltip["percentage"],
      visible: previousSelected === "MOMENTUM_A",
      y: "Y_AXYS_MOMENTUM",
    },
    PERFORMANCE: {
      color: "#2f863f",
      name: "Performance",
      negativeColor: "#f25437",
      showInLegend: true,
      size: 1,
      tooltip: tooltip["percentage"],
      visible: previousSelected == null || previousSelected === "PERFORMANCE",
      y: "Y_AXYS_PERFORMANCE",
    },
    SIZE_MICRO: {
      color: "#00B4D8",
      name: "Micro",
      showInLegend: showSize,
      size: 1,
      tooltip: tooltip["percentage"],
      visible: previousSelected == null || previousSelected === "SIZE_MICRO",
      y: "Y_AXYS_SIZE",
      getData: () => callback!(),
    },
    SIZE_SMALL: {
      color: "#0096C7",
      name: "Small",
      showInLegend: false,
      size: 1,
      tooltip: tooltip["percentage"],
      visible: previousSelected == null || previousSelected === "SIZE_MICRO",
      y: "Y_AXYS_SIZE",
      // getData: () => console.log("sz micro"),
    },
    SIZE_MID: {
      color: "#0077B6",
      name: "Mid",
      showInLegend: false,
      size: 1,
      tooltip: tooltip["percentage"],
      visible: previousSelected == null || previousSelected === "SIZE_MICRO",
      y: "Y_AXYS_SIZE",
      // getData: () => console.log("sz micro"),
    },
    SIZE_LARGE: {
      color: "#023E8A",
      name: "Large",
      showInLegend: false,
      size: 1,
      tooltip: tooltip["percentage"],
      visible: previousSelected == null || previousSelected === "SIZE_MICRO",
      y: "Y_AXYS_SIZE",
    },
  };

  var serie = {
    color: attributes[id].color,
    data: data,
    dataGrouping: {
      enabled: false,
    },
    events: {
      legendItemClick: function (e) {
        const self: any = this;
        _listenerLegend(e, self.chart, attributes?.[id]?.["getData"] ?? null);
      },
    },
    id: id,
    lineWidth: attributes[id].size,
    name: attributes[id].name,
    negativeColor: attributes[id].negativeColor
      ? attributes[id].negativeColor
      : null,
    showInLegend: attributes[id].showInLegend,
    step: attributes[id].step ? attributes[id].step : false,
    tooltip: attributes[id].tooltip,
    type: "column",
    turboThreshold: 0,
    visible: attributes[id].visible,
    yAxis: attributes[id].y,
  };

  return serie;
};
const _legendAllocation = (isVisible, chart) => {
  chart.get("Y_AXYS_ALLOCATION").update({ visible: false });
  chart.get("ALLOCATION").setVisible(isVisible);
};

const _legendAllocationLong = (isVisible, chart) => {
  chart.get("Y_AXYS_ALLOCATION_LONG").update({ visible: false });
  chart.get("ALLOCATION_LONG").setVisible(isVisible);
};

const _legendAllocationShort = (isVisible, chart) => {
  chart.get("Y_AXYS_ALLOCATION_SHORT").update({ visible: false });
  chart.get("ALLOCATION_SHORT").setVisible(isVisible);
};

const _legendCardinality = (isVisible, chart) => {
  chart.get("Y_AXYS_CARDINALITY").update({ visible: false });
  chart.get("CARDINALITY").setVisible(isVisible);
};
const _legendHolding = (isVisible, chart) => {
  var serie = chart.get("HOLDING_EXPOSURE");

  if (serie) {
    serie.setVisible(isVisible);
  }
};

const disableLegendSize = (chart) => {
  chart.get("Y_AXYS_MOMENTUM").update({ visible: false });
  chart.get("SIZE_MICRO").setVisible(false);
  chart.get("SIZE_SMALL").setVisible(false);
  chart.get("SIZE_MID").setVisible(false);
  chart.get("SIZE_LARGE").setVisible(false);
  // chart.get("SIZE_MEGA").setVisible(false);
};

const activateLegendSize = async (isVisible, chart, dataCallback) => {
  chart.get("Y_AXYS_SIZE").update({ visible: false });
  const series = {
    micro: chart.get("SIZE_MICRO"),
    small: chart.get("SIZE_SMALL"),
    mid: chart.get("SIZE_MID"),
    large: chart.get("SIZE_LARGE"),
    // mega: chart.get("SIZE_MEGA"),
  };

  const data = await dataCallback();
  let serie: any = null;

  for (const key in series) {
    serie = series[key];

    serie.setData(data[key]);
    serie.setVisible(true);
  }
};
const _legendMomentum = (isVisible, chart) => {
  chart.get("Y_AXYS_MOMENTUM").update({ visible: false });
  chart.get("MOMENTUM_A").setVisible(isVisible);
  chart.get("MOMENTUM_B").setVisible(isVisible);
  chart.get("MOMENTUM_C").setVisible(isVisible);
  chart.get("MOMENTUM_D").setVisible(isVisible);
};
const _legendPerformance = (isVisible, chart) => {
  chart.get("Y_AXYS_PERFORMANCE").update({ visible: false });
  chart.get("PERFORMANCE").setVisible(isVisible);
};
const _listenerLegend = (event, chartRef, dataCallback?) => {
  event.preventDefault();
  var id = event.target.options.id;
  let chart = chartRef;

  const hasLongSerie = chart.get("Y_AXYS_ALLOCATION_LONG") != null;
  const hasShortSerie = chart.get("Y_AXYS_ALLOCATION_SHORT") != null;

  switch (id) {
    case "ALLOCATION": {
      _legendAllocation(true, chart);

      _legendCardinality(false, chart);
      _legendMomentum(false, chart);
      _legendPerformance(false, chart);
      _legendHolding(false, chart);
      disableLegendSize(chart);

      if (hasLongSerie) {
        _legendAllocationLong(false, chart);
      }

      if (hasShortSerie) {
        _legendAllocationShort(false, chart);
      }

      break;
    }
    case "CARDINALITY": {
      _legendCardinality(true, chart);

      _legendAllocation(false, chart);
      _legendMomentum(false, chart);
      _legendPerformance(false, chart);
      _legendHolding(false, chart);
      disableLegendSize(chart);

      if (hasLongSerie) {
        _legendAllocationLong(false, chart);
      }

      if (hasShortSerie) {
        _legendAllocationShort(false, chart);
      }

      break;
    }
    case "MOMENTUM_A": {
      _legendMomentum(true, chart);

      _legendAllocation(false, chart);
      _legendCardinality(false, chart);
      _legendPerformance(false, chart);
      _legendHolding(false, chart);
      disableLegendSize(chart);

      if (hasLongSerie) {
        _legendAllocationLong(false, chart);
      }

      if (hasShortSerie) {
        _legendAllocationShort(false, chart);
      }

      break;
    }
    case "PERFORMANCE": {
      _legendPerformance(true, chart);

      _legendAllocation(false, chart);
      _legendCardinality(false, chart);
      _legendMomentum(false, chart);
      _legendHolding(false, chart);
      disableLegendSize(chart);

      if (hasLongSerie) {
        _legendAllocationLong(false, chart);
      }

      if (hasShortSerie) {
        _legendAllocationShort(false, chart);
      }

      break;
    }
    case "HOLDING_EXPOSURE": {
      _legendHolding(true, chart);
      _legendPerformance(false, chart);
      _legendAllocation(false, chart);
      _legendCardinality(false, chart);
      _legendMomentum(false, chart);
      disableLegendSize(chart);

      if (hasLongSerie) {
        _legendAllocationLong(false, chart);
      }

      if (hasShortSerie) {
        _legendAllocationShort(false, chart);
      }

      break;
    }

    case "ALLOCATION_LONG": {
      _legendAllocationLong(true, chart);

      _legendAllocation(false, chart);
      _legendCardinality(false, chart);
      _legendMomentum(false, chart);
      _legendPerformance(false, chart);
      _legendHolding(false, chart);
      disableLegendSize(chart);

      if (hasShortSerie) {
        _legendAllocationShort(false, chart);
      }

      break;
    }

    case "ALLOCATION_SHORT": {
      _legendAllocationShort(true, chart);

      _legendAllocation(false, chart);
      _legendCardinality(false, chart);
      _legendMomentum(false, chart);
      _legendPerformance(false, chart);
      _legendHolding(false, chart);
      disableLegendSize(chart);

      if (hasLongSerie) {
        _legendAllocationLong(false, chart);
      }

      break;
    }

    case "SIZE_MICRO": {
      if (dataCallback) {
        activateLegendSize(true, chart, dataCallback).then(() => {
          _legendMomentum(false, chart);
          _legendAllocation(false, chart);
          _legendCardinality(false, chart);
          _legendPerformance(false, chart);
          _legendHolding(false, chart);

          if (hasLongSerie) {
            _legendAllocationLong(false, chart);
          }

          if (hasShortSerie) {
            _legendAllocationShort(false, chart);
          }
        });
      }
    }

    // no default
  }
};

export default Timeline;

import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Skeleton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { decodePeerId } from "../../../../../api/utils";
import { Spinner } from "../../../../../components/Spinner/Spinner";
import { InfoTooltip } from "../../alerts/InfoTooltip";
import DispersionChart from "../../analysisLists/analyze/tabs/TabDispersion/DispersionChart";
import { PeerDetailSegments } from "../detail/PeerDetail";
import DispersionByTable from "../ETFs/widgets/ContentETFMarkets/widgets/DispersionByView/DispersionByTable";
import { SelectorETFMarkets } from "../ETFs/widgets/FiltersBarMarketDetail/FiltersBarMarketDetail";

type DispersionByTabDetailProps = {
  data: { dispersionBy: any };
  peerCardinality: number;
  segment: PeerDetailSegments;
  changePeer: (action) => void;
  type: "Stock" | "ETF";
  id: string;
  isLoading: boolean;
  updateTabSettings: (params: {
    intervals: 4 | 10 | 20;
    performance: "1_week" | "1_month" | "3_months" | "6_months" | "12_months";
    trimOutliers: boolean;
    id: string;
  }) => void;
};

const performanceOptions = [
  {
    label: "3 Months",
    value: "3_months",
  },
  {
    label: "6 Months",
    value: "6_months",
  },
  {
    label: "12 Months",
    value: "12_months",
  },
];

const percentageOptions = [
  {
    label: "25%",
    value: 4,
  },
  {
    label: "10%",
    value: 10,
  },
  {
    label: "5%",
    value: 20,
  },
];

const chartTypeOptions = [
  {
    label: "Histogram",
    value: "histogram",
  },
  {
    label: "Range",
    value: "range",
  },
];

const StyledSwitch = styled((props: any) => (
  <FormControlLabel
    sx={{
      fontSize: "12px",
    }}
    control={
      <Switch
        size="small"
        checked={props.trimOutliers}
        onChange={(e) =>
          props.updatePageSettings(e.target.checked, "trimOutliers")
        }
      />
    }
    classes={{
      label: props.className,
    }}
    label="Trim Outliers"
  />
))({
  fontSize: "12px !important",
});

export const DispersionByTabDetail = forwardRef(
  (
    {
      data,
      peerCardinality,
      segment,
      changePeer,
      updateTabSettings,
      type,
      id,
      isLoading,
    }: DispersionByTabDetailProps,
    ref
  ) => {
    const usage = window.App.usage;
    const [intervals, setIntervals] = useState<4 | 10 | 20>(4);
    const [target, setTarget] = useState(id);
    const [performance, setPerformance] = useState<
      "1_week" | "1_month" | "3_months" | "6_months" | "12_months"
    >("6_months");
    const [trimOutliers, setTrimOutliers] = useState(true);
    const [chartType, setChartType] = useState<"histogram" | "range">(
      "histogram"
    );
    const [sorter, setSorter] = useState<
      {
        dir: "desc" | "asc";
        field: string;
      }[]
    >([]);
    const percentage: 25 | 10 | 5 = useMemo(() => {
      switch (intervals) {
        default:
        case 4:
          return 25;
        case 10:
          return 10;
        case 20:
          return 5;
      }
    }, [intervals]);

    useEffect(() => setTarget(id), [id]);

    const navigateDownToPeer = useCallback(
      (data) => {
        const {
          peer: { id },
        } = data;

        const { where, what, zDimension } = decodePeerId(id);

        const info = {
          action: "PEER",
          actionParam: {
            xDimension: where,
            yDimension: what,
            zDimension,
          },
          function: "MARKET_ETF",
        };

        usage.record(info);

        changePeer({
          type: "CHANGE_PEER",
          payload: { xDim: where, yDim: what },
        });
      },
      [changePeer, usage]
    );

    const onSort = useCallback((value) => {
      setSorter((prev) => {
        if (JSON.stringify(value) !== JSON.stringify(prev)) {
          return value;
        } else {
          return prev;
        }
      });
    }, []);

    const updatePageSettings = useCallback(
      (value, field: "intervals" | "performance" | "trimOutliers") => {
        const settings: any = {
          intervals,
          performance,
          trimOutliers,
          id: target,
        };

        settings[field] = value;

        const set = {
          intervals: () => setIntervals(value),
          performance: () => setPerformance(value),
          trimOutliers: () => setTrimOutliers(value),
        };

        set[field]();
        updateTabSettings(settings);
      },
      [intervals, performance, target, trimOutliers, updateTabSettings]
    );

    useImperativeHandle(ref, () => ({
      getdispersionByTabParams: () => ({
        intervals,
        performance,
        trimOutliers,
      }),
      getSorter: () => sorter,
    }));

    const derivedData = useMemo(() => {
      return data?.dispersionBy ? Object.values(data.dispersionBy) : undefined;
    }, [data?.dispersionBy]);

    return isLoading ? (
      <DispersionBySkeleton />
    ) : (
      <Box
        minHeight={0}
        mt={1}
        display={"flex"}
        boxShadow={3}
        p={1}
        borderRadius={2}
        width={"100%"}
      >
        <Box width={"100%"} gap={1} display={"flex"} minWidth={0} minHeight={0}>
          <Card sx={{ width: "50%", display: "flex", minHeight: 0 }}>
            <CardContent
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                minHeight: 0,
                minWidth: 0,
              }}
            >
              {/* Instruments Table */}
              {data?.dispersionBy ? (
                <>
                  {peerCardinality > 0 ? (
                    <>
                      {peerCardinality > 3 ? (
                        <DispersionByTable
                          type={type}
                          percentage={percentage}
                          segment={segment as any}
                          data={derivedData}
                          navigateDownPeer={navigateDownToPeer}
                          onTableSort={onSort}
                        />
                      ) : (
                        <Typography fontSize={"14px"} fontWeight={"bold"}>
                          The {type} you are lookng for has not enough
                          constituents to calculate dispersion (at least 4)
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Typography fontSize={"14px"} fontWeight={"bold"}>
                      Any {type} was found
                    </Typography>
                  )}
                </>
              ) : (
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spinner />
                </div>
              )}
            </CardContent>
          </Card>
          {/* Dispersion data */}
          <Card sx={{ width: "50%", display: "flex" }}>
            <CardContent
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {data?.dispersionBy ? (
                <>
                  {peerCardinality > 3 && (
                    <>
                      <Card
                        sx={{
                          minHeight: 0,
                          marginBottom: "5px",
                          display: "flex",
                          border: "1px solid #2A7090",
                        }}
                      >
                        <CardContent
                          sx={{
                            minHeight: 0,
                            display: "flex",
                            flex: 1,
                            justifyContent: "space-between",
                            paddingTop: "5px",
                            paddingBottom: "10px!important",
                          }}
                        >
                          <SelectorETFMarkets
                            options={performanceOptions}
                            selectedOption={performance}
                            selectOption={(value) =>
                              updatePageSettings(value, "performance")
                            }
                          />
                          <SelectorETFMarkets
                            selectOption={(value) =>
                              updatePageSettings(value, "intervals")
                            }
                            options={percentageOptions}
                            selectedOption={intervals}
                          />

                          <SelectorETFMarkets
                            selectOption={setChartType}
                            options={chartTypeOptions}
                            selectedOption={chartType}
                          />

                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <StyledSwitch
                              updatePageSettings={updatePageSettings}
                              trimOutliers={trimOutliers}
                            />
                            <InfoTooltip
                              style={{
                                top: 5,
                                left: -12,
                              }}
                              title={""}
                              text="The outliers are securities that perform abnormally relative to the average of all stocks in the same set."
                            />
                          </div>
                        </CardContent>
                      </Card>
                      <Card>
                        <CardContent>
                          <DispersionChart
                            chartType={chartType}
                            data={data.dispersionBy}
                            interval={percentage}
                            timeframe={performance as any}
                            sorter={sorter}
                          />
                        </CardContent>
                      </Card>
                    </>
                  )}
                </>
              ) : (
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spinner />
                </div>
              )}
            </CardContent>
          </Card>
        </Box>
      </Box>
    );
  }
);

const DispersionBySkeleton = () => {
  return (
    <Box p={1} mt={1} borderRadius={"4px"} boxShadow={3} display={"flex"}>
      <Box display={"flex"} flex={3} mr={1}>
        <Card sx={{ flex: 1 }}>
          <CardContent>
            <Stack spacing={1}>
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "20px",
                  marginBottom: "20px",
                }}
              />

              {/* For other variants, adjust the size with `width` and `height` */}
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
            </Stack>
          </CardContent>
        </Card>
      </Box>
      <Box display={"flex"} flex={9}>
        <Card sx={{ flex: 1 }}>
          <CardContent>
            <Stack spacing={1}>
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "20px",
                  marginBottom: "20px",
                }}
              />

              {/* For other variants, adjust the size with `width` and `height` */}
              <Skeleton variant="rectangular" width={"100%"} height={300} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

import { useCallback, useEffect, useState } from "react";
import { deepClone } from "../../../../../deepClone";
import InputNumber from "../../../../InputNumber/InputNumber";

type OptionNumberSingleIntervalProps = {
  type: "number" | "percentage";
  item: any;
  stateSetter: Function;
  constraints: any;
  getInputValidity: Function;
  inputConstraints: any;
};

const defineType = (str: "number" | "percentage") => {
  if (str === "number") {
    return false;
  } else {
    return true;
  }
};

// const defineIsFloat = (str: "number" | "percentage") => {
//   if (str === "number") {
//     return false;
//   } else {
//     return true;
//   }
// };

export default function OptionNumberSingleIntervalNew({
  type,
  item,
  stateSetter,
  constraints,
  getInputValidity,
  inputConstraints
}: OptionNumberSingleIntervalProps) {
  const [innerRange, setInnerRange] = useState(
    item["augmentation"]["value"][0]
  );

  useEffect(() => {
    let temp = deepClone(item);
    temp["augmentation"]["value"][0] = innerRange;
    if (JSON.stringify(temp) !== JSON.stringify(item)) {
      stateSetter(temp);
    }
  }, [innerRange, item, stateSetter]);

  const [input1Validity, setInput1Validity] = useState(false);
  const [input2Validity, setInput2Validity] = useState(false);

  const validateInput1 = useCallback(
    (e) => {
      if (e === false && input2Validity === false) {
        getInputValidity(false);
      } else {
        getInputValidity(true);
      }
      setInput1Validity(e);
    },
    [getInputValidity, input2Validity]
  );

  const validateInput2 = useCallback(
    (e) => {
      if (e === false && input1Validity === false) {
        getInputValidity(false);
      } else {
        getInputValidity(true);
      }
      setInput2Validity(e);
    },
    [getInputValidity, input1Validity]
  );

  return (
    <div
      style={{
        width: "100%",
        display: "block",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <p style={{ fontSize: "1rem" }}>
          <strong>{">"}</strong>
        </p>
        {/* <InputNumber
          getIsValid={(e) => validateInput1(e)}
          constraints={constraints}
          isPercentage={defineType(type)}
          setOuterState={(e) => {
            let tempObj = deepClone(innerRange);
            tempObj["ge"] = e;
            if (JSON.stringify(tempObj) !== JSON.stringify(innerRange)) {
              setInnerRange(tempObj);
            }
          }}
          initVal={innerRange["ge"]}
          isFloating={defineIsFloat(type)}
        /> */}

        <InputNumber
          getIsValid={(e) => validateInput1(e)}
          constraints={constraints}
          isPercentage={defineType(type)}
          setOuterState={(e) => {
            let tempObj = deepClone(innerRange);
            tempObj["ge"] = e;
            if (JSON.stringify(tempObj) !== JSON.stringify(innerRange)) {
              setInnerRange(tempObj);
            }
          }}
          isFloating={inputConstraints?.decimals !== undefined ? true : false}
          initVal={innerRange["ge"]}
          decimals={inputConstraints?.decimals ?? undefined}
        />
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p style={{ fontSize: "1rem" }}>
          <strong>{"<"}</strong>
        </p>
        {/* <InputNumber
          getIsValid={(e) => validateInput2(e)}
          constraints={constraints}
          isPercentage={defineType(type)}
          setOuterState={(e) => {
            let tempObj = deepClone(innerRange);
            tempObj["le"] = e;
            if (JSON.stringify(tempObj) !== JSON.stringify(innerRange)) {
              setInnerRange(tempObj);
            }
          }}
          isFloating={defineIsFloat(type)}
          initVal={innerRange["le"]}
        />
         */}
            <InputNumber
          getIsValid={(e) => validateInput2(e)}
          constraints={constraints}
          isPercentage={defineType(type)}
          setOuterState={(e) => {
            let tempObj = deepClone(innerRange);
            tempObj["le"] = e;
            if (JSON.stringify(tempObj) !== JSON.stringify(innerRange)) {
              setInnerRange(tempObj);
            }
          }}
          isFloating={inputConstraints?.decimals !== undefined ? true : false}
          initVal={innerRange["le"]}
          decimals={inputConstraints?.decimals ?? undefined}
        />
      </div>
    </div>
  );
}
